import React from "react";
import PropTypes from "prop-types";

function RenderLocale({ element, localeString, strReplace }) {
  let tmp = localeString.split(strReplace);
  return (
    <>
      {tmp[0]}
      {element}
      {tmp[1]}
    </>
  );
}

RenderLocale.propTypes = {
  localeString: PropTypes.string,
  strReplace: PropTypes.any,
  element: PropTypes.object
};

RenderLocale.defaultProps = {
  localeString: "",
  strReplace: "#####",
  element: {}
};

export default RenderLocale;
