// Libs
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

// Components
import LessonBox from "./lesson-box";
import Title from "@/components/common/title";
import LearningDashboardLayout from "../index";
import * as images from "@/constants/images";
// import { Skeleton } from "antd";
import locale from "@/locale";
import { selectSessions, selectSessionLog } from "@/stores/selectors/session";
import { insertSessionLog, setQuizLogs } from "@/stores/actions";
import LinkTab from "@/components/link-tab";
import { history } from "@/shared/utils/history";
import { ENTER_CLASS_CODE } from "@/constants/routes";

const WrapperContainer = styled(Container)`
  height: 100%;
  background-size: 50%, 100%;
  background-position: top right, bottom;
  background-repeat: no-repeat, no-repeat;
  background-image: url(${images.BACKGROUND_IMAGE1}),
    url(${images.BACKGROUND_IMAGE2});
`;

function Lesson() {
  const [isDone, setIsDone] = useState(false);
  const selectorSessions = useSelector(selectSessions);
  const selectorSessionLog = useSelector(selectSessionLog);
  const { classRoom } = useSelector(state => state.classRoom);
  const { user } = useSelector(state => state.user);
  const { teacher } = useSelector(state => state.teacher);

  const dispatch = useDispatch();

  useEffect(() => {
    if (_.size(selectorSessionLog) && !isDone) {
      dispatch(insertSessionLog(selectorSessionLog.id));
      setIsDone(true);
    }

  }, [dispatch, selectorSessionLog, isDone]);

  useEffect(() => {
    dispatch(setQuizLogs([]));
  }, [dispatch]);

  // console.log('_.size(teacher) > 0', _.size(teacher) > 0);
  useEffect(() => {
    if (_.size(user)) {

      if ((!user.class_id) && !_.size(teacher)) {
        return history.push(ENTER_CLASS_CODE);
      }
    }
  }, [user, teacher]);

  return (
    <LearningDashboardLayout
      hasSidebar={false}
      hasBreadcrumb={false}
      childrenClassName="h-100"
    >
      <WrapperContainer>
        <Row className="h-100">
          <Col xs="12" className="text-center">
            <LinkTab />
            <>
              <Title
                tag="h4"
                className="text-primary my-4"
                title={locale("titleLesson").replace("#####", user.name || "").replace("$$$$$", classRoom.name || "N/A", locale("titleLesson"))}
              />
              <LessonBox sessions={selectorSessions} isEnable={_.size(teacher) > 0} />
            </>
            {/* )} */}
          </Col>
        </Row>
      </WrapperContainer>
    </LearningDashboardLayout>
  );
}

export default Lesson;
