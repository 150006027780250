import { createSelector } from "reselect";
// import _ from "lodash";

const activities = state => state.activity.activities;
// const activity = state => state.activity.activity;
const activityLogs = state => state.activityLog.activityLogs;

export const selectActivities = createSelector(activities, activityLogs, (activities, activityLogs) => {

  let activityLog = activityLogs.reduce((acc, cur) => {
    acc[cur.activity_id] = cur.is_completed || false;
    return acc;
  }, {});

  return activities.map(activity => {
    activity.is_completed = activityLog[activity.id] || false;
    return activity;
  });
});