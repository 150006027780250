import { SubmissionError } from "redux-form";
import locale from "@/locale";

export const isValidEmail = email => {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
};

export const validate = (rules, values) => {
  var errors = {};
  var isError = false;
  for (var field in rules) {
    var rule = rules[field];
    var value = rule["value"];
    if (values != null) {
      value = values[field];
    }
    var type = rule["type"];

    if (
      rule["required"] == true &&
      (value == null || (typeof value == "string" && value.trim() == ""))
    ) {
      errors[field] = rule["message"] || locale("errorRequired");
      isError = true;
    }

    if (
      rule["required"] == true &&
      rule.type == "array" &&
      (value == null || value.length == 0)
    ) {
      errors[field] = {
        _error: rule["message"] || locale("errorSelectNotEmpty")
      };
      isError = true;
    }

    if (rule.validation && rule.type == "array" && value && value.length > 0) {
      const validation = rule.validation(value);
      if (validation) {
        errors[field] = { _error: validation.message };
        isError = true;
      }
    }

    if (type == "password") {
      if (rule["required"] == true) {
        if (value != null && value.length < 6) {
          errors[field] = rule["message"] || locale("errorPasswordLength");
          isError = true;
        }
      }
    }

    if (rule.hasOwnProperty("equalsTo") && value != null) {
      var equalsTo = rule["equalsTo"];
      if (equalsTo && equalsTo.value != value) {
        const message =
          equalsTo.message || `${field} and ${equalsTo.name} do not match!`;
        errors[field] = message;
        errors[equalsTo.name] = message;
        isError = true;
      }
    }

    if (type == "email") {
      if (rule["required"] == true) {
        if (value != "" && value !== null && isValidEmail(value)) {
          errors[field] = rule["message"] || locale("errorInvalidEmail");
          isError = true;
        } else if (value == null && value == "") {
          errors[field] = rule["message"] || locale("errorRequired");
          isError = true;
        }
      } else {
        if (value != null && value != "" && isValidEmail(value)) {
          errors[field] = rule["message"] || locale("errorInvalidEmail");
          isError = true;
        }
      }
    }
  }
  return isError ? errors : null;
};

export const createSubmitAction = (
  rules,
  action,
  beforeSubmit,
  beforeSubmissionError
) => values => {
  let error = null;
  error = validate(rules, values);
  if (error != null) {
    if (beforeSubmissionError) {
      beforeSubmissionError(values);
    }
    throw new SubmissionError(error);
  } else {
    let newValues = values;
    if (beforeSubmit) {
      newValues = beforeSubmit(values);
    }

    return action(newValues);
  }
};
