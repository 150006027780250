import * as actionTypes from "../actions/actionTypes";

const initialState = {
  sessionLogs: [],
  sessionLog: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.SESSION_LOG_SET_SESSION_LOGS:
    return { ...state, sessionLogs: action.sessionLogs };
  case actionTypes.SESSION_LOG_SET_SESSION_LOG:
    return { ...state, sessionLog: action.sessionLog };
  default:
    return state;
  }
};

export default reducer;

