import React, { Fragment, useState } from "react";
import { Col, Label } from "reactstrap";
import { useDispatch } from "react-redux";
import Title from "@/components/common/title";
import InputText from "@/components/common/input";
import * as helpers from "@/helpers";
import FormWrapper from "@/components/common/form";
import * as images from "../../constants/images";
import * as actions from "@/stores/actions";
import * as routes from "@/constants/routes";
import { Link } from "react-router-dom";
import { Button } from "antd";
import locale from "@/locale";

function CommonSecurity() {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [handleSucceed, handleSuccess] = useState(false);
  const [loadings, setLoadings] = useState(false);

  const handleSecurity = values => {
    setLoadings(true);
    dispatch(actions.sendPasswordResetEmail(values.email))
      .then(() => {
        handleSuccess(true);
      })
      .catch(() => {
        setLoadings(false);
        setError(true);
      });
  };

  const onSubmit = values => {
    setLoadings(false);
    const validationRule = {
      email: {
        required: true,
        type: "email"
      }
    };

    return helpers.createSubmitAction(
      validationRule,
      handleSecurity,
      null // if no need custom values before submit pass null
    )(values);
  };

  return (
    <div className="page-auto page-security">
      {handleSucceed !== false ? (
        <Fragment>
          <Col className="box-success padding-left0 padding-right0">
            <Col className="blog-image">
              <div className="image-box">
                <img src={images.IMAGE_VERIFIED} alt="" />
              </div>
            </Col>
            <Col className="box-details">
              <div className="title">
                <Title
                  tag="span"
                  title={locale("thankYou")}
                  className="title-verified"
                />
              </div>
              <div className="detail">
                <p>{locale("messageEmailResetPassword")}</p>
              </div>
              <Link to={routes.LESSON}>
                <button className="btn-submited" htmlType="submit">
                  {locale("okay")}
                </button>
              </Link>
            </Col>
          </Col>
        </Fragment>
      ) : (<Fragment>
        <div>
          <Title
            tag="span"
            title={locale("security")}
            className="title-general"
          />
        </div>
        <div className="content">
          <Col className="padding-left0">
            <Title
              tag="span"
              title={locale("titleChangePassword")}
              className="title-change-email bottom-bit"
            />
            <div className="detail">
              <p>{locale("titleResetPassword")}</p>
            </div>
          </Col>
          {error && (
            <div className="p-3 mb-3 border-2 border-danger bg-danger text-center text-muted rounded">
              <Title className="text-muted" title={locale("wrongCredentials")} />
              <span>{locale("errorEmailNotExist")}</span>
            </div>
          )}
          <FormWrapper
            className="form-context"
            form="createAccount"
            onSubmit={onSubmit}
          >
            <Label>{locale("emailAddress")}</Label>
            <InputText name="email" placeholder={locale("email")} />
            <Button
              htmlType="submit"
              loading={loadings}
              onClick={setLoadings}
              className="btn-submit"
            >
              {locale("save")}
            </Button>
          </FormWrapper>
        </div>
      </Fragment>)}
    </div>
  );
}

export default CommonSecurity;
