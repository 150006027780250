import * as actionTypes from "./actionTypes";
import {
  firestoreRef,
  FIRSTORE_TIMESTAMP,
  generateDocKey
} from "@/configs/firebase";

const setEnrollment = enrollment => ({
  type: actionTypes.ENROLLMENT_SET_ENROLLMENT,
  enrollment
});

export const getSetEnrollment = (class_id) => (dispatch, getState) => {
  try {
    const { userId } = getState().auth;
    let keyDoc = generateDocKey("enrollments");
    return firestoreRef
      .collection("enrollments")
      .where("user_id", "==", userId)
      .where("class_id", "==", class_id)
      .onSnapshot(snapshots => {
        let data = {};
        snapshots.forEach(doc => {
          data = doc.data();
        });

        if (Object.keys(data).length) {
          dispatch(setEnrollment(data));
          return false;
        }

        let newData = {
          id: keyDoc,
          user_id: userId,
          class_id: class_id,
          created: FIRSTORE_TIMESTAMP
        };

        firestoreRef
          .collection("enrollments")
          .doc(keyDoc)
          .set(newData);
        dispatch(setEnrollment(newData));

      });
  } catch (err) {
    console.log(err);
  }
};
