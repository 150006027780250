// Libs

import React, { Fragment, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { NavLink, Link } from "react-router-dom";
import { Icon, Avatar, Switch } from "antd";
import { Nav, Navbar, NavItem, NavbarToggler, Collapse } from "reactstrap";

// Utils
import locale from "@/locale";
import * as images from "@/constants/images";
import * as routes from "@/constants/routes";
import * as constants from "@/constants/constant";
import * as actions from "@/stores/actions";

function Header({ isAuthorize }) {
  const [isOpen, setIsOpen] = useState(false);

  const { lang } = useSelector(state => state.lang);
  // const { teamMembersNoti } = useSelector(state => state.teamMember);

  const dispatch = useDispatch();

  const onSetLocale = useCallback(value => {
    dispatch(actions.setLocale(value ? constants.KH : constants.EN));
  }, [dispatch]);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const onLogOut = useCallback(() => {
    dispatch(actions.signOut());
  }, [dispatch]);

  return (
    <div style={{ borderBottom: `2px solid ${constants.SECONDARY_COLOR}` }}>
      <Navbar
        light
        color="white"
        expand="lg"
        className="border-primary container-fluid"
      >
        <Link to={routes.HOME} className="navbar-brand custom-navbar-brand">
          <img
            src={images.LOGO}
            className="navbar-brand-logo custom-navbar-brand"
            alt="logo"
          />
        </Link>
        <NavbarToggler
          onClick={toggle}
          className="d-flex d-lg-none text-primary border-primary"
        >
          <Icon type="menu" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar>
            {/* <NavItem>
              <NavLink to={routes.LESSON} className="text-mute menu-item" title={locale('home')}>
                <Avatar src={images.ICON_HOME} /><span className="ml-2 d-md-none d-lg-none">{locale('home')}</span>
              </NavLink>
            </NavItem> */}
            {isAuthorize && (
              <Fragment>
                <NavItem>
                  <NavLink to={`${routes.FORM_TEAM}?action=view`} className="text-mute menu-item" title={locale('team')}>
                    <Avatar src={images.ICON_TEAM} /><span className="ml-2 d-md-none d-lg-none">{locale('team')}</span>
                  </NavLink>
                </NavItem>
              </Fragment>
            )}
            <NavItem>
              <NavLink to={routes.ABOUT_US} className="text-mute menu-item" title={locale('contactUs')}>
                <Avatar src={images.ICON_ABOUT_US} /><span className="ml-2 d-md-none d-lg-none">{locale('contactUs')}</span>
              </NavLink>
            </NavItem>
            {isAuthorize && (
              <Fragment>
                <NavItem>
                  <NavLink to={routes.ACCOUNT_SETTING} className="text-mute menu-item" title={locale('accountSetting')}>
                    <Avatar src={images.ICON_ACCOUNT_SETTING} /><span className="ml-2 d-md-none d-lg-none">{locale('accountSetting')}</span>
                  </NavLink>
                </NavItem>
                {/* {teamMembersNoti.length > 0 && (
                  <NavItem>
                    <NavLink to={getRouteUrl(teamMembersNoti)} title={locale('accountSetting')}>
                      <Badge count={teamMembersNoti.length}>
                        <Icon type="notification" style={{ fontSize: "20px" }} />
                      </Badge>
                    </NavLink>
                  </NavItem>
                )} */}
                <NavItem onClick={onLogOut}>
                  <Avatar style={{ cursor: "pointer" }} src={images.ICON_LOGOUT} title={locale('logOut')} /><span className="ml-2 d-md-none d-lg-none">{locale('logOut')}</span>
                </NavItem>
              </Fragment>
            )}
            {!isAuthorize && (
              <Fragment>
                {/* <NavItem>
                  <NavLink to={routes.SIGN_UP} className="text-mute menu-item" title={locale('signUp')}>
                    <Avatar src={images.ICON_REGISTER} /><span className="ml-2 d-md-none d-lg-none">{locale('signUp')}</span>
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink to={routes.SIGN_IN} className="text-mute menu-item" title={locale('signIn')}>
                    <Avatar src={images.ICON_LOGIN} /><span className="ml-2 d-md-none d-lg-none">{locale('signIn')}</span>
                  </NavLink>
                </NavItem>
              </Fragment>
            )}

            <NavItem key="language" className="text" style={{ width: "100%" }}>
              <Switch className="pull-right" checkedChildren="ខ្មែរ" unCheckedChildren="EN" checked={lang === constants.KH} onChange={(value) => { onSetLocale(value); }} />
            </NavItem>

            {/* {isAuthorize && (
              <Fragment>
                <NavItem key="userName" className="d-flex p-0 pl-3">
                  <div className="ml-2 d-flex font-20">
                    <Dropdown
                      trigger={["click"]}
                      overlay={renderDropdownMenu}
                      overlayClassName="header-menu-dropdown"
                    >
                      <Icon
                        type="more"
                        className="text-muted p-2 rounded-circle"
                      />
                    </Dropdown>
                  </div>
                </NavItem>
              </Fragment>
            )} */}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

Header.propTypes = {
  setLocale: PropTypes.func,
  lang: PropTypes.string,
  isAuthorize: PropTypes.bool
};

export default Header;
