import * as actionTypes from "./actionTypes";

export const setDefault = () => ({
  type: actionTypes.APP_SET_DEFAULT
});

export const setIsLoading = (isLoading) => ({
  type: actionTypes.APP_SET_IS_LOADING,
  isLoading
});

export const setIsOnline = (isOnline) => ({
  type: actionTypes.APP_SET_IS_ONLINE,
  isOnline
});

