import * as actionTypes from "../actions/actionTypes";

const initialState = {
  canvasHistory: {},
  canvasHistories: [],
  total: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.CANVAS_HISTORY_SET_CANVAS_HISTORIES:
    return { ...state, canvasHistories: action.canvasHistories };
  case actionTypes.CANVAS_HISTORY_SET_CANVAS_HISTORY:
    return { ...state, canvasHistory: action.canvasHistory };
  case actionTypes.CANVAS_HISTORY_SET_TOTAL:
    return { ...state, total: action.total };
  default:
    return state;
  }
};

export default reducer;
