import * as actionTypes from "./actionTypes";
import { databaseRef } from "@/configs/firebase";
import { setDefault } from "./app";

const setCanvasStatuses = canvasStatuses => ({
  type: actionTypes.CANVAS_STATUS_SET_CANVAS_STATUSES,
  canvasStatuses
});

// const setCanvasStatus = canvasStatus => ({
//   type: actionTypes.CANVAS_STATUS_SET_CANVAS_STATUS,
//   canvasStatus
// });

export const removeCanvasStatus = () => (dispatch, getState) => {
  try {
    dispatch(setDefault());
    const { user } = getState().user;
    databaseRef.ref(`/canvas_status/${user.team_id}/${user.id}`).remove();
  } catch (err) {
    console.log(err);
  }
};

export const getCanvasStatuses = (team_id) => (dispatch) => {
  try {
    return databaseRef
      .ref(`canvas_status/${team_id}`)
      .on("value", snapshots => {
        let data = [];
        if (snapshots.val()) data = Object.values(snapshots.val());
        dispatch(setCanvasStatuses(data));
        return data;
      });
  } catch (err) {
    console.log(err);
  }
};
