/* eslint-disable no-irregular-whitespace */

export const html_en = `
<div class="about-us-content">
<p style="text-align:center">
  <span style="font-size:13.999999999999998pt">
    <span style="font-family:Arial">
      <span style="color:#000000">
        <span style="background-color:#ffffff">
          <strong>About Us</strong>
        </span>
      </span>
    </span>
  </span>
</p>
<p>&nbsp;</p>
<p style="text-align:justify">
  <span style="font-size:10pt">
    <span style="font-family:Arial">
      <span style="color:#000000">
        <span style="background-color:#ffffff">
          SmartStart Unipreneur Learning Platform is an educational
          initiative between Impact Hub Phnom Penh and Smart Axiata
          Co., Ltd. It is the first local online learning platform
          that introduces entrepreneurship through a blended
          learning approach to Cambodian students in Khmer and
          English. Our goal is to develop technical capabilities in
          entrepreneurship, business, and to foster the
          entrepreneurial mindsets through the practical
          implementation of these concepts.&nbsp;
        </span>
      </span>
    </span>
  </span>
</p>
<p style="text-align:justify">
  <span style="font-size:10pt">
    <span style="font-family:Arial">
      <span style="color:#000000">
        <span style="background-color:#ffffff">
          This Learning Management System (LMS) delivers a blended
          (on and offline) entrepreneurship program by providing
          university students the latest content that is a mixture
          of global startup theories and successful Cambodian
          examples. In order to ensure a high level of
          students&rsquo; understanding, courses embed written text,
          videos, quizzes and practical activities for a more
          engaging, blended learning user experience for university
          students. For example, online activities require students
          to build canvases as well as interact and work together
          whereas offline activities require students to undertake
          practical work outside the classroom to &lsquo;learn by
          doing&rsquo;.
        </span>
      </span>
    </span>
  </span>
</p>
<p style="text-align:justify">
  <span style="font-size:10pt">
    <span style="font-family:Arial">
      <span style="color:#000000">
        <span style="background-color:#ffffff">
          By going through the courses, university students will
          gain an in-depth understanding of entrepreneurship,
          develop their entrepreneurial mindset, and improve their
          employability and life skills, so they can be exposed to
          the local market and its opportunities for their future
          careers, and be inspired by local business leaders. This
          platform is relevant and valuable for all university
          students - even if they do not want/cannot start their own
          businesses - since having an entrepreneurial mindset is a
          valuable life skill for anyone&rsquo;s personal and
          professional development.
        </span>
      </span>
    </span>
  </span>
</p>
<p style="text-align:justify">
  <span style="font-size:10pt">
    <span style="font-family:Arial">
      <span style="color:#000000">
        <span style="background-color:#ffffff">
          We hope that together we can equip students with the
          skills, knowledge and motivation to become valuable
          members of the community when they graduate. In the
          future, we hope to make this LMS system available to all
          Cambodian universities by the end of 2021, in order to
          mainstream Cambodian entrepreneurial education. This
          initiative has been made possible due to Smart&rsquo;s
          contribution to the&nbsp; Capacity Building and R&amp;D
          Fund and their strong partnership with the Ministry of
          Posts and Telecommunications.
        </span>
      </span>
    </span>
  </span>
</p></div>`;

export const html_kh = `<p style="text-align:center">
<span style="font-size:13.999999999999998pt">
  <span style="font-family:Arial">
    <span style="color:#000000">
      <span style="background-color:#ffffff">
        <strong>អំពីយើង</strong>
      </span>
    </span>
  </span>
</span>
</p>
<p>&nbsp;</p>
<p style="text-align:justify">
<span style="font-size:10pt">
  <span style="font-family:Arial">
    <span style="color:#000000">
      <span style="background-color:#ffffff">
        SmartStart Unipreneur Learning Platform
        គឺជាគំនិតផ្តួចផ្តើមផ្នែកអប់រំរវាងសហគ្រាសសង្គម Impact Hub
        Phnom Penh និង ក្រុមហ៊ុន Smart Axiata Co., Ltd។
        វាគឺជាវេទិការការសិក្សាតាមអនឡាញដំបូងបង្អស់ក្នុងប្រទេសដែលណែនាំភាពជាសហគ្រិនតាមរយៈវិធីសាស្ត្របង្រៀនបែបទំនើបទៅសិស្សានិសិ្សតកម្ពុជាជាភាសាខ្មែរ
        និងភាសាអង់គ្លេស។ គោលដៅរបស់យើង គឺ
        ដើម្បីអភិវឌ្ឍន៍សមត្ថភាពបច្ចេកទេសក្នុងវិស័យសហគ្រិនភាព
        អាជីវកម្ម និងពន្លឿនផ្នត់គំនិតសហគ្រិនភាព
        តាមរយៈការអនុវត្តន៍ជាក់ស្តែង។&nbsp;
      </span>
    </span>
  </span>
</span>
</p>
<p style="text-align:justify">&nbsp;</p>
<p style="text-align:justify">
<span style="font-size:10pt">
  <span style="font-family:Arial">
    <span style="color:#000000">
      <span style="background-color:#ffffff">
        ប្រព័ន្ធគ្រប់គ្រងការរៀនសូត្រ ដែលគេហៅថា Learning Management
        System (LMS) នេះផ្តល់ជូនជាកម្មវិធីសហគ្រិនភាពបែបថ្មីតាមរយៈ
        online និង offline ដោយផ្តល់ឲ្យសិស្សានុសិស្ស
        នូវមាតិការថ្មីចុងក្រោយដែលជាការបញ្ចូលគ្នានៃទ្រឹស្តីអាជីវកម្មបង្កើតថ្មីក្នុងពិភពលោក
        និង
      </span>
    </span>
  </span>
</span>
<span style="font-size:10pt">
  <span style="font-family:Arial">
    <span style="color:#000000">
      ឧទាហរណ៍របស់ អាជីវកម្មជោគជ័យក្នុងប្រទេសកម្ពុជា។
      ដើម្បីធានាឲ្យសិស្សទទួលបានការយល់ដឹងខ្ពស់
      កម្មវិធីនេះបានភ្ជាប់នូវឯកសារ ជាអក្សរ វីឌីអូ កម្រងសំណួរ
      និងសកម្មភាពអនុវត្តជាក់ស្តែងដទៃទៀតសម្រាប់ការចូលរួមបន្ថែម
      ដើម្បីឲ្យ​សិស្សានុសិស្សទទួលបានបទពិសោធន៍ថ្មី។ ឧទាហរណ៍
      សកម្មភាពអនឡាញតម្រូវ​ឲ្យ​សិស្សនិសិ្សត​បំពេញ សន្លឹកកិច្ចការ
      ក៏ដូចជា​ប្រាស្រ័យទាក់ទង និងធ្វើកិច្ចការជាមួយគ្នា
      ខណៈពេលដែល​សកម្មភាព offline
      តម្រូវឲ្យសិស្សនិស្សិតអនុវត្តការងាជាក់ស្តែងនៅខាងក្រៅថ្នាក់រៀនគឺ
      &lsquo;រៀនដោយការអនុវត្តន៍ ផ្ទាល់&rsquo;។
    </span>
  </span>
</span>
</p>
<p>&nbsp;</p>
<p style="text-align:justify">
<span style="font-size:10pt">
  <span style="font-family:Arial">
    <span style="color:#000000">
      <span style="background-color:#ffffff">
        តាមរយៈ​កម្មវិធីសិក្សានេះ
        សិស្សនិសិ្សតនៅសាកលវិទ្យាល័យនឹងយល់កាន់តែស៊ីជម្រៅអំពី​ភាពជាសហគ្រិន
        អភិវឌ្ឍន៍​ផ្នត់គំនិតសហគ្រិន​របស់ពួកគេ
        និង​បង្កើន​ឱកាស​ការងារ
        ក៏ដូចជា​ជំនាញ​សំខាន់ផ្សេងៗក្នុងជីវិត។
        ដូចនេះពួកគេអាចមានភាពលេចញធ្លោក្នុងទីផ្សារការងារក្នុងស្រុក
        និង​ទទួលបាន​ឱកាសជាច្រើនសម្រាប់អាជីពនាពេលអនាគត។
        វេទិកា​រៀនតាមអនឡាញនេះ​ ពាក់ព័ន្ធ និង​មាន​តម្លៃយ៉ាងខ្លាំង
        សម្រាប់​សិស្សនិសិ្សត​សាកលវិទ្យាល័យ បើទោះបីជាពួកគេ​មិនចង់
        ឬ​មិនអាច​បើក​អាជីវកម្មផ្ទាល់ខ្លួនក៏ដោយ
        ព្រោះការដែលមាន​ផ្នត់គំនិត​សហគ្រិនភាព​គឺជា​ជំនាញជីវិតដ៏សំខាន់មួយ​សម្រាប់មនុស្សរាល់គ្នាទាំងការអភិវឌ្ឍន៍ខ្លួនឯង
        និង​អាជីព។
      </span>
    </span>
  </span>
</span>
</p>
<p style="text-align:justify">&nbsp;</p>
<p style="text-align:justify">
<span style="font-size:10pt">
  <span style="font-family:Arial">
    <span style="color:#000000">
      <span style="background-color:#ffffff">
        យើងសង្ឃឹមថា
        យើងទាំងអស់គ្នារួមគ្នាជួយឲ្យសិស្សនិស្សិតមានជំនាញ ចំណេះដឹង
        និងការលើកទឹកចិត្តដើម្បីក្លាយជាសមាជិកដ៏មានតម្លៃក្នុងសហគមន៍នៅពេលដែលពួកគេបញ្ចប់ការសិក្សា។
        នៅពេលអនាគត យើងនឹង​ធ្វើឲ្យប្រព័ន្ធ LMS
        មាននៅតាមសាកលវិទ្យាល័យផ្សេងៗទៀតក្នុងប្រទេសត្រឹមចុងឆ្នាំ
        ២០២១ ដើម្បី​បញ្រ្ចៀបការអប់រំសហគ្រិនភាពនៅកម្ពុជា។
        គំនិតផ្តួចផ្តើមនេះអាចកើតមានឡើង​ដោយ​ការ​ចូលរួមចំណែកពីក្រុមហ៊ុន
        Smart ទៅកាន់មូលនិធិ​ការកសាង​សមត្ថភាព និង ការស្រាវជ្រាវ
        និង​អភិវឌ្ឍន៍ (Capacity Building and R&amp;D Fund)
        និង​ទំនាក់ទំនងភាពជាដៃគូរ​ដ៏ជិតស្និទ្ធជាមួយ​ក្រសួងប្រៃសណីយ៍
        និងទូរគមនាគមន៍។​
      </span>
    </span>
  </span>
</span>
</p>`;
