import * as actionTypes from "./actionTypes";
import {
  authRef,
  facebookProvider,
  googleProvider
} from "../../configs/firebase";
// import { getUser } from "./user";
import { setDefault } from "./app";
import { history } from "@/shared/utils/history";
import { SIGN_IN } from "@/constants/routes";

export const setAuthUserId = userId => ({
  type: actionTypes.AUTH_USER_ID,
  userId
});

const setError = error => ({
  type: actionTypes.AUTH_FAIL,
  error
});

const setIsMailForgetPassword = status => ({
  type: actionTypes.AUTH_IS_MAIL_RESET_PASSWORD,
  status
});

export const signInWithEmailAndPassword = (email, password) => dispatch => {
  try {
    return authRef.signInWithEmailAndPassword(email, password).then(res => {
      let user = res.user;
      dispatch(setAuthUserId(user.uid));
      // dispatch(getUser(user.uid, true));
      return user;
    });
  } catch (err) {
    console.log(err);
  }
};

export const doSignInWithSocial = provider => dispatch => {
  try {
    return authRef
      .signInWithPopup(provider)
      .then(res => {
        dispatch(setAuthUserId(res.user.uid));
        localStorage.setItem("userId", res.user.uid);
        dispatch(setError(null));
        return res.user;
      })
      .catch(error => {
        dispatch(setError(error.message));
      });
  } catch (err) {
    console.log(err);
  }
};

export const signInWithGoogle = () => dispatch => {
  try {
    return authRef
      .signInWithPopup(googleProvider)
      .then(res => {
        dispatch(setAuthUserId(res.user.uid));
        localStorage.setItem("userId", res.user.uid);
        dispatch(setError(null));
        return res.user;
      })
      .catch(error => {
        dispatch(setError(error.message));
      });
  } catch (err) {
    console.log(err);
  }
};

export const signInWithFacebook = () => dispatch => {
  try {
    return authRef.signInWithPopup(facebookProvider).then(res => {
      dispatch(setAuthUserId(res.user.uid));
      localStorage.setItem("userId", res.user.uid);
      dispatch(setError(null));
      return res.user;
    });
  } catch (err) {
    console.log(err);
  }
};

export const signOut = () => dispatch => {
  try {
    return authRef
      .signOut()
      .then(() => {
        dispatch(setAuthUserId(null));
        setError(null);
        localStorage.removeItem("Authorize");
        localStorage.removeItem("userId");
        history.push(SIGN_IN);
      })
      .catch(error => {
        dispatch(setError(error.message));
      });
  } catch (err) {
    console.log(err);
  }
};

export const sendPasswordResetEmail = email => dispatch => {
  try {
    return authRef.sendPasswordResetEmail(email).then(() => {
      dispatch(setIsMailForgetPassword(true));
    });
  } catch (err) {
    console.log(err);
  }
};

export const createUserWithEmailAndPassword = data => dispatch => {
  const { email, password } = data;
  try {
    return authRef.createUserWithEmailAndPassword(email, password).then(res => {
      dispatch(setDefault());
      return res.user;
    });
  } catch (err) {
    console.log(err);
  }
};

export const sendEmailVerification = () => dispatch => {
  try {
    dispatch(setDefault());
    return authRef.currentUser.sendEmailVerification();
  } catch (err) {
    console.log(err);
  }
};

export const sendSignInLinkToEmail = data => dispatch => {
  const { email } = data;

  let actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: "http://localhost:3000/email-verify/token=java",
    // This must be true.
    handleCodeInApp: true
  };
  try {
    return authRef.sendSignInLinkToEmail(email, actionCodeSettings).then(() => {
      dispatch(setDefault());
      return data;
    });
  } catch (err) {
    console.log(err);
  }
};
