import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import classnames from "classnames";
import { Radio, Icon } from "antd";
import { QUIZ_IMAGE, ALT_MEDIA } from "@/constants/constant";
import {
  WrapperFormItem,
  WrapperLabelAnswer
} from "@/components/style-component";

function RadioButtonField(props) {
  const onChange = value => {
    const {
      input: { onChange: reduxFormChange },
      onChange
    } = props;
    reduxFormChange(value);
    if (onChange) {
      onChange(value);
    }
  };

  const {
    label,
    image,
    style,
    required,
    dataSource,
    isAnswer,
    initValue,
    lang,
    input: { value },
    meta: { touched, error }
  } = props;
  const validateStatus = classnames(touched && error ? "error" : "success");
  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px"
  };

  return (
    <Fragment>
      <WrapperFormItem
        help={error}
        label={label}
        required={required}
        validateStatus={validateStatus}
        marginLeft={style && style.marginLeft}
      >
        {
          image && image !== "" &&
          <img src={`${QUIZ_IMAGE}${image}${ALT_MEDIA}`} className="image-responsive" />
        }
        <Radio.Group
          onChange={onChange}
          value={initValue !== -1 ? initValue : value}
          defaultValue={1}
          disabled={false}
          className="float-left"
        >
          {dataSource &&
            dataSource.map((value, index) => {
              return (
                <Fragment key={index}>
                  {value.is_correct && isAnswer ? (
                    <WrapperLabelAnswer>
                      <span className="d-flex align-self-start mt-2 ">
                        <Icon type="check-circle" className="mr-1 text-danger" />{" "}
                      </span>
                      <span>
                        {value[`${lang}_answer`]}
                      </span>
                    </WrapperLabelAnswer>
                  ) : (<Radio key={index} style={radioStyle} value={index}>
                    {value[`${lang}_answer`]}
                  </Radio>)}
                </Fragment>
              );
            })}
        </Radio.Group>
      </WrapperFormItem>
    </Fragment>
  );
}

RadioButtonField.propTypes = {
  tip: PropTypes.string,
  input: PropTypes.any,
  meta: PropTypes.any,
  onChange: PropTypes.func,
  dataSource: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  image: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  isAnswer: PropTypes.bool,
  lang: PropTypes.string,
  initValue: PropTypes.number,
};

function RadioButton(props) {
  return <Field component={RadioButtonField} {...props} />;
}

export default RadioButton;
