// import LocalizedStrings from "react-localization";
import kh from "./kh";
import en from "./en";

const lang = {
  en,
  kh
};

// let locale = new LocalizedStrings(lang);

const locale = (key = "") => {
  let shortLang = localStorage.getItem("lang") || "kh";

  if (lang[shortLang][key])
    return lang[shortLang][key];
  return key;
};

export default locale;
