import styled from "styled-components";
import * as images from "@/constants/images";
import { Layout } from "antd";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "@/constants/constant";

// background-image: url(${images.BACKGROUND_IMAGE}),
//     linear-gradient(to bottom, #30ea98, #3ab398) !important;
export const WrapperSidebar = styled(Layout.Sider)`
  background-size: 100% !important;
  /* background-position: bottom !important; */
  /* background-repeat: no-repeat !important; */
  /* box-shadow: 0px 4px 8px 0px rgba(38, 35, 66, 0.25); */
  /* background-image: url(${images.BACKGROUND_IMAGE}),
    linear-gradient(to bottom, ${PRIMARY_COLOR}, ${PRIMARY_COLOR}) !important; */
    background-color: ${SECONDARY_COLOR} !important;
    /* border: 1px solid ${SECONDARY_COLOR}; */

  &.ant-layout-sider-collapsed {
    .Lesson-title {
      visibility: collapse;
    }
  }

  .Lesson-title {
    color: ${PRIMARY_COLOR};
    padding: 24px;
    font-size: 20px;
    position: relative;
    border-bottom: 2px solid #fff;

    i {
      top: 0;
      right: 0;
      cursor: pointer;
      font-size: 1rem;
      padding: 0.5rem;
      position: absolute;
    }
  }

  .ant-menu {
    padding:10px;
    background: inherit;
    border-right: unset;
    visibility: ${props => props.collapsed && "collapse"};
    .ant-menu-item {
      color: ${PRIMARY_COLOR};
      font-size: 16px;
      margin: 0rem !important;
      padding: 0px ${props => !props.collapsed && "24px"} !important;

      &.ant-menu-item-selected,
      :active {
        background-color: #fff !important;
      }

      :focus {
        outline: unset;
      }
    }
  }

  .ant-menu-inline .ant-menu-item::after,
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after {
    border-right: unset !important;
  }
`;
