// Libs
import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
// import _ from "lodash";
import { Col } from "reactstrap";
import { Icon, Breadcrumb } from "antd";
import { useSelector, useDispatch } from "react-redux";
import useReactRouter from "use-react-router";
import queryString from "query-string";

// Component
import Sidebar from "../sidebar";

// Util
import { WrapperContainerBreadcrumb } from "@/components/style-component";
import * as actions from "@/stores/actions";
import locale from "@/locale";
import { selectActivities } from "@/stores/selectors/activity";

function Content({
  children,
  hasSidebar,
  hasBreadcrumb,
  childrenClassName,
  breadcrumbDatasource,
  // hideFinish
}) {
  const [collapsed, setCollapsed] = useState(false);
  const activities = useSelector(selectActivities);
  // const { activity } = useSelector(state => state.activity);
  // const { activityLog } = useSelector(state => state.activityLog);
  const { session } = useSelector(state => state.session);

  const dispatch = useDispatch();
  const { history } = useReactRouter();
  const { sessionId, activityId } = queryString.parse(history.location.search);

  useEffect(() => {
    if (sessionId) {
      dispatch(actions.getActivities({ sessionId }));
    }
  }, [activityId, dispatch, sessionId]);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  // const handleFinishedActivity = useCallback(() => {
  //   if (_.size(session) && _.size(activity))
  //     dispatch(updateActivityLog(session.id, activity.id, { is_completed: true }));
  // }, [dispatch, session, activity]);

  const matchMedia = window.matchMedia("(max-width: 768px)");
  const { matches } = matchMedia;

  if (!breadcrumbDatasource.length) {
    breadcrumbDatasource = [
      `${locale("session")} ${session ? session.session : ""}`,
      session.name
    ];
  }
  return (
    <Fragment>
      {hasSidebar && (
        <Sidebar
          collapsed={collapsed ? "collapsed" : ""}
          activities={activities}
          onCollapse={onCollapse}
          session={session}
        />
      )}
      <WrapperContainerBreadcrumb
        fluid
        match={matches ? "matches" : ""}
        collapsed={collapsed ? "collapsed" : ""}
        className="wrapper-content p-3"
      >
        <div className="d-flex justify-content-between">
          <div>
            {hasBreadcrumb && (
              <Breadcrumb separator=":" className={`${hasSidebar ? "" : "mt-0"}`}>
                <Icon
                  onClick={onCollapse}
                  className="icon-collapse-sider"
                  type={`menu-${collapsed || matches ? "unfold" : "fold"}`}
                />
                {breadcrumbDatasource.map((value, key) => {
                  return <Breadcrumb.Item key={key}>{value}</Breadcrumb.Item>;
                })}
              </Breadcrumb>
            )}
          </div>
          {/* <div>
            {hasBreadcrumb && (
              <Button type="primary" onClick={handleFinishedActivity} disabled={activityLog.is_completed}>{locale("finish")}</Button>
            )}
          </div> */}

        </div>
        <Col
          xs="12"
          md="12"
          lg={`${hasSidebar ? 10 : 12}`}
          className={`mx-auto ${hasSidebar ? "" : "px-0"} ${childrenClassName ? childrenClassName : ""}`}>
          {children}
        </Col>
      </WrapperContainerBreadcrumb>
    </Fragment>
  );
}

Content.propTypes = {
  children: PropTypes.node,
  hasSidebar: PropTypes.bool,
  hasBreadcrumb: PropTypes.bool,
  childrenClassName: PropTypes.string,
  breadcrumbDatasource: PropTypes.array,
  hideFinish: PropTypes.bool
};

Content.defaultProps = {
  hasSidebar: true,
  hasBreadcrumb: true,
  breadcrumbDatasource: []
};

export default Content;
