// Libs
import React, { Fragment } from "react";
import PropTypes from "prop-types";

// Components
import Header from "./header";
import Body from "./body";
import Footer from "./footer";

function Layout(props) {
  return (
    <Fragment>
      <Header {...props} />
      <Body>{props.children}</Body>
      <Footer />
    </Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.node
};

export default Layout;
