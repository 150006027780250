// Libs
import React from "react";
import PropTypes from "prop-types";
import { WrapperBody } from "@/components/style-component";

function Body(props) {
  return <WrapperBody className="wrapper-body">{props.children}</WrapperBody>;
}

Body.propTypes = {
  children: PropTypes.node
};

export default Body;
