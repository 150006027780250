import styled from "styled-components";
import * as images from "@/constants/images";

export const WrapperEnvelope = styled.div`
  width: 75px;
  height: 75px;
  margin: auto;
  margin-bottom: 1.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: url(${images.ENVELOPE});
`;