/*eslint-disable indent */
import * as actionTypes from "../actions/actionTypes";
import * as constants from "@/constants/constant";

let lang = localStorage.getItem("lang", constants.KH);
if (!["en", "kh"].includes(lang))
  lang = constants.KH;

const initialState = {
  lang
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOCALE:
      return {
        lang: action.lang
      };
  }
  return state;
};

export default reducer;
