import React, { useEffect } from "react";
import { Label, Row, Col } from "reactstrap";
import { Icon, Button } from "antd";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as routes from "@/constants/routes";
import * as actions from "@/stores/actions";
import { isEmpty } from "@/helpers";
import locale from "@/locale";

function NextSession() {
  const { sessions, session } = useSelector(state => state.session);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getSessions());
  }, [dispatch]);

  if (!isEmpty(sessions) && isEmpty(session)) return null;

  let index = -2;
  sessions.forEach((itemSession, i) => {
    if (session.id === itemSession.id) {
      index = i;
    }
  });

  if (!sessions[index + 1]) return null;

  return (
    <Row className="mt-5">
      {sessions[index - 1] && (
        <Col>
          <div className="mt-3 text-left">
            <Button
              type="primary"
              className="rounded d-inline-flex align-items-center"
            >
              <NavLink
                to={`${routes.LESSON}?sessionId=${sessions[index - 1].id}`}
                className="d-flex"
              >
                <Icon type="arrow-left" />
              </NavLink>
            </Button>
            <Label className="text-primary ml-3">
              {locale("previousSession")}
            </Label>
          </div>
        </Col>
      )}
      {sessions[index + 1] && (
        <Col>
          <div className="mt-3 text-right">
            <Label className="text-primary">{locale("nextSession")}</Label>
            <Button
              type="primary"
              className="ml-3 rounded d-inline-flex align-items-center"
            >
              <NavLink
                to={`${routes.LESSON}?sessionId=${sessions[index + 1].id}`}
                className="d-flex"
              >
                <Icon type="arrow-right" />
              </NavLink>
            </Button>
          </div>
        </Col>
      )}
    </Row>
  );
}

export default NextSession;
