import * as actionTypes from "../actions/actionTypes";

const initialState = {
  resources: [],
  resource: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.RESOURCE_SET_RESOURCE:
    return { ...state, resource: action.resource };
  case actionTypes.RESOURCE_SET_RESOURCES:
    return { ...state, resources: action.resources };
  default:
    return state;
  }
};

export default reducer;

