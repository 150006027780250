/* eslint-disable no-unused-vars */

// Libs
import React, { Fragment, useEffect, useCallback, useState } from 'react';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import R from 'ramda';

// Components
import Title from '@/components/common/title';
import * as actions from '@/stores/actions';
import * as routes from '@/constants/routes';
import { Button, Popconfirm, Skeleton } from 'antd';
import { isEmpty, getUserName } from '@/helpers';
import locale from '@/locale';
import useRouter from '@/hook/useRouter';
import useWindowSize from '@/hook/useWindowSize';
import { SCREEN_SM } from '@/constants/constant';
import { history } from '@/shared/utils/history';
import { sleep } from '@/helpers/utils';

function TeamInformation({ isShowMessage, handleVisible }) {
  const { team } = useSelector((state) => state.team);
  const { teamMembers } = useSelector((state) => state.teamMember);
  const { user, users } = useSelector((state) => state.user);
  const { session } = useSelector((state) => state.session);
  const { activity } = useSelector((state) => state.activity);
  const { width } = useWindowSize();
  const { status } = useRouter();
  const dispatch = useDispatch();
  const [isLoadingTeam, setIsLoadingTeam] = useState(false);

  useEffect(() => {
    if (user.team_id) {
      dispatch(actions.getTeam(user.team_id));
      dispatch(actions.getUsers({ class_id: user.class_id }));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (_.size(team)) dispatch(actions.getTeamMembers({ team_id: team.id }));
  }, [dispatch, team]);

  const handleEdit = useCallback(() => {
    handleVisible();
  }, [handleVisible]);

  const onLeaveGroup = () => {
    // const teamId = user.team_id;
    const userId = user.id;

    // assign leader
    if (user.is_team_leader) {
      const candidateLeader = teamMembers.find(
        (item) => item.is_verify && item.user_id !== userId
      );

      if (candidateLeader) {
        dispatch(
          actions.updateUser({
            is_team_leader: true,
            id: candidateLeader.user_id,
          })
        );

        dispatch(
          actions.updateTeamMember({
            is_team_leader: true,
            id: candidateLeader.id,
          })
        );

        // dispatch(
        //   actions.updateTeam({
        //     id: teamId,
        //     user_id: candidateLeader.user_id,
        //   })
        // );
      } else {
        dispatch(actions.deleteTeamMembers(_.map(teamMembers, 'id')));
      }

      dispatch(
        actions.updateUser({
          team_id: '',
          is_join_team: false,
          is_team_leader: false,
        })
      );

      const findTeamMember = teamMembers.find(
        (item) => item.user_id === userId
      );
      if (findTeamMember)
        dispatch(actions.deleteTeamMembers([findTeamMember.id]));
    }
  };

  let urlEdit = `${routes.FORM_TEAM}?sessionId=${session.id}&activityId=${activity.id}&teamId=${team.id}&status=edit`;

  if (isEmpty(activity.id))
    urlEdit = `${routes.FORM_TEAM}?teamId=${team.id}&status=edit`;

  // let target = '_blank';
  // if (status === 'edit') {
  //   target = '_self';
  // }

  // let target = "_self";
  // console.log(status);

  let param = {
    to: urlEdit,
    // target: target,
  };

  if (handleVisible != undefined) {
    param.onClick = handleEdit;
  }

  // if (user?.id && !user?.team_id && !activity?.id) {
  //   history.push(urlEdit);
  // }

  if (!team.name) {
    return (
      <Fragment>
        <Title
          tag='h6'
          className='mb-5 text-muted'
          title={locale('messageTeamLeaderInfo')}
        />

        <Button
          ghost
          type='primary'
          className='mb-5 d-flex mx-auto'
          onClick={() => history.push(urlEdit)}
        >
          {locale('buttonCreateTeam')}
        </Button>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {isShowMessage && (
        <Title
          tag='h6'
          className='mb-4 text-muted text-center font-weight-normal'
          title={locale('messageSuccessJoinTeam').replace('#####', team.name)}
        />
      )}
      <div
        className={`${
          width >= SCREEN_SM ? 'p-5' : 'p-3'
        } rounded text-center box-shadow`}
      >
        <div className='d-flex align-items-end justify-content-end'>
          <div>
            {user.is_team_leader && (
              <Link {...param}>
                <Label
                  style={{ cursor: 'pointer' }}
                  className='pl-3 font-16 text-primary font-weight-bold'
                >
                  {locale('edit')}
                </Label>
              </Link>
            )}
            {user.team_id && (
              <Popconfirm
                title={locale('leaveGroupQuestion')}
                onConfirm={onLeaveGroup}
                // onCancel={cancel}
                okText={locale('okey')}
                cancelText={locale('cancel')}
              >
                <Label
                  // onClick={onLeaveGroup}
                  style={{ cursor: 'pointer' }}
                  className='pl-5 font-16 text-danger font-weight-bold'
                >
                  {locale('leaveGroup')}
                </Label>
              </Popconfirm>
            )}
            {/* <Label
              //onClick={this.onShowForm}
              style={{ cursor: "pointer" }}
              className="pl-3 font-16 text-primary font-weight-bold"
            >
              Save
            </Label> */}
          </div>
        </div>
        {/* {!team.id && <Skeleton active paragraph={{ rows: 15 }} />} */}
        {/* {team?.id && ( */}
        <>
          <Title
            tag='h6'
            title={locale('labelAskTeamName')}
            className='mb-4 text-muted text-left font-weight-normal'
          />
          <Title
            tag='h6'
            title={team?.name}
            className='py-3 px-4 mb-4 text-muted text-left font-weight-normal rounded-pill border-1 border-primary w-50'
          />
          <Title
            tag='h6'
            title={locale('labelShortDescTeamProblem')}
            className='mb-4 text-muted text-left font-weight-normal'
          />
          <Title
            tag='h6'
            className='py-3 px-4 mb-4 text-muted text-left font-weight-normal rounded-pill border-1 border-primary'
            title={team?.description}
          />
          <Title
            tag='h6'
            title={locale('labelAskTeamMember')}
            className='mb-4 text-muted text-left font-weight-normal'
          />
        </>
        {/* )} */}
        {/* {teamMembers.length <= 0 && (
          <Skeleton active paragraph={{ rows: 15 }} />
        )} */}
        {teamMembers.length > 0 && (
          <div className='pt-3'>
            {teamMembers.map((itemTeamMember) => {
              let foundUser = R.find(R.propEq('id', itemTeamMember.user_id))(
                users
              );

              let userName = getUserName(foundUser);

              return (
                <div
                  className='mb-3 d-flex justify-content-between'
                  key={itemTeamMember.id}
                >
                  <Label className='text-left'>
                    {userName}
                    {itemTeamMember.is_team_leader
                      ? ` (${locale('Leader')})`
                      : ''}
                  </Label>
                  <span
                    style={{ minWidth: '150px', maxHeight: '35px' }}
                    className={`pl-2 pr-2 text-white ${
                      itemTeamMember.is_verify ? 'bg-primary' : 'bg-warning'
                    } rounded-pill`}
                  >
                    {itemTeamMember.is_verify
                      ? locale('teamMember')
                      : locale('pendingApproval')}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Fragment>
  );
}

TeamInformation.propTypes = {
  isShowMessage: PropTypes.bool,
  handleVisible: PropTypes.func,
};

TeamInformation.defaultProps = {
  isShowMessage: true,
};

export default TeamInformation;
