import React, { Fragment } from "react";
import CommonSecurity from "./common-security";
import Layout from "./layout";

function Security() {
  return (
    <Fragment>
      <Layout>
        <CommonSecurity />
      </Layout>
    </Fragment>
  );
}

export default Security;
