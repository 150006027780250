import * as actionTypes from "../actions/actionTypes";

const initialState = {
  userId: !localStorage.getItem("userId")
    ? null
    : localStorage.getItem("userId"),
  isVerify: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.AUTH_USER_ID:
    return { ...state, userId: action.userId };
  case actionTypes.AUTH_FAIL:
    return { ...state, error: action.error };
  case actionTypes.AUTH_IS_MAIL_RESET_PASSWORD:
    return { ...state, isMailForgetPassword: action.status };
  default:
    return state;
  }
};

export default reducer;
