// Libs
//import { hot } from "react-hot-loader";
import React, { Fragment, useEffect, useCallback, useState } from 'react';
import { BrowserRouter, Router } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

// Components
import RouteComponent from '../routes';

// Helper
import { history } from '@/shared/utils/history';
import {
  getSessions,
  getSessionLogs,
  setSessionById,
  getActivities,
  setActivity,
  setActivityById,
  getResource,
  getHtmlPage,
  getCheckList,
  getQuizzes,
  getQuizLogs,
  removeCanvasStatus,
  getTeam,
  getClass,
  getTeamMembersNotifications,
  logUserOnline,
  signOut,
  getUser,
  getTeacher,
  getSurvey,
} from '@/stores/actions';
import * as constants from '@/constants/constant';
import useRouter from '@/hook/useRouter';
import { authRef } from '@/configs/firebase';
import { ENTER_CLASS_CODE, EMAIL_VERIFY } from '@/constants/routes';
import {
  getActivityLogs,
  insertActivityLog,
} from '@/stores/actions/activityLog';

function App() {
  const { user } = useSelector((state) => state.user);
  const { userId } = useSelector((state) => state.auth);
  const { lang } = useSelector((state) => state.lang);
  const { activities, activity } = useSelector((state) => state.activity);
  const { sessions } = useSelector((state) => state.session);
  const { resource } = useSelector((state) => state.resource);
  const { teacher } = useSelector((state) => state.teacher);
  const dispatch = useDispatch();
  let { sessionId, activityId } = useRouter();
  const [isVerify, setIsVerify] = useState(true);

  if (!activityId && activity.id) {
    activityId = activity.id;
  }

  useEffect(() => {
    if (_.size(user)) {
      dispatch(getSessions());
      dispatch(getSessionLogs());
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (sessions.length) dispatch(setSessionById(sessionId));
  }, [dispatch, sessionId, sessions]);

  useEffect(() => {
    if (sessionId) {
      dispatch(getActivities({ sessionId }));
      dispatch(getActivityLogs(sessionId));
    }
  }, [dispatch, sessionId, activityId]);

  useEffect(() => {
    if (activities.length && !activityId) dispatch(setActivity(activities[0]));
  }, [dispatch, activities, activityId]);

  useEffect(() => {
    if (activities.length && activityId) {
      dispatch(setActivityById(activityId));
    }
  }, [dispatch, activities, activityId]);

  useEffect(() => {
    if (_.size(activity)) {
      if (activity.resource_id) dispatch(getResource(activity.resource_id));
    }
  }, [dispatch, activity]);

  useEffect(() => {
    if (_.size(resource) && _.size(activity)) {
      if (activity.type === constants.ACTIVITY_TYPE_CHECK_LIST) {
        if (resource.check_list_id)
          dispatch(getCheckList(resource.check_list_id));
      } else if (activity.type === constants.ACTIVITY_TYPE_FORM_TEAM) {
        // return <FormingTeamLearningDashboard />
      } else if (activity.type === constants.ACTIVITY_TYPE_CANVAS) {
        // return <Canvas />
      } else if (activity.type === constants.ACTIVITY_TYPE_SURVEY) {
        if (resource.survey_id) dispatch(getSurvey(resource.survey_id));
      } else if (activity.type === constants.ACTIVITY_TYPE_QUIZ) {
        if (resource.quiz_id) {
          dispatch(getQuizzes(resource.quiz_id));
          dispatch(getQuizLogs({ sessionId, activityId: activity.id }));
        }
      } else if (activity.type === constants.ACTIVITY_TYPE_PAGE) {
        if (resource.html_page_id) dispatch(getHtmlPage(resource.html_page_id));
      }
    }
  }, [dispatch, activity, sessionId, resource]);

  useEffect(() => {
    if (_.size(user)) {
      if (user.team_id) {
        dispatch(removeCanvasStatus());
        dispatch(getTeam(user.team_id));
      }
    }
  });

  useEffect(() => {
    if (userId) {
      dispatch(logUserOnline(userId));
      dispatch(getTeacher(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (_.size(user)) {
      if ((!user.class_id || !isVerify) && !_.size(teacher)) {
        history.push(ENTER_CLASS_CODE);
      } else {
        dispatch(getClass(user.class_code));
        dispatch(getTeamMembersNotifications());
      }
    }
  }, [dispatch, user, teacher, isVerify]);

  useEffect(() => {
    authRef.onAuthStateChanged((firebaseUser) => {
      localStorage.setItem('lang', lang);
      if (!firebaseUser) {
        signOut();
        return false;
      }

      if (firebaseUser) {
        if (process.env.REACT_APP_DEPLOY === 'default') {
          if (!firebaseUser.emailVerified) {
            setIsVerify(false);
            history.push(EMAIL_VERIFY);
          } else {
            dispatch(getUser(firebaseUser.uid));
          }
        }

        if (process.env.REACT_APP_DEPLOY !== 'default') {
          dispatch(getUser(firebaseUser.uid));
        }
      }
    });

    // Todo generate canvas to fireStore
    // dispatch(actions.generateCanvases());
  }, [dispatch, lang]);

  const delayInsertActivityLog = useCallback(
    _.debounce(() => {
      if (sessionId && activityId) {
        if (activity.type) {
          let is_completed = true;
          if (
            [
              constants.ACTIVITY_TYPE_QUIZ,
              constants.ACTIVITY_TYPE_CHECK_LIST,
            ].includes(activity.type)
          ) {
            is_completed = false;
          }
          dispatch(insertActivityLog(sessionId, activityId, { is_completed }));
        }
      }
    }, 500),
    [sessionId, activityId, activity.type]
  );

  useEffect(() => {
    delayInsertActivityLog();
    return delayInsertActivityLog.cancel;
  }, [dispatch, sessionId, activityId, activity.type, delayInsertActivityLog]);

  useEffect(() => {
    console.log('render....');
  });

  return (
    <BrowserRouter>
      <Fragment>
        <Router history={history}>
          <RouteComponent />
        </Router>
      </Fragment>
    </BrowserRouter>
  );
}

//export default hot(module)(App);
export default App;
