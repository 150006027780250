// Libs
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import Title from "@/components/common/title";
import { CHECK_LIST_FAIL } from "@/constants/images";
import locale from "@/locale";
import { selectActivities } from "@/stores/selectors/activity";
import { LESSON_DETAIL } from "@/constants/routes";
import useRouter from "@/hook/useRouter";
import { Button } from "antd";

function CheckListFail() {
  const activities = useSelector(selectActivities);
  const { history } = useRouter();
  const handleClick = (item) => {
    history.push(`${LESSON_DETAIL}?sessionId=${item.session_id}&activityId=${item.id}`);
  };

  return (
    <Fragment>
      <div className="text-center">
        <img src={CHECK_LIST_FAIL} />
      </div>
      <Title
        tag="p"
        title={locale("messageFailCheckList")}
        className="text-center"
      />
      <div>
        <ul style={{ paddingLeft: "45%" }}>
          {activities.map(item => {
            if (item.is_completed) return null;
            return (
              <li key={item.id}>
                <Button type="link"
                  onClick={() => handleClick(item)}
                >
                  {item.title}
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    </Fragment>
  );
}

export default CheckListFail;
