import React, { Component } from "react";
import { Form as AntForm } from "antd";
import { reduxForm } from "redux-form";
import PropTypes from "prop-types";

class Form extends Component {

  render() {
    const { onSubmit, children, handleSubmit, className } = this.props;
    return (
      <AntForm onSubmit={handleSubmit(onSubmit)} className={className}>
        {children}
      </AntForm>
    );
  }
}

Form.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  handleSubmit: PropTypes.func,
  className: PropTypes.string,
};

export default reduxForm()(Form);