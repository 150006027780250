/* eslint-disable indent */
import styled from 'styled-components';
import { SECONDARY_COLOR, PRIMARY_COLOR } from '@/constants/constant';

export const Wrapper = styled.div`
  color: ${PRIMARY_COLOR};
  min-height: 200px;
  /* padding: 0 1rem 1rem 1rem; */
  /* cursor: ${(props) => (props.disable ? 'no-drop' : 'pointer')}; */
  cursor: pointer;
  /* border-radius: 0.5rem; */
  border: 2px solid #0095336b;
  /* background-color: ${SECONDARY_COLOR}; */
  transition: all ease-in-out 0.25s;
  opacity: ${(props) => props.disable && 0.2};
  /* box-shadow: 0px 1px 2px 0px rgba(38, 35, 66, 0.25); */

  .icon-check {
    text-align: right;
    span.ant-avatar.ant-avatar-circle.ant-avatar-image {
      width: 1.5rem;
      height: auto;
    }
  }

  &:hover {
    box-shadow: ${(props) =>
      !props.disable && '0px 3px 6px 0px rgba(38,35,66,0.25)'};
  }
`;

export const WrapperLessonAndImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.25rem;
  position: relative;

  span {
    position: absolute;
    left: 10%;
    z-index: 1;
    color: ${PRIMARY_COLOR};
    font-size: 50px;
    text-shadow: ${PRIMARY_COLOR} 1px 1px 1px;
  }

  img {
    max-width: 50%;
    max-height: 100px;
    /* margin-left: 0.25rem; */
  }
`;
