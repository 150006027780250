import * as actionTypes from "./actionTypes";
import { firestoreRef, FIRSTORE_TIMESTAMP } from "@/configs/firebase";

export const setCanvasHistories = canvasHistories => ({
  type: actionTypes.CANVAS_HISTORY_SET_CANVAS_HISTORIES,
  canvasHistories
});

export const setCanvasHistory = canvasHistory => ({
  type: actionTypes.CANVAS_HISTORY_SET_CANVAS_HISTORY,
  canvasHistory
});

export const setCanvasHistoryTotal = total => ({
  type: actionTypes.CANVAS_HISTORY_SET_TOTAL,
  total
});

export const getCanvasHistories = () => (dispatch, getState) => {
  try {
    const { user } = getState().user;
    const { canvas } = getState().canvas;

    return firestoreRef
      .collection("canvas_histories")
      .where("team_id", "==", user.team_id)
      .where("canvas_id", "==", canvas.id)
      .orderBy("created", "desc")
      .onSnapshot(snapshots => {
        let data = [];
        snapshots.forEach(doc => {
          data.push({
            ...doc.data(),
            id: doc.id
          });
        });
        dispatch(setCanvasHistories(data));
        dispatch(setCanvasHistoryTotal(data.length));
        return data;
      });
  } catch (err) {
    console.log(err);
  }
};

export const getCanvasHistory = canvasHistoryId => dispatch => {
  try {
    return firestoreRef
      .collection("canvas_histories")
      .doc(canvasHistoryId)
      .onSnapshot(doc => {
        let data = doc.data() || {};
        dispatch(setCanvasHistory(data));
      });
  } catch (err) {
    console.log(err);
  }
};

export const insertCanvasHistory = (obj = {}) => (dispatch, getState) => {
  try {
    const { user } = getState().user;
    obj = {
      ...obj,
      user_id: user.id,
      team_id: user.team_id,
      class_id: user.class_id,
      created: FIRSTORE_TIMESTAMP
    };
    return firestoreRef
      .collection("canvas_histories")
      .doc()
      .set(obj);
  } catch (err) {
    console.log(err);
  }
};