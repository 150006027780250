// Libs
import React, { useState, useCallback, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
// import _ from 'lodash';

// Components
import Title from '@/components/common/title';
import InputText from '@/components/common/input';
import FormWrapper from '@/components/common/form';

// Helper
import * as helpers from '@/helpers';
import { history } from '@/shared/utils/history';
import * as routes from '@/constants/routes';
import * as actions from '@/stores/actions';
import locale from '@/locale';
// import { sleep } from '@/helpers/utils';

function EnterClassCode() {
  // const { user } = useSelector(state => state.user);
  const dispatch = useDispatch();
  // const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  // const { classRoom } = useSelector((state) => state.classRoom);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (user?.class_id) {
      history.push(routes.LESSON);
    }
  }, [user]);

  const handleCancel = () => {
    setError(false);
  };

  const handleEnterClassCode = useCallback(
    async (values) => {
      const { code } = values;
      dispatch(actions.getClass(code))
        .then((classRoom) => {
          dispatch(actions.getSetEnrollment(classRoom.id));
          let updateData = {
            is_join_class: true,
            class_code: code,
            class_id: classRoom.id,
          };
          dispatch(actions.updateUser(updateData)).then(() => {
            history.push(routes.LESSON);
          });
        })
        .catch(() => {
          setError(true);
        });
      // await sleep(1000);
    },
    [dispatch]
  );

  const onSubmit = (values, dispatch) => {
    const validationRule = {
      code: {
        required: true,
      },
    };
    return helpers.createSubmitAction(
      validationRule,
      handleEnterClassCode,
      null,
      false
    )(values, dispatch);
  };

  return (
    <Container fluid>
      <Row className="h-100 enter-class-code">
        <Col lg="6" md="8" xs="12" className="m-auto text-center">
          <Title
            tag="h4"
            className="text-primary mb-4"
            title={locale('titleEnterClassCode')}
          />
          <Title
            tag="h6"
            className="text-muted mb-4"
            title={locale('messageClassCode')}
          />
          <Row>
            <Col lg="5" md="7" xs="12" className="mx-auto">
              <FormWrapper form="resetPassword" onSubmit={onSubmit}>
                <InputText name="code" placeholder="Ex: 123456" />
                <Button
                  type="primary"
                  htmlType="submit"
                  className="mt-2 px-5 font-weight-bold"
                >
                  {locale('submit')}
                </Button>
              </FormWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        visible={error}
        footer={null}
        centered
        maskClosable={false}
        onCancel={handleCancel}
        className="ant-modal-danger text-center"
      >
        <div className="p-4">
          <Title
            tag="h6"
            className="text-danger my-4"
            title={locale('incorrectClassCode')}
          />
          <span className="text-danger mb-2 p-4">
            {locale('messageEnterIncorrectClassCode')}
          </span>
          <Row>
            <Col xs="12">
              <Button
                type="danger"
                onClick={handleCancel}
                className="my-4 px-5 font-weight-bold"
              >
                {locale('okay')}
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </Container>
  );
}

export default EnterClassCode;
