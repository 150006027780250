/* eslint-disable indent */
import _ from "lodash";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  activities: [],
  activity: {},
  loading: false,
  error: ""
};

const findActivityById = (state, action) => {
  let found = _.find(state.activities, { id: action.id });
  return { ...state, activity: found || {} };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTIVITY_SET_ACTIVITY:
      return { ...state, activity: action.activity, loading: true, error: "" };
    case actionTypes.ACTIVITY_SET_ACTIVITIES:
      return {
        ...state,
        activities: action.activities,
        loading: true,
        error: ""
      };
    case actionTypes.ACTIVITY_FIND_ACTIVITY_BY_ID:
      return findActivityById(state, action);
    case actionTypes.ACTIVITY_SET_ACTIVITY_SUCCESS:
      return { ...state, loading: false, error: "" };
    case actionTypes.ACTIVITY_SET_ACTIVITY_ERROR:
      return { ...state, loading: false, error: action.message };
    case actionTypes.ACTIVITY_SET_ACTIVITY_REQUEST:
      return { ...state, loading: true, error: "" };
    default:
      return state;
  }
};

export default reducer;
