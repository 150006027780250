import * as actionTypes from "../actions/actionTypes";

const initialState = {
  checkLists: [],
  checkList: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.CHECK_LIST_SET_CHECK_LISTS:
    return { ...state, checkLists: action.checkLists };
  case actionTypes.CHECK_LIST_SET_CHECK_LIST:
    return { ...state, checkList: action.checkList };
  default:
    return state;
  }
};

export default reducer;
