import _ from "lodash";
import * as actionTypes from "./actionTypes";
import { firestoreRef } from "@/configs/firebase";
import { setDefault } from "./app";

export const setTeamMembers = teamMembers => ({
  type: actionTypes.TEAM_MEMBER_SET_TEAM_MEMBERS,
  teamMembers
});

export const setTeamMembersNoti = teamMembers => ({
  type: actionTypes.TEAM_MEMBER_SET_TEAM_MEMBERS_NOTI,
  teamMembers
});

export const setTeamMember = teamMember => ({
  type: actionTypes.TEAM_MEMBER_SET_TEAM_MEMBER,
  teamMember
});

export const getTeamMembersNotifications = () => (dispatch, getState) => {
  try {
    const { userId } = getState().auth;
    let ref = firestoreRef.collection("team_members");
    ref = ref.where("user_id", "==", userId);
    ref = ref.where("is_verify", "==", false);
    return ref.onSnapshot(snapshots => {
      let data = [];
      snapshots.forEach(doc => {
        data.push({
          ...doc.data(),
          id: doc.id
        });
      });

      dispatch(setTeamMembersNoti(data));
      return data;
    });
  } catch (err) {
    console.log(err);
  }
};

export const getTeamMembers = (obj = {}) => (dispatch, getState) => {
  try {
    const { userId } = getState().auth;
    let ref = firestoreRef.collection("team_members");

    if (!_.isNil(obj.user_id)) {
      ref = ref.where("user_id", "==", userId);
    }

    if (!_.isNil(obj.is_verify)) {
      ref = ref.where("is_verify", "==", obj.is_verify);
    }

    if (!_.isNil(obj.team_id)) {
      ref = ref.where("team_id", "==", obj.team_id);
    }

    return ref.onSnapshot(snapshots => {
      let data = [];

      snapshots.forEach(doc => {
        data.push({
          ...doc.data(),
          id: doc.id
        });
      });

      dispatch(setTeamMembers(data));
    });
  } catch (err) {
    console.log(err);
  }
};

export const getTeamMember = teamMemberId => dispatch => {
  try {
    return firestoreRef
      .collection("team_members")
      .doc(teamMemberId)
      .onSnapshot(doc => {
        let data = doc.data();
        dispatch(setTeamMember(data));
      });
  } catch (err) {
    console.log(err);
  }
};

export const insertTeamMember = (data = []) => () => {
  try {
    let batch = firestoreRef.batch();

    data.forEach(item => {
      batch.set(firestoreRef
        .collection("team_members")
        .doc(), item);
    });

    batch.commit();
  } catch (err) {
    console.log(err);
  }
};

export const updateTeamMember = (obj = {}) => dispatch => {
  try {
    dispatch(setDefault);
    return firestoreRef
      .collection("team_members")
      .doc(obj.id)
      .update(obj);
  } catch (err) {
    console.log(err);
  }
};

export const deleteTeamMembers = (arrId = []) => () => {
  try {
    let batch = firestoreRef.batch();
    arrId.forEach(id => {
      let docDelete = firestoreRef.collection("team_members").doc(id);
      batch.delete(docDelete);
    });

    return batch.commit();
  } catch (err) {
    console.log(err);
  }
};
