import * as actionTypes from "../actions/actionTypes";

const initialState = {
  default: "",
  isLoading: true,
  isOnline: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.APP_SET_DEFAULT:
    return { ...state, default: "" };
  case actionTypes.APP_SET_IS_LOADING:
    return { ...state, isLoading: action.isLoading };
  case actionTypes.APP_SET_IS_ONLINE:
    return { ...state, isOnline: action.isOnline };
  default:
    return state;
  }
};

export default reducer;
