import styled from "styled-components";
import { Tag } from "antd";

export const WrapperSelectMember = styled.div`
  width: 50%;
  position: relative;
  transition: all ease-in-out 0.25s;

  .ant-select .ant-select-selection {
    padding-bottom: 0rem !important;

    .ant-select-selection__rendered {
      padding-left: 1rem !important;
      padding-right: 1rem !important;

      ul li.ant-select-selection__choice {
        display: none !important;
      }

      .ant-select-selection__placeholder {
        display: block !important;
        margin-left: 0px !important;
        transition: all ease-in-out 0.25s;
        left: ${props => (props.isFocus ? 1 : 3)}rem !important;
      }
    }
  }

  span {
    top: 0;
    color: #fff;
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 0.25s;
    opacity: ${props => (props.isFocus ? 0 : 1)};
    pointer-events: ${props => props.isFocus && "none"};
  }
`;

export const WrapperTag = styled(Tag)`
  height: 40px !important;
  font-size: 14px !important;
  padding: 0rem 1rem !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-color: #35d098 !important;
  background: transparent !important;
  margin-bottom: 0.5rem !important;

  i {
    color: #35d098 !important;
    font-size: 18px !important;
    margin-left: 0.5rem !important;
  }
`;
