import * as actionTypes from "../actions/actionTypes";

const initialState = {
  canvasStatuses: [],
  canvasStatus: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.CANVAS_STATUS_SET_CANVAS_STATUSES:
    return { ...state, canvasStatuses: action.canvasStatuses };
  case actionTypes.CANVAS_SET_FIND_CANVAS:
    return { ...state, canvasStatus: action.canvasStatus };
  default:
    return state;
  }
};

export default reducer;
