export default {
  addMember: 'បន្ថែមសមាជិកក្រុម',
  allRightReserved: 'SmartStart Unipreneurship © ##### រក្សាសិទ្ធិគ្រប់យ៉ាង',
  allRightReservedDev: 'E-Learning Platform អភិវឌ្ឍដោយ Edemy Co.,Ltd',
  alreadyHaveAccount: 'មានគណនីរួចហើយមែនទេ?',
  accountSetting: 'ការកំណត់​គណនី',
  aboutUs: 'អំពីយើងខ្ញុំ',
  agree: 'យល់ព្រម',
  askForgetPassword: 'ភ្លេចពាក្យសម្ងាត់មែនទេ?',
  buttonCreateTeam: 'ចុចត្រង់នេះដើម្បីបង្កើតក្រុម',
  backToCanvas: 'ត្រលប់ទៅសន្លឹកកិច្ចការវិញ',
  back: 'ត្រឡប់ក្រោយ',
  becomeTeamLeader: 'ក្លាយជា ប្រធានក្រុម',
  contactUs: 'ទាក់ទង​មក​​យើងខ្ញុំ',
  createAccount: 'បង្កើត​គណនី',
  canvases: 'សន្លឹកកិច្ចការ',
  canvasList: 'បញ្ជីសន្លឹកកិច្ចការ',
  changeEmail: 'ផ្លាស់ប្តូរអ៊ីមែល',
  cancel: 'បោះបង់',
  comment: 'មតិ',
  dateOfBirth: 'ថ្ងៃខែ​ឆ្នាំ​កំណើត',
  description: 'ការពិពណ៌នា',
  done: 'រួចរាល់',
  email: 'អ៊ីមែល',
  emailAddress: 'អាស័យ​ដ្ឋាន​អ៊ី​ម៉េ​ល',
  emailInUsed: 'អ៊ីមែលនេះត្រូវ​បាន​ប្រើ​ប្រាស់រួចហើយ',
  enterYourClassCode: 'បញ្ចូលលេខកូដថ្នាក់របស់អ្នក',
  emailNotification: 'ការជូនដំណឹងតាមអ៊ីមែល',
  edit: 'កែសម្រួល',
  errorRequired: 'ផ្នែកនេះតម្រូវអោយបំពេញ',
  errorRequired2: '* ផ្នែកនេះតម្រូវអោយបំពេញ',
  errorInvalidEmail: 'អ៊ីមែលមិនត្រឹមត្រូវ',
  errorEmailNotExist: 'អ៊ីមែលនេះមិនមានទេ',
  errorEmailExisted: 'អ៊ីមែលនេះត្រូវបានប្រើរួចហើយដោយគណនីមួយផ្សេងទៀត',
  errorInvalidEmailOrPassword: 'ឈ្មោះអ្នកប្រើ ឬពាក្យសម្ងាត់មិនត្រឹមត្រូវ',
  errorSelectOption: 'សូមជ្រើសរើស',
  errorPasswordLength: 'លេខសម្ងាត់ត្រូវតែមាន ៦ តួអក្សរឬច្រើនជាងនេះ។',
  errorSelectNotEmpty: 'សូមជ្រើសរើសយ៉ាងហោចណាស់មួយក្នុងចំណោមរបស់ទាំងនេះ។',
  female: 'ស្រី',
  formingTeam: 'ការបង្កើតក្រុម',
  finish: 'បញ្ចប់',
  gender: 'ភេទ',
  general: 'ទូទៅ',
  gotoCanvas: 'ទៅសន្លឹកកិច្ចការ',
  home: 'ទំព័រដើម',
  inviteList: 'មើលកាណែនាំចូលក្រុម',
  instruction: 'ការណែនាំ',
  incorrectClassCode: 'លេខកូដថ្នាក់់មិនត្រឹមត្រូវ',
  join: 'ចូលរួម',
  leaveGroup: 'ចាកចេញពីក្រុម',
  leaveGroupQuestion: 'តើអ្នកចង់ចាកចេញពីក្រុមមែនទេ?',
  logIn: 'ចូល',
  learningDashboard: 'ផ្ទាំងសិក្សាគោល',
  logOut: 'ចាកចេញ',
  labelAskTeamName: 'តើក្រុមរបស់អ្នកឈ្មោះអ្វី?',
  labelAskTeamMember: 'តើអ្នកណាជាសមាជិកក្រុមរបស់អ្នក?',
  labelShortDescTeamProblem: 'សូមរៀបរាប់ខ្លីៗអំពីបញ្ហាដែលក្រុមអ្នកកំពុងធ្វើ។',
  labelShortDescTeamName:
    'សូមរៀបរាប់ខ្លីៗអំពីបញ្ហាដែលក្រុមអ្នកកំពុងធ្វើ។ (សូមសរសេរមិនលើសពី ២៥០ តួអក្សរ)',
  male: 'ប្រុស',
  messageUploadSuccess: 'ប្រវត្តិរូបត្រូវបានធ្វើបច្ចុប្បន្នភាពរួចរាល់',
  messageNotExist: 'ទំព័រដែលអ្នកកំពុងរកមិនមានទេ',
  messageReturnHome: 'ត្រឡប់ទៅ ##### ឬ ទៅទំព័ររុករក',
  messageReturnHomeContactAdmin:
    'ត្រលប់ទៅ ##### ឬទាក់ទងផ្នែករដ្ឋបាលសម្រាប់ព័ត៌មានបន្ថែម',
  messageEmailCurrently: 'អាសយដ្ឋានអ៊ីមែលរបស់អ្នកបច្ចុប្បន្ន',
  messageClassCode:
    'ដើម្បីចាប់ផ្តើម សូមបញ្ចូលលេខកូដថ្នាក់ (Class Code) សម្រាប់ថ្នាក់របស់អ្នក។ ប្រសិនបើអ្នកមិនមានលេខកូដទេ​ សូមទៅយកវាពីគ្រូរបស់អ្នក។ ប្រសិនបើមានបញ្ហាណាមួយជាមួយលេខកូដថ្នាក់របស់អ្នក សូមទាក់ទង Impact Hub Phnom Penh ។',
  messageNotiEmail:
    'ដើម្បីគ្រប់គ្រងអ៊ីមែលដែលអ្នកទទួលបាន សូមទៅកាន់ ផ្នែកគ្រប់គ្រងអ៊ីមែល email preference center។',
  messageEmailVerify:
    'សូមពិនិត្យអ៊ីមែលរបស់អ្នកហើយចុចពាក្យ Verify URL ដើម្បីបញ្ជាក់',
  messageVerifyEmailInfo:
    'អ៊ីមែលបញ្ជាក់មួយត្រូវបានផ្ញើ។ សូមពិនិត្យអ៊ីមែលរបស់អ្នកហើយចុចលើ“ Verfify URL” ដើម្បីបញ្ជាក់។',
  messageTeamJoinRejectInfo:
    'អ្នកត្រូវបានអញ្ជើញឱ្យចូលរួមក្រុម ##### ។ ប្រសិនបើនេះជាក្រុមត្រឹមត្រូវចុច “ចូលរួម” ។ បើមិនដូច្នោះទេសូមចុច “បោះបង់” ។',
  messageTeamLeaderInfo:
    'គ្រូរបស់អ្នកបានតំឡើងគណនីអ្នកអោយក្លាយជា “ប្រធានក្រុម” ។ គណនីប្រធានក្រុមអនុញ្ញាតឱ្យអ្នកបន្ថែម ឬដកសមាជិកក្រុមហើយដាក់ស្នើរការងារក្រុមសម្រាប់ការពិនិត្យ។',
  messageCanvasHistoryInfo: 'មើលប្រវត្តិកែប្រែសន្លឹកកិច្ចការ',
  messageSuccessJoinTeam:
    'សូមអបអរសាទរ! ឥឡូវអ្នកជាសមាជិកនៃក្រុម #####។ នេះជាព័ត៌មានក្រុមរបស់អ្នក។ ឥឡូវអ្នកអាចចាប់ផ្តើមសហការគ្នានៅ Canvases ។',
  messageSuccessChecklist: 'ល្អ​ណាស់! អ្នកបានបញ្ចប់វគ្គទី ##### ហើយ។',
  messageFailCheckList:
    'ហ្អេ៎! អ្នកមិនទាន់បានបញ្ចប់សកម្មភាពខាងក្រោមទេ។ បំពេញវាជាមុនសិនមុននឹងបន្តទៅមុខទៀត។',
  messageInstructionMember:
    'មានតែអ្នកដឹកនាំក្រុមទេ ដែលអាចបង្កើតក្រុមបាន។ ក្នុងមួយក្រុមអាចមានតែអ្នកដឹកនាំក្រុមម្នាក់ប៉ុននោះ។ ប្រសិនបើអ្នកមិនទាន់មានក្រុមទេ សូមពិភាក្សាជាមួយមិត្តរួមក្រុមរបស់អ្នកហើយស្នើសុំឱ្យគ្រូរបស់អ្នកតម្លើងគណនីសមាជិកក្រុមរបស់អ្នកឱ្យក្លាយជាអ្នកដឹកនាំក្រុម។',
  messageHappyInvited: 'អបអរសាទរ! អ្នកត្រូវបានគេអញ្ជើញឱ្យចូលរួមក្រុម។',
  messageCancelJoinTeam:
    'អ្នកបានបដិសេធការអញ្ជើញឱ្យចូលរួមក្រុម #####។ ដើម្បីចូលរួមក្រុមសូមស្នើប្រធានក្រុមអោយអញ្ជើញអ្នកចូលក្រុម។',
  messageEmailResetPassword:
    'យើងបានផ្ញើអ៊ីមែលទៅអាសយដ្ឋានអ៊ីមែលដែលអ្នកបានផ្ដល់។ សូមចុចលើតំណ LINK ដើម្បីបញ្ជាក់។',
  messageEnterIncorrectClassCode:
    'លេខកូដថ្នាក់ដែលអ្នកបានបញ្ចូលមិនត្រឹមត្រូវទេ។ សូមព្យាយាមម្តងទៀត ឬទាក់ទងគ្រូរបស់អ្នកសម្រាប់លេខកូដថ្នាក់ត្រឹមត្រូវ។',
  messageNotPassQuiz:
    'អូ! អ្នកធ្វើត្រូវបាន #####%។ អ្នកត្រូវធ្វើត្រូវអោយបានយ៉ាងហោចណាស់ ៥០% ដើម្បីបន្តទៅវគ្គបន្ទាប់។ សូមសិក្សារមេរៀនឡើងវិញ ហើយសឹមធ្វើកម្រងសំណួរម្តងទៀត។',
  messageNoPermission: 'សូមអភ័យទោស! អ្នកគ្មានការអនុញ្ញាតអោយធ្វើសកម្មភាពនេះទេ។',
  next: 'បន្ទាប់',
  nextSession: 'វគ្គបន្ទាប់',
  now: 'ឥឡូវ​នេះ.',
  name: 'ឈ្មោះ',
  okey: 'អូខេ',
  password: 'ពាក្យសម្ងាត់',
  previousSession: 'វគ្គមុន',
  personalDetails: 'ព័ត៌មានផ្ទាល់ខ្លួន',
  pendingApproval: 'រង់ចាំការសម្រេច',
  pending: 'រង់ចាំ',
  redoQuiz: 'ធ្វើសំណួរឡើងវិញ',
  signUp: 'ចុះ​ឈ្មោះ',
  signIn: 'ចូល',
  submit: 'ដាក់ស្នើ',
  school: 'សាលា',
  setting: 'ការកំណត់',
  save: 'រក្សាទុក',
  saveChange: 'រក្សាទុកការផ្លាស់ប្តូរ',
  security: 'សន្តិសុខ',
  session: 'វគ្គ',
  satisfy: 'ពេញចិត្ត',
  status: 'ស្ថានភាព',
  titleEnterClassCode: 'បញ្ចូលលេខកូដថ្នាក់របស់អ្នកដើម្បីចាប់ផ្តើមរៀន',
  titleListSubMenu: 'កម្មវិធីសហគ្រិនភាពស្មាត Smart Entrepreneurship',
  titleResetPassword: 'សូមបញ្ចូលអ៊ីមែលរបស់អ្នកដើម្បីកំណត់ពាក្យសម្ងាត់ឡើងវិញ',
  titleChangePassword: 'ផ្លាស់ប្តូរពាក្យសម្ងាត់របស់អ្នក',
  titleLesson: 'សួស្តី  #####! សូមស្វាគមន៍មកកាន់ថ្នាក់ $$$$$!',
  titleEmailSend: 'ការផ្ទៀងផ្ទាត់ត្រូវបានផ្ញើទៅអ៊ីម៉ែលរបស់អ្នក!',
  team: 'ក្រុម',
  teamMember: 'សមាជិក​ក្រុម',
  teamManagement: 'ការគ្រប់គ្រង​ក្រុម',
  teamName: 'ឈ្មោះ​ក្រុម',
  total: 'សរុប',
  teamDescription: 'ការពិពណ៌នាអំពីក្រុម',
  thankYou: 'សូមអរគុណ!',
  username: 'ឈ្មោះ​អ្នកប្រើប្រាស់',
  unsatisfied: 'មិនពេញចិត្ត',
  viewInvitation: 'មើលការអញ្ជើញ',
  wrongCredentials: 'ព័ត៌មានគណនីមិនត្រឹមត្រូវទេ',
  youGot: 'អ្នក​បាន​ទទួល',
  writtenHistory: 'បានសរសេរចូលក្នុងប្រអប់',
  introCanvas: 'ចូរបំពេញសន្លឹកកិច្ចការខាងក្រោម',
  canvas_no_team_message:
    'អ្នកពុំទាន់អាចធ្វើសន្លឹកកិច្ចការបានទេ។ អ្នកត្រូវបង្កើត ឬ ចូលជាសមាជិកក្រុមណាមួយសិន។',
  form_no_team_message:
    'អ្នកពុំទាន់អាចមើលសមាជិកក្រុមបានទេ។ អ្នកត្រូវបង្កើត ឬ ចូលជាសមាជិកក្រុមណាមួយសិន។',
};
