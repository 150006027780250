import * as actionTypes from "../actions/actionTypes";

const initialState = {
  surveys: [],
  survey: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.SURVEY_SET_SURVEYS:
    return { ...state, surveys: action.surveys };
  case actionTypes.SURVEY_SET_SURVEY:
    return { ...state, survey: action.survey };
  default:
    return state;
  }
};

export default reducer;
