import * as actionTypes from "./actionTypes";
import { firestoreRef, FIRSTORE_TIMESTAMP } from "@/configs/firebase";
import { setDefault } from "./app";
import { setCanvasTeamError } from "./canvasTeam";

export const setCanvasTeamSubmits = canvasTeamSubmits => ({
  type: actionTypes.CANVAS_TEAM_SET_CANVAS_TEAM_SUBMITS,
  canvasTeamSubmits
});

export const setCanvasTeamSubmit = canvasTeamSubmit => ({
  type: actionTypes.CANVAS_TEAM_SET_CANVAS_TEAM_SUBMIT,
  canvasTeamSubmit
});

export const setCanvasTeamSubmitError = message => ({
  type: actionTypes.CANVAS_TEAM_SET_CANVAS_TEAMS_SUBMITS_ERROR,
  message
});

export const setCanvasTeamSubmitSuccess = () => ({
  type: actionTypes.CANVAS_TEAM_SET_CANVAS_TEAMS_SUBMITS_SUCCESS
});

export const setCanvasTeamSubmitRequest = () => ({
  type: actionTypes.CANVAS_TEAM_SET_CANVAS_TEAMS_SUBMITS_REQUEST
});

export const getCanvasTeamSubmits = (team_id, canvas_id) => (dispatch) => {
  try {

    dispatch(setCanvasTeamSubmitRequest());
    return firestoreRef
      .collection("canvas_team_submits")
      .where("team_id", "==", team_id)
      .where("canvas_id", "==", canvas_id)
      .onSnapshot(snapshots => {
        let data = [];
        snapshots.forEach(doc => {
          data.push({
            ...doc.data(),
            id: doc.id
          });
        });
        dispatch(setCanvasTeamSubmits(data));
        dispatch(setCanvasTeamSubmitSuccess());
        return data;
      });
  } catch (err) {
    dispatch(setCanvasTeamError(err));
    console.log(err);
  }
};

export const getCanvasTeam = canvasTeamId => dispatch => {
  try {
    return firestoreRef
      .collection("canvas_team_submit")
      .doc(canvasTeamId)
      .onSnapshot(doc => {
        let data = { ...doc.data(), id: doc.id };
        dispatch(setCanvasTeamSubmit(data));
      });
  } catch (err) {
    console.log(err);
  }
};

export const insertCanvasTeamSubmit = (obj = {}) => (dispatch, getState) => {
  try {
    const { user } = getState().user;
    obj = {
      ...obj,
      user_id: user.id,
      team_id: user.team_id,
      class_id: user.class_id,
      created: FIRSTORE_TIMESTAMP
    };

    dispatch(setDefault());
    return firestoreRef
      .collection("canvas_team_submits")
      .where("team_id", "==", user.team_id)
      .where("canvas_id", "==", obj.canvas_id)
      .onSnapshot(snapshots => {
        let data = {};
        snapshots.forEach(doc => {
          data = {
            ...doc.data(),
            id: doc.id
          };
        });

        if (data.id) {
          firestoreRef
            .collection("canvas_team_submits")
            .doc(data.id)
            .update(obj);
          return data;
        }

        firestoreRef
          .collection("canvas_team_submits")
          .doc()
          .set(obj);
      });
  } catch (err) {
    console.log(err);
  }
};
