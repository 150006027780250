// Libs
import React from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import Public from "./public";
import Private from "./private";
import NotFound from "@/views/not-found";
import PrivateRoute from "@/components/common/private-route";
import ErrorBoundary from "@/components/error-boundary";

function RouteComponent() {
  const { userId } = useSelector(state => state.auth);
  return (
    <ErrorBoundary>
      <Switch key={userId}>
        <PrivateRoute component={Private} authenticationComponent={Public} />
        <Route component={NotFound} />
      </Switch>
    </ErrorBoundary>
  );
}

export default RouteComponent;
