import * as actionTypes from "../actions/actionTypes";

const initialState = {
  users: [],
  user: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.USER_SET_USER:
    return { ...state, user: action.user };
  case actionTypes.USER_SET_USERS:
    return { ...state, users: action.users };
  default:
    return state;
  }
};

export default reducer;
