import styled from "styled-components";
import * as images from "@/constants/images";
import { Drawer } from "antd";
import { PRIMARY_COLOR } from "@/constants/constant";

export const WrapperDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    box-shadow: unset !important;
  }

  .ant-drawer-content {
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    /* background-image: url(${images.BACKGROUND_IMAGE}) !important; */
    background-color: #e8e8e8;
    

    .ant-drawer-header {
      color: #fff;
      height: 85px;
      display: flex;
      align-items: center;
      padding: 0px 24px;
      border-bottom: 2px solid #fff;
      background: inherit !important;

      .ant-drawer-title {
        color:${PRIMARY_COLOR};
        font-size: 20px;
      }

      .ant-drawer-close {
        color: ${PRIMARY_COLOR};
        width: auto;
        height: auto;
        display: flex;
        padding: 0.5rem;
        line-height: unset;
      }
    }

    .ant-drawer-body {
      padding: 0px;
      padding-top: 50px;

      .ant-menu {
        background: inherit;
        border-right: unset;

        .ant-menu-item {
          color: ${PRIMARY_COLOR};
          font-size: 16px;
          margin: 0rem !important;
          padding: 0px ${props => !props.collapsed && "24px"} !important;

          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
          }

          &.ant-menu-item-selected,
          :active {
            background-color: rgba(255, 255, 255, 0.25) !important;
          }

          :focus {
            outline: unset;
          }
        }
      }
    }
  }
`;
