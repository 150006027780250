/* eslint-disable indent */
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  quizzes: [],
  quiz: {},
  totalQuiz: 0,
  totalCorrectAnswer: 0,
  loading: false,
  error: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.QUIZ_SET_QUIZ:
      return { ...state, quiz: action.quiz };
    case actionTypes.QUIZ_SET_QUIZZES:
      return { ...state, quizzes: action.quizzes };
    case actionTypes.QUIZ_SET_QUIZZES_SUCCESS:
      return { ...state, loading: false, error: "" };
    case actionTypes.QUIZ_SET_QUIZZES_REQUEST:
      return { ...state, loading: true, error: "" };
    case actionTypes.QUIZ_SET_QUIZZES_ERROR:
      return { ...state, loading: false, error: action.message };
    case actionTypes.QUIZ_SET_TOTAL_QUIZ:
      return { ...state, totalQuiz: action.total };
    case actionTypes.QUIZ_SET_TOTAL_CORRECT_ANSWER:
      return { ...state, totalCorrectAnswer: action.total };
    default:
      return state;
  }
};

export default reducer;
