import * as actionTypes from "../actions/actionTypes";

const initialState = {
  classRoom: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.CLASS_SET_CLASS:
    return { ...state, classRoom: action.cls };
  default:
    return state;
  }
};

export default reducer;
