import _ from "lodash";
import * as actionTypes from "./actionTypes";
import { firestoreRef, generateDocKey } from "@/configs/firebase";

export const setTeams = teams => ({
  type: actionTypes.TEAM_SET_TEAMS,
  teams
});

export const setTeam = team => ({
  type: actionTypes.TEAM_SET_TEAM,
  team
});

export const getTeams = (obj = {}) => (dispatch) => {
  try {
    let ref = firestoreRef.collection("teams");
    if (!_.isNil(obj.class_id)) {
      ref = ref.where("class_id", "==", obj.class_id);
    }
    return ref.onSnapshot(snapshots => {
      let data = [];
      snapshots.forEach(doc => {
        data.push({
          ...doc.data(),
          id: doc.id
        });
      });

      dispatch(setTeams(data));
    });
  } catch (err) {
    console.log(err);
  }
};

export const getTeam = (teamId = null) => (dispatch, getState) => {
  try {
    const { user } = getState().user;
    if (!teamId) {
      teamId = user.team_id;
    }

    return firestoreRef
      .collection("teams")
      .doc(teamId)
      .onSnapshot(doc => {
        let data = { ...doc.data(), id: doc.id };
        dispatch(setTeam(data));
      });
  } catch (err) {
    console.log(err);
  }
};

export const insertTeam = (obj = {}) => () => {

  let docKey = generateDocKey("teams");
  return firestoreRef
    .collection("teams")
    .doc(docKey)
    .set(obj)
    .then(() => {
      return docKey;
    });
};

export const updateTeam = (obj = {}) => () => {
  try {
    return firestoreRef
      .collection("teams")
      .doc(obj.id)
      .update(obj)
      .then(() => {
        return obj.id;
      });
  } catch (err) {
    console.log(err);
  }
};
