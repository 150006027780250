import * as actionTypes from "./actionTypes";
import { firestoreRef } from "@/configs/firebase";

export const setQuizzes = quizzes => ({
  type: actionTypes.QUIZ_SET_QUIZZES,
  quizzes
});

const setQuizSuccess = () => ({
  type: actionTypes.QUIZ_SET_QUIZZES_SUCCESS
});

const setQuizRequest = () => ({
  type: actionTypes.QUIZ_SET_QUIZZES_REQUEST
});

const setQuizError = message => ({
  type: actionTypes.QUIZ_SET_QUIZZES_ERROR,
  message
});

const setQuiz = quiz => ({
  type: actionTypes.QUIZ_SET_QUIZ,
  quiz
});

export const setTotalQuiz = total => ({
  type: actionTypes.QUIZ_SET_TOTAL_QUIZ,
  total
});

export const setTotalCorrectAnswer = total => ({
  type: actionTypes.QUIZ_SET_TOTAL_CORRECT_ANSWER,
  total
});

export const getQuizzes = (quiz_id) => (dispatch) => {
  try {
    return firestoreRef
      .collection("quizzes")
      .where("parent_id", "==", quiz_id)
      .orderBy("rank", "asc")
      .onSnapshot(snapshots => {
        dispatch(setQuizRequest());
        let data = [];
        snapshots.forEach(doc => {
          data.push({
            ...doc.data(),
            id: doc.id
          });
        });
        dispatch(setTotalQuiz(data.length));
        dispatch(setQuizzes(data));
        dispatch(setQuizSuccess());
      });
  } catch (err) {
    dispatch(setQuizError(err));
    console.log(err);
  }
};

export const getQuiz = quizId => dispatch => {
  try {
    return firestoreRef
      .collection("quizzes")
      .doc(quizId)
      .onSnapshot(doc => {
        let data = doc.data() || {};
        dispatch(setQuiz(data));
        return data;
      });
  } catch (err) {
    console.log(err);
  }
};
