import * as actionTypes from "../actions/actionTypes";

const initialState = {
  course: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.COURSE_SET_COURSE:
    return { ...state, course: action.course };
  default:
    return state;
  }
};

export default reducer;

