import * as actionTypes from "./actionTypes";
import { firestoreRef, FIRSTORE_TIMESTAMP } from "@/configs/firebase";

export const setActivityLogs = activityLogs => ({
  type: actionTypes.ACTIVITY_LOG_SET_ACTIVITY_LOGS,
  activityLogs
});

export const setActivityLog = activityLog => ({
  type: actionTypes.ACTIVITY_LOG_SET_ACTIVITY_LOG,
  activityLog
});

export const getActivityLog = (sessionId, activityId) => (dispatch, getState) => {
  try {
    const userId = getState().auth.userId;

    return firestoreRef
      .collection("activity_logs")
      .where("user_id", "==", userId)
      .where("session_id", "==", sessionId)
      .where("activity_id", "==", activityId)
      .onSnapshot(snapshots => {
        let data = {};
        if (snapshots.size) {
          let doc = snapshots.docs[0];
          data = { ...doc.data(), id: doc.id };
        }
        dispatch(setActivityLog(data));
      });
  } catch (err) {
    console.log(err);
  }
};

export const getActivityLogs = (sessionId) => (dispatch, getState) => {
  try {

    const { userId } = getState().auth;
    return firestoreRef
      .collection("activity_logs")
      .where("user_id", "==", userId)
      .where("session_id", "==", sessionId)
      // .orderBy("created", "desc")
      .onSnapshot(snapshots => {
        let data = snapshots.docs.map(doc => {
          return { ...doc.data(), id: doc.id };
        });

        // console.log(data, userId, sessionId);
        dispatch(setActivityLogs(data));
      });
  } catch (err) {
    console.log(err);
  }
};

export const insertActivityLog = (sessionId, activityId, data = {}) => (dispatch, getState) => {
  try {
    const { userId } = getState().auth;

    return firestoreRef
      .collection("activity_logs")
      .where("user_id", "==", userId)
      .where("session_id", "==", sessionId)
      .where("activity_id", "==", activityId)
      .get()
      .then(snapshots => {

        if (snapshots.size) {
          const doc = snapshots.docs[0];
          // return firestoreRef
          //   .collection("activity_logs")
          //   .doc(doc.id)
          //   .update(data).then(() => {
          dispatch(setActivityLog({ ...doc.data(), id: doc.id, ...data }));
          // });
        } else {

          // let docId = firestoreRef.collection("activity_logs").doc().id;
          const docId = `${userId}_${activityId}`;
          let newData = {
            id: docId,
            user_id: userId,
            session_id: sessionId,
            activity_id: activityId,
            created: FIRSTORE_TIMESTAMP,
            is_completed: false,
            ...data,
          };

          firestoreRef
            .collection("activity_logs")
            .doc(docId)
            .set(newData).then(() => {
              dispatch(setActivityLog(newData));
            });
        }
      });
  } catch (err) {
    console.log(err);
  }
};

export const updateActivityLog = (sessionId, activityId, data = {}) => (dispatch, getState) => {
  try {
    const { userId } = getState().auth;
    return firestoreRef
      .collection("activity_logs")
      .where("user_id", "==", userId)
      .where("session_id", "==", sessionId)
      .where("activity_id", "==", activityId)
      .get()
      .then(snapshots => {

        if (snapshots.size) {

          let doc = snapshots.docs[0];
          dispatch(setActivityLog({ ...doc.data(), id: doc.id, ...data }));
          return firestoreRef
            .collection("activity_logs")
            .doc(doc.id)
            .update(data);
        }
      });

  } catch (err) {
    console.log(err);
  }
};
