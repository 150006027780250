// Libs
import React from "react";
import PropTypes from "prop-types";

// Component
import Layout from "@/components/layout/content";

function LearningDashboard({
  children,
  hasSidebar,
  hasBreadcrumb,
  childrenClassName,
  breadcrumbDatasource,
  hideFinish
}) {
  return (
    <Layout
      hasSidebar={hasSidebar}
      hasBreadcrumb={hasBreadcrumb}
      childrenClassName={childrenClassName}
      breadcrumbDatasource={breadcrumbDatasource}
      hideFinish={hideFinish}
    >
      {children}
    </Layout>
  );
}

LearningDashboard.propTypes = {
  children: PropTypes.node,
  childrenClassName: PropTypes.string,
  hasSidebar: PropTypes.bool,
  hasBreadcrumb: PropTypes.bool,
  breadcrumbDatasource: PropTypes.array,
  hideFinish: PropTypes.bool
};

export default LearningDashboard;
