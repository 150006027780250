// Libs
import React from "react";
import { Row, Col } from "reactstrap";

// Components
import Form from "./form/index";
import Layout from "@/components/layout/content";
import locale from "@/locale";

function FormingTeam() {
  const breadcrumbDatasource = [locale("setting"), locale("teamManagement")];
  return (
    <Layout
      hasSidebar={false}
      hasBreadcrumb={true}
      breadcrumbDatasource={breadcrumbDatasource}
    >
      <Row>
        <Col xl="6" lg="8" md="10" xs="12" className="mx-auto">
          <Form />
        </Col>
      </Row>
    </Layout>
  );
}

export default FormingTeam;
