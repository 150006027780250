import * as actionTypes from "./actionTypes";
import { firestoreRef } from "@/configs/firebase";

export const setActivity = activity => ({
  type: actionTypes.ACTIVITY_SET_ACTIVITY,
  activity
});

export const setActivityById = id => ({
  type: actionTypes.ACTIVITY_FIND_ACTIVITY_BY_ID,
  id
});

const setActivitySuccess = () => ({
  type: actionTypes.ACTIVITY_SET_ACTIVITY_SUCCESS
});

const setActivityError = message => ({
  type: actionTypes.ACTIVITY_SET_ACTIVITY_ERROR,
  message
});

const setActivityRequest = () => ({
  type: actionTypes.ACTIVITY_SET_ACTIVITY_REQUEST
});

export const setActivities = activities => ({
  type: actionTypes.ACTIVITY_SET_ACTIVITIES,
  activities
});

export const getActivities = (obj = { type: null, sessionId: "" }) => (dispatch, getState) => {
  const { session } = getState().session;
  let { type, sessionId } = obj;

  sessionId = sessionId ? sessionId : session.id;
  try {
    dispatch(setActivityRequest());
    let ref = firestoreRef.collection("activities");

    if (type) {
      ref = ref.where("type", "==", type);
    }

    return ref
      .where("session_id", "==", sessionId)
      .orderBy("rank", "asc")
      .onSnapshot(snapshots => {
        let data = [];
        snapshots.forEach(doc => {
          data.push({
            ...doc.data(),
            id: doc.id
          });
        });
        dispatch(setActivities(data));
        dispatch(setActivitySuccess());
        return data;
      });
  } catch (err) {
    setActivityError(err);
    console.log(err);
  }
};

export const getActivity = activityId => dispatch => {
  try {
    return firestoreRef
      .collection("activities")
      .doc(activityId)
      .onSnapshot(doc => {
        let data = { ...doc.data(), id: doc.id };
        dispatch(setActivity(data));
      });
  } catch (err) {
    console.log(err);
  }
};
