import * as actionTypes from "./actionTypes";
import { firestoreRef } from "@/configs/firebase";

export const setTeacher = teacher => ({
  type: actionTypes.TEACHER_SET_TEACHER,
  teacher
});

export const getTeacher = id => dispatch => {
  try {
    return firestoreRef
      .collection("teachers")
      .doc(id)
      .onSnapshot(doc => {
        if (doc.exists) {
          let data = doc.data();
          dispatch(setTeacher(data));
          return data;
        }
        return null;
      });
  } catch (err) {
    console.log(err);
  }
};
