import _ from "lodash";
import * as actionTypes from "./actionTypes";
import { firestoreRef } from "@/configs/firebase";
import { setDefault } from "./app";

export const setUser = user => ({
  type: actionTypes.USER_SET_USER,
  user
});

export const setUsers = users => ({
  type: actionTypes.USER_SET_USERS,
  users
});

export const registerUser = data => dispatch => {
  try {
    dispatch(setUser(data));
    return firestoreRef
      .collection("users")
      .doc(data["id"])
      .set(data);
  } catch (err) {
    console.log(err);
  }
};

export const getUser = (userId) => (dispatch) => {
  try {
    return firestoreRef
      .collection("users")
      .doc(userId)
      .onSnapshot(doc => {
        let user = doc.data() || {};
        dispatch(setUser(user));
        localStorage.setItem("userId", userId);
      });
  } catch (err) {
    console.log(err);
  }
};

export const getUsers = (obj = {}) => (dispatch) => {
  try {
    let ref = firestoreRef.collection("users");
    if (!_.isNil(obj.class_id)) {
      ref = ref.where("class_id", "==", obj.class_id);
    }

    if (!_.isNil(obj.is_team_leader)) {
      ref = ref.where("is_team_leader", "==", obj.is_team_leader);
    }

    if (!_.isNil(obj.team_id)) {
      ref = ref.where("team_id", "==", obj.team_id);
    }

    if (!_.isNil(obj.is_join_team)) {
      ref = ref.where("is_join_team", "==", obj.is_join_team);
    }

    return ref.onSnapshot(snapshots => {
      let data = [];
      snapshots.forEach(doc => {
        let tmp = {
          ...doc.data(),
          id: doc.id
        };

        // if (obj.isMember || !doc.data().is_team_leader) {
        //   data.push(tmp);
        // }

        data.push(tmp);

      });
      dispatch(setUsers(data));
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateUser = (data = {}) => (dispatch, getState) => {
  try {
    const { userId } = getState().auth;
    let id = userId;
    if (data.id) id = data.id;
    dispatch(setDefault());
    return firestoreRef
      .collection("users")
      .doc(id)
      .update(data);
  } catch (err) {
    console.log(err);
  }
};

export const updateUsers = (arrIds = [], data) => () => {
  try {
    let batch = firestoreRef.batch();
    arrIds.forEach(id => {
      let docUpdate = firestoreRef.collection("users").doc(id);
      batch.update(docUpdate, data);
    });

    return batch.commit();
  } catch (err) {
    console.log(err);
  }
};

