import React, { Fragment } from "react";
import { Col, Label } from "reactstrap";
import { Button } from "antd";
import { useSelector } from "react-redux";
import Title from "@/components/common/title";
import InputText from "@/components/common/input";
import * as helpers from "@/helpers";
import FormWrapper from "@/components/common/form";
import locale from "@/locale";

function CommonEmail() {
  const { user } = useSelector(state => state.user);

  const onSubmit = values => {
    const validationRule = {
      email: {
        required: true,
        type: "email"
      }
    };
    return helpers.createSubmitAction(
      validationRule,
      null // if no need custom values before submit pass null
    )(values);
  };

  if (!user.id) return null;

  return (
    <Fragment>
      <div className="page-auto page-email">
        <div>
          <Title tag="span" title={locale("email")} className="title-general" />
        </div>
        <div className="content">
          <Col className="padding-left0">
            <Title
              tag="span"
              title={locale("changeEmail")}
              className="title-change-email"
            />
          </Col>
          <Col className="padding-left0">
            <Col className="padding-left0 in-one">
              <div className="current">
                <Title
                  tag="span"
                  title={locale("messageEmailCurrently")}
                  className="title-detail-email"
                />
              </div>
              <div className="user">
                <Title
                  tag="span"
                  title={user.email}
                  className="title-bold-email"
                />
              </div>
            </Col>
            <FormWrapper
              className="form-context"
              form="createAccount"
              onSubmit={onSubmit}
            >
              <Label>{locale("emailAddress")}</Label>
              <InputText
                name="Email"
                placeholder={locale("email")}
                disabled
                defaultValue={user.email}
              />
              {/* <button className="btn-submit" htmlType="submit">
                    Save
              </button> */}
              <Button htmlType="submit" className="btn-submit">
                {locale("save")}
              </Button>
              <Col className="padding-left0">
                <Title
                  tag="span"
                  title={locale("emailNotification")}
                  className="title-change-email"
                />
                <div className="detail">
                  <p>{locale("messageNotiEmail")}</p>
                </div>
              </Col>
            </FormWrapper>
          </Col>
        </div>
      </div>
    </Fragment>
  );
}

export default CommonEmail;
