import styled from "styled-components";
import { Container } from "reactstrap";
import { PRIMARY_COLOR } from "@/constants/constant";

export const WrapperContainerBreadcrumb = styled(Container)`
  .ant-breadcrumb {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin: 8px 0px 24px 15px;
    text-transform: uppercase;

    .icon-collapse-sider {
      color: ${PRIMARY_COLOR};
      padding-right: 0.75rem;
      font-size: 20px !important;
      transition: all ease-in-out 0.25s;
      display: ${props => (props.collapsed || props.match ? "block" : "none")};
    }
  }
`;
