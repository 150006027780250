import * as actionTypes from "../actions/actionTypes";

const initialState = {
  enrollment: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.ENROLLMENT_SET_ENROLLMENT:
    return { ...state, enrollment: action.enrollment };
  default:
    return state;
  }
};

export default reducer;

