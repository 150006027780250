import { databaseRef } from "@/configs/firebase";

export const logUserOnline = (userId) => () => {
  try {

    let userOnlineRef = databaseRef.ref(`user_online/${userId}`);
    let connectedRef = databaseRef.ref(".info/connected");

    connectedRef.on("value", function (snap) {
      if (snap.val() === true) {
        // Online , offline
        userOnlineRef.onDisconnect().remove();
        userOnlineRef.set(true);
      }
    });
  } catch (error) {
    console.log(error);
  }
};
