// Libs
import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Button, Skeleton } from "antd";
import { Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
// import _ from "lodash";


// Components
import Form from "@/components/common/form";
import Title from "@/components/common/title";
import CheckBox from "@/components/common/check-box";
import LearningDashboardLayout from "../index";
import * as helpers from "@/helpers";
import CheckListSuccess from "./check-list-success";
import CheckListFail from "./check-list-fail";
import locale from "@/locale";
import NextSession from "@/components/common/next-session";
import useRouter from "@/hook/useRouter";
import { getSessionLog } from "@/stores/actions";
import { selectActivities } from "@/stores/selectors/activity";

function CheckList() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPassQuiz, setIsPassQuiz] = useState(false);

  const { activity } = useSelector(state => state.activity);
  const activities = useSelector(selectActivities);
  const { checkList } = useSelector(state => state.checkList);

  const { sessionLog } = useSelector(state => state.sessionLog);
  // const { quizLogs } = useSelector(state => state.quizLog);
  const dispatch = useDispatch();
  const { sessionId } = useRouter();

  useEffect(() => {
    if (sessionId)
      dispatch(getSessionLog(sessionId));
  }, [dispatch, sessionId]);

  useEffect(() => {
    if (activities.length) {
      let filterActivities = activities.filter(activity => activity.is_completed);
      if (activities.length - 1 <= filterActivities.length) {
        setIsPassQuiz(true);
      }
    }

  }, [dispatch, activities, sessionId]);

  // useEffect(() => {
  //   if (activities.length && quizLogs.length) {
  //     let total = quizLogs.length;
  //     let filterQuizLogs = _.filter(quizLogs, { is_correct: true });

  //     if (helpers.calculatePercentage(total, filterQuizLogs.length) >= 50) {
  //       setIsPassQuiz(true);
  //     } else {
  //       setIsPassQuiz(false);
  //     }
  //   }
  // }, [quizLogs, activities]);

  // useEffect(() => {
  //   if (activities.length && quizLogs.length) {
  //     let total = quizLogs.length;
  //     let filterQuizLogs = _.filter(quizLogs, { is_correct: true });

  //     if (helpers.calculatePercentage(total, filterQuizLogs.length) >= 50) {
  //       setIsPassQuiz(true);
  //     } else {
  //       setIsPassQuiz(false);
  //     }
  //   }
  // }, [quizLogs, activities]);



  const handleSuccess = useCallback(() => {
    setLoading(true);
    setVisible(true);
  }, []);

  const onSubmit = values => {
    const validationRule = {};
    const validateOptions = {
      required: true,
      message: locale("errorRequired2")
    };

    checkList.activities.map((item, index) => {
      validationRule[`name_${index}`] = validateOptions;
    });

    return helpers.createSubmitAction(
      validationRule,
      handleSuccess,
      null // if no need custom values before submit pass null
    )(values);
  };

  let checkListActivities = [];
  if (Object.keys(checkList).length) {
    checkListActivities = checkList.activities;
  }

  let isComplete = false;
  if (sessionLog.is_completed) isComplete = true;

  return (
    <LearningDashboardLayout hasSidebar={true} hasBreadcrumb={true} hideFinish={true}>
      {(() => {
        if (visible && isPassQuiz) {
          return <CheckListSuccess />;
        }
        if (visible && !isPassQuiz) {
          return <CheckListFail />;
        } else {
          return (
            <Fragment>
              {!activity.id && <Skeleton active paragraph={{ rows: 5 }} />}
              {activity.id && (
                <>
                  <Title
                    tag="h4"
                    title={activity.title}
                    className="mb-5 text-center text-shadow text-primary"
                  />
                  <Title
                    tag="h6"
                    className="mb-5 text-center font-weight-normal"
                    title={activity.subtitle}
                  />
                </>
              )}
              {/* {checkListActivities.length <= 0 && (
                <Skeleton active paragraph={{ rows: 5 }} />
              )} */}
              {/* {checkListActivities.length > 0 && ( */}
              <Form
                form="checkListForm"
                className="mb-5 checklist-form"
                onSubmit={onSubmit}
              >
                <Col lg="6" md="8" xs="12" className="mx-auto">
                  {checkListActivities.map((value, index) => {
                    return (
                      <CheckBox
                        key={index}
                        name={`name_${index}`}
                        label={value.title}
                        checked={isComplete}
                        disabled={isComplete}
                      />
                    );
                  })}
                </Col>
                {!isComplete && checkListActivities.length > 0 && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="px-5 d-flex mx-auto font-16"
                    loading={loading}
                  >
                    {locale("submit")}
                  </Button>
                )}

                {isComplete && checkListActivities.length > 0 && (
                  <NextSession />
                )}
              </Form>
              {/* )} */}
            </Fragment>
          );
        }
      })()}
    </LearningDashboardLayout>
  );
}

export default CheckList;
