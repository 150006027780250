import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
// import * as serviceWorker from './serviceWorker';
import "./assets/styles/index.scss";
import App from "./views";
import store from "./stores";

// Sentry.init({
//   dsn: "https://613971be075e44e18879458fb157bab4@o878194.ingest.sentry.io/5829735",
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// serviceWorker.register();
