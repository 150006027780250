import React from "react";
import PropTypes from "prop-types";

function Title(props) {
  const { tag: Tag, className, title, isShowTitle } = props;
  return (
    <Tag className={className} title={isShowTitle ? title : ""}>
      {title}
    </Tag>
  );
}

Title.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  isShowTitle: PropTypes.bool
};

Title.defaultProps = {
  tag: "h5"
};

export default Title;
