import React from "react";
import PropTypes from "prop-types";
import { Container, Col } from "reactstrap";
import * as images from "../../constants/images";
import Title from "@/components/common/title";
import { NavLink } from "react-router-dom";
import * as routes from "../../constants/routes";
import locale from "@/locale";

function VerifyEmail() {

  return (
    <Container fluid className="mail-page-verify">
      <Col className="box-verify">
        <div className="image-verify">
          <img src={images.IMAGE_VERIFY} alt="" />
        </div>
        <div>
          <Title
            tag="span"
            title={locale("titleEmailSend")}
            className="title-mail-verify"
          />
        </div>
        <div className="detail">
          <p>{locale("messageVerifyEmailInfo")}</p>
        </div>
        <NavLink to={routes.SIGN_IN}>
          <div className="btn-back">
            <span>{locale("agree")}</span>
          </div>
        </NavLink>
      </Col>
    </Container>
  );
}

VerifyEmail.propTypes = {
  buttonText: PropTypes.string,
  description: PropTypes.string
};

export default VerifyEmail;
