import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import * as routes from "@/constants/routes";
import locale from "@/locale";
import { Avatar } from "antd";
import { ICON_ACCOUNT, ICON_EMAIL, ICON_SECURITY } from "@/constants/images";

function NavBar() {

  return (
    <Fragment>
      <Nav vertical className="nav-bar">
        <NavItem>
          <NavLink className="nav-link" to={routes.ACCOUNT_SETTING}>
            <Avatar src={ICON_ACCOUNT} className="mr-2" /> {locale("account")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link" to={routes.EMAIL}>
            <Avatar src={ICON_EMAIL} className="mr-2" />{locale("email")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link" to={routes.SECURITY}>
            <Avatar src={ICON_SECURITY} className="mr-2" />{locale("security")}
          </NavLink>
        </NavItem>
      </Nav>
    </Fragment>
  );
}

export default NavBar;
