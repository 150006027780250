import { firestoreRef } from '@/configs/firebase';
import React from 'react';

const userId = 'q99cH7l5hyV1Usr93puwLqHBFsw1';
function Test() {
  const deleteQuizLog = () => {
    console.log(`delete quiz_logs where user_id = ${userId} `);
    const unsubscribe = firestoreRef
      .collection('quiz_logs')
      .where('user_id', '==', userId)
      .onSnapshot((snapshots) => {
        console.log(`quiz_logs: ${snapshots.docs.length}`);
        snapshots.forEach((doc) => {
          doc.ref.delete();
          console.log(`quiz_log ${doc.id} deleted`);
        });
        unsubscribe();
      });
  };

  const deleteQuizResultLog = () => {
    console.log(`delete quiz_result_logs where user_id = ${userId} `);
    const unsubscribe = firestoreRef
      .collection('quiz_result_logs')
      .where('user_id', '==', userId)
      .onSnapshot((snapshots) => {
        console.log(`quiz_result_logs: ${snapshots.docs.length}`);
        snapshots.forEach((doc) => {
          doc.ref.delete();
          // console.log(doc.ref);
          console.log(`quiz_result_logs ${doc.id} deleted`);
        });

        unsubscribe();
      });
  };

  const deleteSessionLog = () => {
    console.log(`delete quiz_session_logs where user_id = ${userId} `);
    const unsubscribe = firestoreRef
      .collection('session_logs')
      .where('user_id', '==', userId)
      .onSnapshot((snapshots) => {
        console.log(`session_logs: ${snapshots.docs.length}`);
        snapshots.forEach((doc) => {
          doc.ref.delete();
          console.log(`session_logs ${doc.id} deleted`);
        });

        unsubscribe();
      });
  };

  return (
    <div>
      <button onClick={deleteQuizLog}>
        Delete quiz_logs by user_id = {userId}
      </button>
      <button onClick={deleteQuizResultLog}>
        Delete quiz_result_logs by user_id = {userId}
      </button>
      <button onClick={deleteSessionLog}>
        Delete session_logs by user_id = {userId}
      </button>
    </div>
  );
}

export default Test;
