import * as actionTypes from "./actionTypes";
import { firestoreRef } from "@/configs/firebase";

export const setSurveys = surveys => ({
  type: actionTypes.SURVEY_SET_SURVEYS,
  surveys
});

const setSurvey = survey => ({
  type: actionTypes.SURVEY_SET_SURVEY,
  survey
});

export const getSurveys = () => dispatch => {
  try {
    return firestoreRef
      .collection("surveys")
      .onSnapshot(snapshots => {
        let data = [];
        snapshots.forEach(doc => {
          data.push({
            ...doc.data()
          });
        });
        dispatch(setSurveys(data));
      });
  } catch (err) {
    console.log(err);
  }
};

export const getSurvey = (id) => (dispatch) => {
  try {
    return firestoreRef
      .collection("surveys")
      .doc(id)
      .onSnapshot(doc => {
        let data = { ...doc.data(), id: doc.id };
        dispatch(setSurvey(data));
      });
  } catch (err) {
    console.log(err);
  }
};
