import * as actionTypes from "./actionTypes";
// import localLocale from "../../locale";

export const localeSet = lang => ({
  type: actionTypes.SET_LOCALE,
  lang
});

export const setLocale = lang => dispatch => {
  localStorage.setItem("lang", lang);
  localStorage.setItem("currentLanguage", lang);
  // localLocale.setLanguage(lang);
  dispatch(localeSet(lang));
  window.location.reload();
};
