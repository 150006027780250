import styled from "styled-components";

export const VideoContent = styled.div`
  border-bottom: 2px solid #0002;
  color:#6c757d;
  margin-bottom: 3rem;
  /* padding: 1rem; */
  
  .title{
    font-weight:bold;
    font-size:1rem;
    margin-top:1rem;
    margin-bottom:1rem;
  }

  .description {
    margin-top:1rem;
    margin-bottom:1rem;
    font-size: 1rem;
  }
`;