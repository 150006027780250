import * as actionTypes from "./actionTypes";
import { firestoreRef } from "@/configs/firebase";

const setSchools = schools => ({
  type: actionTypes.SCHOOL_SET_SCHOOLS,
  schools
});

export const getSchools = () => dispatch => {
  try {
    return firestoreRef
      .collection("schools")
      .onSnapshot(snapshots => {
        let data = [];
        snapshots.forEach(doc => {
          data.push({
            ...doc.data(),
            id: doc.id
          });
        });
        dispatch(setSchools(data));
      });
  } catch (err) {
    console.log(err);
  }
};
