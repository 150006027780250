// Libs
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

// Components
import Title from "@/components/common/title";
import LearningDashboardLayout from "../index";

// Util
import * as constants from "@/constants/constant";
import NextButton from "@/components/common/next-button";
import { Skeleton } from "antd";
import * as typeformEmbed from '@typeform/embed';
import "./styles.scss";

function SurveyPage() {
  const [contentLanguage, setContentLanguage] = useState(
    localStorage.getItem("lang")
  );

  const { activity } = useSelector(state => state.activity);
  const { survey } = useSelector(state => state.survey);

  useEffect(() => {
    const embedElement = document.querySelector('.js-typeform-embed');

    if (embedElement && _.get(survey, 'typeform_en')) {
      typeformEmbed.makeWidget(
        embedElement,
        `${survey[`typeform_${contentLanguage}`]}`,
        {
          opacity: 55,
          buttonText: 'Answer this!',
          hideScrollbars: true,
          // onSubmit: function (event) {
          //   handleTypeForm(event.response_id);
          // },
          // onReady: function () { },
        }
      );
    }
  }, [survey, contentLanguage]);

  const switchContentLanguage = language => {
    setContentLanguage(language);
  };

  return (
    <LearningDashboardLayout hasSidebar={true} hasBreadcrumb={true}>
      {!activity.id && <Skeleton active paragraph={{ rows: 15 }} />}
      {activity.id && (
        <>
          <Title
            tag="h4"
            title={activity.title}
            className="mb-5 text-center text-shadow text-primary"
          />
          <Title
            tag="h6"
            title={activity.subtitle}
            className="mb-5 text-center text-muted"
          />
          <div className="mt-3 text-right">
            <a
              onClick={() => switchContentLanguage(constants.EN)}
              className={`${contentLanguage == constants.EN &&
                "text-primary"} pr-2`}
            >
              EN
            </a>
            |
            <a
              onClick={() => switchContentLanguage(constants.KH)}
              className={`${contentLanguage == constants.KH &&
                "text-primary"} pl-2`}
            >
              ខ្មែរ
            </a>
          </div>
          <div className="mt-3 text-justify">
            <div className="video-wrapper">
              {_.get(survey, 'typeform_en') && (
                <div className="js-typeform-embed type-form-view" />
              )}
            </div>
          </div>
          <NextButton activity={activity} />
        </>
      )}
    </LearningDashboardLayout>
  );
}

SurveyPage.defaultProps = {
  activity: {}
};

export default SurveyPage;
