/* eslint-disable no-unused-vars */

// Libs
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
// Util
import * as constants from '@/constants/constant';

// CKEditor Style
import './style.css';

import FormingTeamLearningDashboard from '@/views/learning-dashboard/forming-team';
import Video from '../video';
import CheckList from '../check-list';
import Canvas from '../canvas';
import Quiz from '../quiz';
import HtmlPage from '../html-page';
import useRouter from '@/hook/useRouter';
import PageNotFound from '@/views/not-found';
import SurveyPage from '../survey';
import { selectSessionLog } from '@/stores/selectors/session';
import { insertSessionLog } from '@/stores/actions';

function LessonDetail() {
  // const [isFirst, setIsFirst] = useState(false);
  const { activity } = useSelector((state) => state.activity);
  const { sessionId } = useRouter();
  const { sessionLogs } = useSelector((state) => state.sessionLog);
  const { teacher } = useSelector((state) => state.teacher);
  const dispatch = useDispatch();
  const selectorSessionLog = useSelector(selectSessionLog);

  // const { session } = useSelector(state => state.session);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   if (_.size(session) && _.size(activity) && !isFirst) {
  //     dispatch(insertActivityLog(session.id, activity.id));
  //     setIsFirst(true);
  //   }
  // }, [dispatch, session, activity, isFirst]);

  // if (sessionLogs.length > 0) {
  //   const found = sessionLogs.find(item => item.session_id === sessionId);
  //   if (!found && !_.size(teacher)) return <PageNotFound />;
  // }

  useEffect(() => {
    if (sessionId) {
      dispatch(insertSessionLog(sessionId));
    }
  }, [dispatch, sessionId]);

  if (activity.type === constants.ACTIVITY_TYPE_VIDEO) {
    return <Video key={activity.id} />;
  } else if (activity.type === constants.ACTIVITY_TYPE_CHECK_LIST) {
    return <CheckList />;
  } else if (activity.type === constants.ACTIVITY_TYPE_FORM_TEAM) {
    return <FormingTeamLearningDashboard />;
  } else if (activity.type === constants.ACTIVITY_TYPE_CANVAS) {
    return <Canvas />;
  } else if (activity.type === constants.ACTIVITY_TYPE_SURVEY) {
    return <SurveyPage />;
  } else if (activity.type === constants.ACTIVITY_TYPE_QUIZ) {
    return <Quiz />;
  } else if (activity.type === constants.ACTIVITY_TYPE_PAGE) {
    return <HtmlPage />;
  }

  return null;
}

export default LessonDetail;
