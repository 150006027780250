/* eslint-disable indent */
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  canvasTeam: {},
  canvasTeams: [],
  loading: false,
  error: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CANVAS_TEAM_SET_CANVAS_TEAMS:
      return {
        ...state,
        canvasTeams: action.canvasTeams,
        loading: true,
        error: ""
      };
    case actionTypes.CANVAS_TEAM_SET_CANVAS_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case actionTypes.CANVAS_TEAM_SET_CANVAS_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      };
    case actionTypes.CANVAS_TEAM_SET_CANVAS_TEAMS_ERROR:
      return {
        ...state,
        loading: false,
        error: action
      };
    case actionTypes.CANVAS_TEAM_SET_CANVAS_TEAM:
      return { ...state, canvasTeam: action.canvasTeam };
    default:
      return state;
  }
};

export default reducer;
