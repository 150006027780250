// Libs
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";

// Components
import Title from "@/components/common/title";
import LearningDashboardLayout from "../index";

// Util
import * as constants from "@/constants/constant";
import NextButton from "@/components/common/next-button";
import { Skeleton, Spin } from "antd";
import useWindowSize from "@/hook/useWindowSize";
import { VideoContent } from "./styled";

function Video() {
  const [contentLanguage, setContentLanguage] = useState(
    localStorage.getItem("lang")
  );

  const size = useWindowSize();
  const { resource } = useSelector(state => state.resource);
  const { activity } = useSelector(state => state.activity);
  const [loading, setLoading] = useState(true);

  const switchContentLanguage = language => {
    setContentLanguage(language);
  };


  // console.log({ resource });

  return (
    <LearningDashboardLayout hasSidebar={true} hasBreadcrumb={true}>
      {!activity.id && <Skeleton active paragraph={{ rows: 15 }} />}
      {activity.id && (
        <>
          <Title
            tag="h4"
            title={activity.title}
            className="mb-2 text-center text-shadow text-primary"
          />
          <Title
            tag="h6"
            title={activity.subtitle}
            className="mb-5 text-center text-muted"
          />

          {!resource.videos && (
            <div className="text-center wrap-player mb-5">
              <Spin tip="Loading..." spinning={loading}>
                <ReactPlayer
                  url={`${constants.VIMEO_BASE_PATH}${resource.file_name}`}
                  width="100%"
                  height={`${size.width / 3}px`}
                  className="player-wrapper"
                  key={`key-${resource.file_name}`}
                  playing
                  controls={true}
                  onReady={() => setLoading(false)}
                />
              </Spin>
            </div>
          )}

          {resource.videos && resource.videos.map((item, i) => {
            return (<VideoContent key={i} className="video-content">
              <div className="title">{item[`${contentLanguage}_title`]}</div>
              <Spin tip="Loading..." spinning={loading}>
                <ReactPlayer
                  url={`${constants.VIMEO_BASE_PATH}${item[`${contentLanguage}_file_name`]}`}
                  width="100%"
                  height={`${size.width / 3}px`}
                  className="player-wrapper"
                  key={`key-${item[`${contentLanguage}_file_name`]}-${i}`}
                  playing={i === 0}
                  controls={true}
                  onReady={() => setLoading(false)}
                />
              </Spin>
              <div className="description"
                dangerouslySetInnerHTML={{
                  __html: item[`${contentLanguage}_description`]
                }}
              />
            </VideoContent>);
          })}

          <div className="mt-3 text-right">
            <a
              onClick={() => switchContentLanguage(constants.EN)}
              className={`${contentLanguage == constants.EN &&
                "text-primary"} pr-2`}
            >
              EN
            </a>
            |
            <a
              onClick={() => switchContentLanguage(constants.KH)}
              className={`${contentLanguage == constants.KH &&
                "text-primary"} pl-2`}
            >
              ខ្មែរ
            </a>
          </div>
          <div className="mt-3 text-justify">
            <div
              dangerouslySetInnerHTML={{
                __html: activity[`${contentLanguage}_description`]
              }}
            />
          </div>

          <NextButton activity={activity} />
        </>
      )}
    </LearningDashboardLayout>
  );
}

Video.defaultProps = {
  activity: {}
};

export default Video;
