/*eslint-disable indent */
import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const initialState = {
  sessions: [],
  session: {}
};

const findById = (state, action) => {

  const found = _.find(state.sessions, { id: action.id });
  return { ...state, session: found || {} };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_SET_SESSIONS:
      return { ...state, sessions: action.sessions };
    case actionTypes.SESSION_SET_SESSION:
      return { ...state, session: action.session };
    case actionTypes.SESSION_SET_SESSION_BY_ID:
      return findById(state, action);
    default:
      return state;
  }
};

export default reducer;

