import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { Checkbox, Form } from "antd";

import classnames from "classnames";
import { Label, FormText, FormFeedback } from "reactstrap";

const FormItem = Form.Item;

function RenderCheckBoxField(props) {
  const {
    tip,
    input: { value, onChange, ...inputs },
    label,
    meta: { error, touched },
    required,
    disabled,
    //className,
    checked,
    name
  } = props;
  const checkBoxValue = Boolean(value);
  const validateStatus = classnames(touched && error ? "error" : "success");

  return (
    <FormItem
      //label={label}
      validateStatus={validateStatus}
      help={error}
      required={required}
    >
      <Label>{required && <span className="text-danger mr-2"> * </span>}</Label>
      <Checkbox
        {...inputs}
        disabled={disabled}
        onChange={onChange}
        checked={checkBoxValue || checked}
        name={name}
      >
        {label}
      </Checkbox>
      {tip && <FormText color="muted">{tip}</FormText>}
      {error && <FormFeedback>{error}</FormFeedback>}
    </FormItem>
  );
}

RenderCheckBoxField.propTypes = {
  tip: PropTypes.string,
  meta: PropTypes.object,
  label: PropTypes.string,
  input: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool
};

function CheckBox(props) {
  return <Field component={RenderCheckBoxField} {...props} />;
}

export default CheckBox;
