import * as actionTypes from "../actions/actionTypes";

const initialState = {
  schools: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.SCHOOL_SET_SCHOOLS:
    return { ...state, schools: action.schools };
  default:
    return state;
  }
};

export default reducer;

