import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";
import * as routes from "@/constants/routes";
import locale from "@/locale";
import RenderLocale from "@/components/common/RenderLocale/RenderLocale";

class PageNotFound extends Component {
  render() {
    const { status } = this.props;
    const Status = () => {
      return (
        <h1 className="mb-0 text-center text-primary display-2 font-weight-bold">
          {status}
        </h1>
      );
    };

    const Text = () => {
      return (
        <p className="text-center font-weight-light mb-0 lead">
          {locale("messageNotExist")}
        </p>
      );
    };

    const Description = () => {
      return (
        <p className="text-center font-weight-light lead">
          <RenderLocale
            localeString={locale("messageReturnHome")}
            element={
              <span className="ml-1 font-weight-bold">
                <Link to={routes.HOME}>{locale("home")}</Link>
              </span>
            }
          />
        </p>
      );
    };

    const { fullHeight } = this.props;
    return (
      <Fragment>
        {fullHeight ? (
          <Container fluid>
            <Row className="h-100 p-3">
              <Col className="m-auto">
                <Status />
                <Text />
                <Description />
              </Col>
            </Row>
          </Container>
        ) : (<Container>
          <Row className="p-3">
            <Col lg="6" md="6" sm="12" xs="12" className="m-auto">
              <Status />
              <Text />
              <Description />
            </Col>
          </Row>
        </Container>)}
      </Fragment>
    );
  }
}

PageNotFound.propTypes = {
  name: PropTypes.string,
  status: PropTypes.number,
  fullHeight: PropTypes.bool,
  lang: PropTypes.string
};

PageNotFound.defaultProps = {
  fullHeight: true,
  status: 404
};

export default PageNotFound;
