import * as actionTypes from "../actions/actionTypes";

const initialState = {
  teamInvitations: [],
  teamInvitation: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.TEAM_INVITATION_SET_TEAM_INVITATIONS:
    return { ...state, teamInvitations: action.teamInvitations };
  case actionTypes.TEAM_INVITATION_SET_TEAM_INVITATION:
    return { ...state, teamInvitation: action.teamInvitation };
  default:
    return state;
  }
};

export default reducer;

