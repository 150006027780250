// Libs
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Components
import Layout from '@/components/layout';
// import NotFound from "@/views/not-found";
import FormingTeam from '@/views/forming-team';
import EnterClassCode from '@/views/enter-class-code';
import Lesson from '@/views/learning-dashboard/lesson';
import Video from '@/views/learning-dashboard/video';
import Quiz from '@/views/learning-dashboard/quiz';
import CheckList from '@/views/learning-dashboard/check-list';
import FormingTeamLearningDashboard from '@/views/learning-dashboard/forming-team';
import AccountSetting from '@/views/account-setting';
import Email from '@/views/account-setting/email';
import Security from '@/views/account-setting/security';
import * as routes from '@/constants/routes';
import HtmlPage from '@/views/learning-dashboard/html-page';
import Canvas from '@/views/learning-dashboard/canvas';
import Invitation from '@/views/learning-dashboard/forming-team/invitation';
import Canvases from '@/views/canvases';
import AboutUs from '@/views/about-us';
import LessonDetail from '@/views/learning-dashboard/lesson-detail';
import VerifyEmailForm from '@/views/create-account/verify-email-form';
import Test from '@/views/test';
class Private extends Component {
  render() {
    return (
      <Layout {...this.props}>
        <Switch>
          <Redirect exact from='/' to={routes.LESSON} />
          {/* <Redirect
            exact
            from={routes.LESSON_DETAIL}
            to={routes.LESSON_DETAIL_INTRODUCTION}
          /> */}
          <Route path={routes.LESSON_DETAIL} component={LessonDetail} />
          <Route path={routes.LESSON_DETAIL_QUIZ} component={Quiz} />
          <Route path={routes.LESSON_DETAIL_CHECK_LIST} component={CheckList} />
          <Route path={routes.LESSON} component={Lesson} />
          <Route path={routes.LESSON_DETAIL_VIDEO} component={Video} />
          <Route path={routes.ENTER_CLASS_CODE} component={EnterClassCode} />
          <Route path={routes.FORM_TEAM} component={FormingTeam} />
          <Route
            path={routes.LESSON_DETAIL_FORM_TEAM}
            component={FormingTeamLearningDashboard}
          />
          <Route path={routes.EMAIL_VERIFY} component={VerifyEmailForm} />
          <Route
            path={routes.LESSON_DETAIL_FORM_TEAM_INVITATION}
            component={Invitation}
          />
          <Route path={routes.ACCOUNT_SETTING} component={AccountSetting} />
          <Route path={routes.EMAIL} component={Email} />
          <Route path={routes.SECURITY} component={Security} />
          <Route path={routes.LESSON_DETAIL_PAGE} component={HtmlPage} />
          <Route path={routes.LESSON_DETAIL_CANVAS} component={Canvas} />
          <Route exact path={routes.ABOUT_US} component={AboutUs} />

          <Route path='/canvases' component={Canvases} />
          {/* <Route path="/*" component={NotFound} /> */}
          <Route exact path={'/test'} component={Test} />
          <Redirect from='/*' to={routes.LESSON} />
        </Switch>
      </Layout>
    );
  }
}

export default Private;
