// Libs
import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";


import Title from "@/components/common/title";
import { CHECK_LIST_SUCCESS } from "@/constants/images";
import NextSession from "@/components/common/next-session";
import locale from "@/locale";
import { updateSessionLog } from "@/stores/actions";
import useRouter from "@/hook/useRouter";
import { updateActivityLog } from "@/stores/actions/activityLog";

function CheckListSuccess() {
  const { session } = useSelector(state => state.session);
  const { sessionLog } = useSelector(state => state.sessionLog);
  const { sessionId, activityId } = useRouter();

  const dispatch = useDispatch();

  useEffect(() => {
    if (_.size(sessionLog)) {
      dispatch(updateSessionLog(sessionLog.id, { is_completed: true }));
    }
  }, [dispatch, sessionLog]);

  useEffect(() => {
    if (sessionId && activityId) {
      dispatch(updateActivityLog(sessionId, activityId, { is_completed: true }));
    }
  }, [dispatch, sessionId, activityId]);

  return (
    <Fragment>
      <div className="text-center">
        <img src={CHECK_LIST_SUCCESS} />
      </div>
      <br />
      <Title
        tag="h3"
        title={locale("messageSuccessChecklist").replace("#####", session.rank)}
        className="text-center text-shadow text-info"
      />
      <br />
      <NextSession />
    </Fragment>
  );
}

export default CheckListSuccess;
