import * as actionTypes from "../actions/actionTypes";

const initialState = {
  quizLogs: [],
  quizLog: {},
  isPassQuiz: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.QUIZ_lOG_SET_QUIZ_LOGS:
    return { ...state, quizLogs: action.quizLogs };
  case actionTypes.QUIZ_lOG_SET_QUIZ_LOG:
    return { ...state, quizLog: action.quizLog };
  case actionTypes.QUIZ_lOG_SET_IS_PASS_QUIZ:
    return { ...state, isPassQuiz: action.isPassQuiz };
  default:
    return state;
  }
};

export default reducer;

