import React, { Fragment, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import PropTypes from "prop-types";

import NavBar from "./navbar";
// import CommonIndex from "./common-index";
import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import * as routes from "../../constants/routes";
import locale from "@/locale";
import { Avatar, Icon } from "antd";
import { ICON_ACCOUNT, ICON_EMAIL, ICON_SECURITY } from "@/constants/images";

function Layout(props) {
  const [show, setShow] = useState(false);

  const showLeftMenu = () => {
    setShow(true);
  };

  const showPopUp = () => {
    setShow(false);
  };

  let openMenu = show ? "left-menu open" : "left-menu";

  const { children } = props;

  return (
    <Fragment>
      <Container fluid className="main-width">
        <Row>
          <Col xl="3" lg="3" md="3" className="left-profile">
            <Col className="profile-left">
              <NavBar />
            </Col>
          </Col>
          <Col xl="1" lg="1" md="1" className="menu-nabar">
            <div className="menu-hamburger" onClick={showLeftMenu}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
          </Col>

          <Nav vertical className={openMenu}>
            <NavItem>
              <NavLink className="nav-link" to={routes.ACCOUNT_SETTING}>
                <Avatar src={ICON_ACCOUNT} className="mr-2" /> {locale("account")} <Icon type="right" className="pull-right mt-2" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to={routes.EMAIL}>
                <Avatar src={ICON_EMAIL} className="mr-2" /> {locale("email")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to={routes.SECURITY}>
                <Avatar src={ICON_SECURITY} className="mr-2" />{locale("security")}
              </NavLink>
            </NavItem>
          </Nav>

          {show && <div className="pop-up-left-menu" onClick={showPopUp}></div>}

          <Col xl="9" lg="9" md="11" className="right-profile">
            {children}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.node
};

export default Layout;
