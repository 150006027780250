
import { createSelector } from "reselect";
import _ from "lodash";

const sessions = state => state.session.sessions;
// const session = state => state.session.session;
const sessionLogs = state => state.sessionLog.sessionLogs;


export const selectSessions = createSelector(
  sessions,
  sessionLogs,
  (sessions, sessionLogs) => {

    return _.map(sessions, (session) => {
      let sessionLog = _.find(sessionLogs, { session_id: session.id });
      if (sessionLog)
        session.is_completed = sessionLog.is_completed;
      return session;
    });
  }
);

export const selectSessionLog = createSelector(
  selectSessions,
  (sessions) => {

    let index = _.findLastIndex(sessions, { is_completed: false });

    if (index !== -1) {
      return null;
    }

    let indexTrue = _.findLastIndex(sessions, { is_completed: true });
    if (sessions[indexTrue + 1]) {
      let session = sessions[indexTrue + 1];
      return session;
    }

    return null;
  }
);
