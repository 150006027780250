// Libs
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Media from "react-media";
import useReactRouter from "use-react-router";
import queryString from "query-string";
import { Menu, Icon, Skeleton, Row, Col, Button } from "antd";

import { WrapperSidebar, WrapperDrawer } from "@/components/style-component";
// import * as images from "@/constants/images";
import * as routes from "@/constants/routes";
import * as actions from "@/stores/actions";
import locale from "@/locale";
import useRouter from "@/hook/useRouter";
import { ACTIVITY_TYPE_FORM_TEAM, ACTIVITY_TYPE_CHECK_LIST, ACTIVITY_TYPE_PAGE, ACTIVITY_TYPE_QUIZ, ACTIVITY_TYPE_VIDEO, ACTIVITY_TYPE_CANVAS, ACTIVITY_TYPE_SURVEY } from "@/constants/constant";

function Sidebar({ collapsed, activities, session, onCollapse }) {
  const { activity } = useSelector(state => state.activity);
  const dispatch = useDispatch();

  const { history } = useReactRouter();
  const { activityId } = queryString.parse(history.location.search);
  const { sessionId } = useRouter();

  const onCollapseLocal = useCallback(() => {
    if (onCollapse) {
      onCollapse();
    }
  }, [onCollapse]);

  const handleMenuClick = useCallback(
    (paramActivityId, routeUrl) => {
      if (paramActivityId != activityId) {
        dispatch(actions.setActivityById(paramActivityId));
        history.push(routeUrl);
      }
    },
    [activityId, dispatch, history]
  );

  const switchColor = (activity) => {
    if (activity.is_completed) {
      return { color: "green", fontSize: "18px" };
    }

    return { color: "white", fontSize: "18px" };
  };

  const switchIcon = useCallback((activity) => {

    let style = switchColor(activity);
    if (activity.type === ACTIVITY_TYPE_QUIZ) {
      return <Icon type="ordered-list" style={style} />;
    } else if (activity.type === ACTIVITY_TYPE_VIDEO) {
      return <Icon type="video-camera" style={style} />;
    } else if (activity.type === ACTIVITY_TYPE_FORM_TEAM) {
      return <Icon type="team" style={style} />;
    } else if (activity.type === ACTIVITY_TYPE_CHECK_LIST) {
      return <Icon type="check-square" style={style} />;
    } else if (activity.type === ACTIVITY_TYPE_SURVEY) {
      return <Icon type="question-circle" style={style} />;
    } else if (activity.type === ACTIVITY_TYPE_PAGE) {
      return <Icon type="html5" style={style} />;
    } else if (activity.type === ACTIVITY_TYPE_CANVAS) {
      return <Icon type="file-sync" style={style} />;
    }
  }, []);

  const renderMenu = useCallback(
    (lessonNumber, activities) => {
      return (
        <Menu selectedKeys={[activityId]}>
          {activities.map((value, key) => {
            let routePath = routes.LESSON_DETAIL;
            routePath = `${routePath}?sessionId=${sessionId}&activityId=${value.id}`;
            return (
              <Menu.Item key={value.id}>
                <span
                  to={routePath}
                  style={{ color: "inherit" }}
                  className="d-flex align-items-center"
                  onClick={() => handleMenuClick(value.id, routePath)}
                  id={value.id}
                >
                  {switchIcon(value)} {lessonNumber}.{key + 1}
                  <span className="pl-2">{value.title}</span>
                </span>
              </Menu.Item>
            );
          })}
        </Menu>
      );
    },
    [handleMenuClick, sessionId, activityId, switchIcon]
  );

  const lessonNumber = session.session;
  return (
    <Media query={{ maxWidth: 768 }}>
      {matches =>
        matches ? (
          <WrapperDrawer
            title={activity.name}
            width={300}
            closable={true}
            placement="left"
            visible={!collapsed ? false : true}
            onClose={onCollapseLocal}
          >
            {activities.length <= 0 && (
              <div style={{ padding: "20px" }}>
                <Skeleton active paragraph={{ rows: 10 }} />
              </div>
            )}
            {activities.length > 0 && renderMenu(lessonNumber, activities, session)}
          </WrapperDrawer>
        ) : (<WrapperSidebar
          width={300}
          collapsedWidth={0}
          collapsed={collapsed ? "collapsed" : ""}
          onCollapse={onCollapseLocal}
        >
          <div className="session-title-menu">
            <Row>
              {/* <Col span={4}>
                <Link to={routes.HOME} className="back-arrow-link">
                  <img src={images.BACK_ARROW} alt="back icon" />
                </Link>
              </Col> */}
              <Col span={16}>
                <div style={{ marginTop: 8 }} className="pl-4">
                  {locale("session")} {session.session}
                </div>
              </Col>
              <Col span={8}>
                <div className="text-align-right">
                  <Button onClick={onCollapseLocal} className="btn-toggle-menu">
                    <Icon type="menu-fold" />
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          {activities.length <= 0 && (
            <div style={{ padding: "20px" }}>
              <Skeleton active paragraph={{ rows: 10 }} />
            </div>
          )}
          {activities.length > 0 && renderMenu(lessonNumber, activities)}
        </WrapperSidebar>)}
    </Media>
  );
}

Sidebar.propTypes = {
  onCollapse: PropTypes.func,
  collapsed: PropTypes.any,
  match: PropTypes.object,
  location: PropTypes.object,
  activities: PropTypes.array,
  session: PropTypes.object,
  resource: PropTypes.object
};

Sidebar.defaultProps = {
  resource: {}
};

export default withRouter(connect(null)(Sidebar));
