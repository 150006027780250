// Libs
import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { Container, Row, Col, Label } from "reactstrap";
import { useDispatch } from "react-redux";

// Components
import Title from "@/components/common/title";
import InputText from "@/components/common/input";
import FormWrapper from "@/components/common/form";
import * as helpers from "@/helpers";

// Util
import { history } from "@/shared/utils/history";
import { WrapperBodyLeft } from "@/components/style-component";
import * as routes from "@/constants/routes";
import * as actions from "@/stores/actions";
import locale from "@/locale";

// Loading
// import Loading from "@/components/common/loading/loading";
// import Btnloading from "@/components/common/loading/loading-btn";

function SignIn() {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loadings, setLoadings] = useState(false);
  // const { user } = useSelector(state => state.user);

  // useEffect(() => {
  //   if (_.size(user)) {

  //   }
  // }, [user]);

  const handleLogin = useCallback(
    values => {
      const { email, password } = values;
      setLoadings(true);

      dispatch(actions.signInWithEmailAndPassword(email, password))
        .then(user => {
          setError(false);
          //Check if email verify
          if (process.env.REACT_APP_DEPLOY === "default") {
            if (!user.emailVerified) {
              history.push(routes.EMAIL_VERIFY);
              return false;
            }
          }

          localStorage.setItem("Authorize", true);
          localStorage.setItem("userId", user.uid);

          history.push(routes.LESSON);

          // dispatch(actions.getUser(user.uid)).then(user => {
          //   if (user.is_join_class) {
          //     history.push(routes.LESSON);
          //   } else {
          //     history.push(routes.ENTER_CLASS_CODE);
          //   }
          // });
        })
        .catch(() => {
          setError(true);
          setLoadings(false);
        });
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    values => {
      setLoadings(false);
      const validationRule = {
        email: {
          required: true,
          type: "email"
        },
        password: {
          required: true,
          type: "password"
        }
      };
      return helpers.createSubmitAction(validationRule, handleLogin)(values);
    },
    [handleLogin]
  );

  return (
    <Container fluid>
      <Row className="h-100">
        <Col md="6" xs="12">
          <WrapperBodyLeft />
        </Col>
        <Col
          md="6"
          xs="12"
          className="h-100 text-center bg-gradient-primary-top-bottom-right"
        >
          <Row className="h-100">
            <Col lg="8" md="10" xs="12" className="m-auto">
              <Title title={locale("logIn")} className="text-white mb-4" />
              {error && (
                <div className="p-3 mb-3 border-2 border-danger bg-danger text-center text-muted rounded">
                  <Title
                    className="text-muted"
                    title={locale("wrongCredentials")}
                  />
                  <span>
                    {locale("errorInvalidEmailOrPassword")}
                    <Link
                      to={routes.FORGET_PASSWORD}
                      className="pl-1 font-weight-bold"
                    >
                      {locale("askForgetPassword")}
                    </Link>
                  </span>
                </div>
              )}
              <FormWrapper form="singIn" onSubmit={onSubmit}>
                <InputText name="email" placeholder={locale("email")} />
                <InputText
                  name="password"
                  type="Password"
                  placeholder={locale("password")}
                />
                <Label className="d-flex align-items-center justify-content-center text-white mt-3">
                  <Link to={routes.FORGET_PASSWORD}>
                    <span className="text-warning px-1">
                      {locale("askForgetPassword")}
                    </span>
                  </Link>
                </Label>
                <Label className="d-flex align-items-center justify-content-center text-white mt-3">
                  {locale("alreadyHaveAccount")}
                  <Link to={routes.SIGN_UP}>
                    <span className="text-warning px-1">{locale("signUp")}</span>
                  </Link>
                  {locale("now")}
                </Label>
                <Button
                  htmlType="submit"
                  loading={loadings}
                  onClick={setLoadings}
                  className="btn-submit-form"
                >
                  {locale("submit")}
                </Button>
              </FormWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default SignIn;
