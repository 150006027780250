import * as actionTypes from "./actionTypes";
import { firestoreRef } from "@/configs/firebase";

export const setCheckLists = checkLists => ({
  type: actionTypes.CHECK_LIST_SET_CHECK_LISTS,
  checkLists
});

export const setCheckList = checkList => ({
  type: actionTypes.CHECK_LIST_SET_CHECK_LIST,
  checkList
});

export const getCheckLists = () => dispatch => {
  try {
    return firestoreRef
      .collection("check_lists")
      .onSnapshot(snapshots => {
        let data = [];
        snapshots.forEach(doc => {
          data.push({
            ...doc.data(),
            id: doc.id
          });
        });
        dispatch(setCheckLists(data));
      });
  } catch (err) {
    console.log(err);
  }
};

export const getCheckList = (check_list_id) => (dispatch) => {
  try {
    return firestoreRef
      .collection("check_lists")
      .doc(check_list_id)
      .onSnapshot(doc => {
        let data = doc.data() || {};
        dispatch(setCheckList(data));
      });
  } catch (err) {
    console.log(err);
  }
};
