//import firebase from "firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";

const settings = { timestampsInSnapshots: true };

let firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY_DEV,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN_DEV,
  databaseURL: process.env.REACT_APP_DATABASE_URL_DEV,
  projectId: process.env.REACT_APP_PROJECT_ID_DEV,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET_DEV,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
  appId: process.env.REACT_APP_APP_ID_DEV
};

if (process.env.REACT_APP_DEPLOY === "default") {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };
}

firebase.initializeApp(firebaseConfig);
const firestoreRef = firebase.firestore();
const databaseRef = firebase.database();
const storageRef = firebase.storage();
const authRef = firebase.auth();

const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();

//const FIRSTORE_TIMESTAMP = firebase.firestore.Timestamp.fromDate(new Date());
const FIRSTORE_TIMESTAMP = firebase.firestore.FieldValue.serverTimestamp();
// admin.firestore.FieldValue.serverTimestamp();
const DATABASE_TIMESTAMP = firebase.database.ServerValue.TIMESTAMP;
const FIRSTORE_TIME_MILLI = firebase.firestore.Timestamp.now().toMillis();

const generateDocKey = ref => {
  const _ref = firestoreRef.collection(ref).doc();
  const newKey = _ref.id;
  return newKey;
};

const firebaseApp = firebase;

export {
  firebaseApp,
  firestoreRef,
  authRef,
  storageRef,
  googleProvider,
  facebookProvider,
  databaseRef,
  firebase,
  FIRSTORE_TIMESTAMP,
  DATABASE_TIMESTAMP,
  settings,
  FIRSTORE_TIME_MILLI,
  generateDocKey
};
