import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, DatePicker as AntdDatePicker } from "antd";
import { Field } from "redux-form";
import classnames from "classnames";
import moment from "moment";

const FormItem = Form.Item;

class DatePickerField extends Component {
  onChange = value => {
    const {
      input: { onChange },
      handleChange,
      defaultDisplayDateFormat
    } = this.props;
    if (value != null) {
      const result = value.format(defaultDisplayDateFormat);
      onChange(result);
      if (handleChange) {
        handleChange(result, value);
      }
    } else {
      onChange(null);
      if (handleChange) {
        handleChange();
      }
    }
  };

  disabledDate = currentDate => {
    const {
      minimumDate,
      showFutureDates,
      defaultDisplayDateFormat
    } = this.props;
    if (minimumDate) {
      const minimumDateMoment = moment(
        minimumDate,
        defaultDisplayDateFormat
      ).add(1, "day");
      if (currentDate) {
        return currentDate.isBefore(minimumDateMoment);
      }
    }
    if (!showFutureDates) {
      return moment() < moment(currentDate);
    }
  };

  renderValue = () => {
    const {
      input: { value },
      defaultDisplayDateFormat,
      displayCurrentDate
    } = this.props;
    if (value) {
      return moment(value, defaultDisplayDateFormat);
    } else {
      if (displayCurrentDate) {
        const currentDate = moment().format(defaultDisplayDateFormat);
        return moment(currentDate, defaultDisplayDateFormat);
      }
    }
  };

  render() {
    const {
      label,
      placeholder,
      required,
      disabled,
      defaultDisplayDateFormat,
      minimumDate,
      meta: { touched, error },
      ...props
    } = this.props;
    const validateStatus = classnames(touched && error ? "error" : "success");
    return (
      <FormItem
        label={label}
        validateStatus={validateStatus}
        help={error}
        required={required}
      >
        <AntdDatePicker
          className="w-100"
          format={defaultDisplayDateFormat}
          placeholder={placeholder}
          allowClear={!required}
          onChange={this.onChange}
          defaultPickerValue={
            minimumDate &&
            moment(minimumDate, defaultDisplayDateFormat).add(1, "day")
          }
          disabledDate={this.disabledDate}
          value={this.renderValue()}
          defaultValue={this.renderValue()}
          disabled={disabled}
          {...props}
        />
      </FormItem>
    );
  }
}

DatePickerField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.any,
  handleChange: PropTypes.func,
  meta: PropTypes.any,
  disabled: PropTypes.bool,
  onPressEnter: PropTypes.func,
  defaultDisplayDateFormat: PropTypes.string,
  displayCurrentDate: PropTypes.bool,
  minimumDate: PropTypes.any,
  showFutureDates: PropTypes.bool
};

DatePickerField.defaultProps = {
  required: false,
  defaultDisplayDateFormat: "DD/MM/YYYY",
  showFutureDates: true
};

class DatePicker extends Component {
  render() {
    const { ...props } = this.props;
    return <Field component={DatePickerField} {...props} />;
  }
}

export default DatePicker;
