import * as actionTypes from "./actionTypes";
import { firestoreRef } from "@/configs/firebase";
import { setDefault } from "./app";

export const setCanvases = canvases => ({
  type: actionTypes.CANVAS_SET_CANVASES,
  canvases
});

export const setCanvas = canvas => ({
  type: actionTypes.CANVAS_SET_CANVAS,
  canvas
});

export const findCanvas = canvasId => ({
  type: actionTypes.CANVAS_SET_FIND_CANVAS,
  canvasId
});

export const generateCanvases = () => (dispatch, getState) => {
  try {
    dispatch(setDefault());
    const { canvases } = getState().canvas;
    const canvasObj = firestoreRef.collection("canvases");
    canvases.forEach(itemCanvas => {
      canvasObj
        .doc(itemCanvas.id)
        .set(itemCanvas)
        .then(() => {
          itemCanvas.blocks.forEach(itemBlock => {
            canvasObj
              .doc(itemCanvas.id)
              .collection("blocks")
              .doc(itemBlock.id)
              .set(itemBlock);
          });
        });
    });
  } catch (err) {
    console.log(err);
  }
};
