// Libs
import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { EN } from "@/constants/constant";
import { html_en, html_kh } from "./content";
import {
  IMPACT_HUB,
  CBRD,
  EDEMY,
  RULE_CAMBODIA,
  MINISTRY_OF_EDUCATION,
  MPTC,
  NATIONAL_UNIVERSITY_OF_CAMBODIA,
  NIPTICT,
  SMART_AXIATA,
  UP
} from "@/constants/images";

function AboutUs() {
  const { lang } = useSelector(state => state.lang);

  return (
    <Container fluid>
      <Row className="h-100">
        <Col md="9" xs="12" className="m-auto text-center">
          <Fragment className="mt-5">
            <div
              className="pt-5 pb-5"
              dangerouslySetInnerHTML={{
                __html: lang === EN ? html_en : html_kh
              }}
            />
            <div className="about-us-support">
              <div>
                <p>An initiative by:</p>
                <div>
                  <img className="impact-hub" src={IMPACT_HUB} />
                  <img className="smart" src={SMART_AXIATA} />
                </div>
              </div>
              <div>
                <p>Supported by:</p>
                <div>
                  <img className="moe" src={MINISTRY_OF_EDUCATION} />
                  <img className="mptc" src={MPTC} />
                  <img className="cbrd" src={CBRD} />
                </div>
              </div>
              <div>
                <p className="mb-2">
                  The current universities using the platform:
                </p>
                <div>
                  <img className="niptict" src={NIPTICT} />
                  <img className="nuoc" src={NATIONAL_UNIVERSITY_OF_CAMBODIA} />
                  <img className="rc" src={RULE_CAMBODIA} />
                  <img className="up" src={UP} />
                </div>
              </div>
              <div>
                <p>Learning Platform Partner:</p>
                <div>
                  <img className="edemy" src={EDEMY} />
                </div>
              </div>
            </div>
          </Fragment>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutUs;
