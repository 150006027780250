

import styled from "styled-components";
import { Form } from "antd";

const FormItem = Form.Item;


export const WrapperFormItem = styled(FormItem)`
  .ant-radio-group {
    margin-left: ${props => props.marginLeft} !important;
    text-align: left;
  }

  .ant-form-explain {
    margin-left: ${props => props.marginLeft} !important;
  }

  .ant-form-item-label {
    white-space: unset;
    line-height: normal;
    text-align: justify;
  }
`;

export const WrapperLabelAnswer = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 0;
  /* height: 30px; */
  /* line-height: 30px; */

  i svg {
    display: flex;
    font-size: 16px;
    margin-right: 4px;
    position: relative;
  }
`;