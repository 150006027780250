import * as actionTypes from "./actionTypes";
import {
  firestoreRef,
  FIRSTORE_TIMESTAMP,
  generateDocKey,
  FIRSTORE_TIME_MILLI
} from "@/configs/firebase";
import { calculatePercentage } from "@/helpers";

export const setQuizLogs = quizLogs => ({
  type: actionTypes.QUIZ_lOG_SET_QUIZ_LOGS,
  quizLogs
});

export const setQuizLog = quizLog => ({
  type: actionTypes.QUIZ_lOG_SET_QUIZ_LOG,
  quizLog
});

export const setIsPassQuiz = isPassQuiz => ({
  type: actionTypes.QUIZ_lOG_SET_IS_PASS_QUIZ,
  isPassQuiz
});

export const getQuizLogs = ({ sessionId, activityId }) => (dispatch, getState) => {
  try {
    const { userId } = getState().auth;
    return firestoreRef
      .collection("quiz_logs")
      .where("user_id", "==", userId)
      .where("session_id", "==", sessionId)
      .where("activity_id", "==", activityId)
      .onSnapshot(snapshots => {
        let data = [];
        snapshots.forEach(doc => {
          data.push({
            ...doc.data(),
            id: doc.id
          });
        });

        dispatch(setQuizLogs(data));
      });
  } catch (err) {
    console.log(err);
  }
};

export const getQuizLog = quizId => dispatch => {
  try {
    return firestoreRef
      .collection("quiz_logs")
      .doc(quizId)
      .onSnapshot(doc => {
        let data = doc.data() || {};
        dispatch(setQuizLog(data));
      });
  } catch (err) {
    console.log(err);
  }
};

export const setQuizLogsData = ({ activity, quizzes, sessionId }) => (dispatch, getState) => {
  try {
    let { userId } = getState().auth;

    let batch = firestoreRef.batch();
    firestoreRef
      .collection("quiz_logs")
      .where("user_id", "==", userId)
      .where("session_id", "==", sessionId)
      .where("activity_id", "==", activity.id)
      .get()
      .then(snapshots => {
        if (snapshots.size > 0) {
          return false;
        }

        quizzes.map(quiz => {
          let data = {
            activity_id: activity.id,
            correct_answer_id: quiz.correct_answer_id,
            session_id: sessionId,
            is_correct: quiz.is_correct,
            quiz_parent_id: quiz.parent_id,
            quiz_id: quiz.id,
            user_answer_id: quiz.answer_id,
            user_id: userId,
            created: FIRSTORE_TIMESTAMP
          };

          batch.set(firestoreRef.collection("quiz_logs").doc(), data);
        });

        return batch.commit();
      });
  } catch (err) {
    console.log(err);
  }
};

export const setQuizResultLogsData = ({ activity, sessionId, quizzes }) => (dispatch, getState) => {
  try {
    let { userId } = getState().auth;
    let { user } = getState().user;

    firestoreRef
      .collection("quiz_result_logs")
      .where("user_id", "==", userId)
      .where("session_id", "==", sessionId)
      .where("activity_id", "==", activity.id)
      .get()
      .then(snapshots => {
        let myDoc = {};
        snapshots.forEach(snapshot => {
          myDoc = { ...snapshot.data(), id: snapshot.id };
        });

        try {
          let total_do_quiz = 1;
          let data = {};
          let result = {};
          let docId = generateDocKey("quiz_result_logs");
          if (myDoc) {
            if (myDoc.user_id) {
              total_do_quiz = myDoc.total_do_quiz + 1;
              data = myDoc.data;
              result = myDoc.result;
              docId = myDoc.id;
            }
          }

          let total_right = 0;
          let parent_id = "";
          let tmp = {};
          quizzes.map(quiz => {
            total_right += quiz.is_correct ? 1 : 0;
            parent_id = quiz.parent_id;
            tmp[quiz.id] = {
              activity_id: activity.id,
              correct_answer_id: quiz.correct_answer_id,
              session_id: sessionId,
              is_correct: quiz.is_correct,
              quiz_parent_id: quiz.parent_id,
              quiz_id: quiz.id,
              user_answer_id: quiz.answer_id,
              user_id: userId,
              created: FIRSTORE_TIME_MILLI,
              class_id: user.class_id
            };
          });

          result[total_do_quiz.toString()] = {
            total_quiz: quizzes.length,
            total_right: total_right,
            percentage: calculatePercentage(quizzes.length, total_right)
          };

          data[total_do_quiz.toString()] = tmp;

          let dataLog = {
            created: FIRSTORE_TIMESTAMP,
            activity_id: activity.id,
            parent_id: parent_id,
            session_id: sessionId,
            user_id: userId,
            total_do_quiz: total_do_quiz,
            data: data,
            result: result
          };

          if (myDoc.user_id) {
            firestoreRef
              .collection("quiz_result_logs")
              .doc(docId)
              .update(dataLog);
          } else {
            firestoreRef
              .collection("quiz_result_logs")
              .doc(docId)
              .set(dataLog);
          }
        } catch (err) {
          //console.log(err);
        }
      });
  } catch (err) {
    console.log(err);
  }
};
