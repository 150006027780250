/*eslint-disable */

import React, { Fragment, useState, useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploader from "react-firebase-file-uploader";
import { message, Icon, Button } from "antd";
import _ from "lodash";
import { Label } from "reactstrap";

import Title from "@/components/common/title";
import { storageRef, FIRSTORE_TIME_MILLI } from "@/configs/firebase";
import InputText from "@/components/common/input";
import FormWrapper from "@/components/common/form";
import DatePicker from "@/components/common/date-picker";
import SelectAutoComplete from "@/components/common/select";
import * as images from "@/constants/images";
import * as helpers from "@/helpers";
import * as actions from "@/stores/actions";
import * as data from "@/data";
import locale from "@/locale";

function CommonIndex() {
  const [isLoading, setLoading] = useState(false);
  const [, setProgress] = useState(0);
  const [imageName, setImageNames] = useState("");
  const [loadings, setLoadings] = useState(false);

  let { user } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const success = useCallback(() => {
    message.success("Profile has been updated");
  }, []);

  useEffect(() => {
    if (_.size(user))
      setImageNames(user.profile ? user.profile : `${"placeholder.jpg"}`);
  }, [dispatch, user]);

  const onUpdateData = useCallback(
    values => {
      setLoadings(true);
      values.profile = imageName;
      dispatch(actions.updateUser(values))
        .then(() => {
          success();
          setLoadings(false);
          user.name = values.name;
          user.gender = values.gender;
          user.date_of_birth = values.date_of_birth;
          user.profile = imageName;
        })
        .catch(() => {
          setLoadings(false);
        });
    },
    [dispatch, imageName, success, user]
  );

  const handleUploadStart = () => {
    setLoading(true);
    setProgress(0);
    setImageNames("");
  };

  const handleUploadError = () => {
    setLoading(false);
  };

  const handleUploadSuccess = filename => {
    setImageNames(filename);
    setProgress(100);
    setLoading(false);
    dispatch(
      actions.updateUser({
        profile: filename
      })
    );
    user.filename = filename;
  };

  const onSubmit = values => {
    setLoadings(false);
    const validationRule = {
      name: {
        required: true
      },
      gender: {
        required: true
      },
      date_of_birth: {
        required: true
      }
    };

    return helpers.createSubmitAction(
      validationRule,
      onUpdateData,
      null // if no need custom values before submit pass null
    )(values);
  };

  let showLoad = isLoading ? (
    <Fragment>
      <div className="loading-gif">
        <img src={images.IMAGE_GIF} alt="" />
      </div>
    </Fragment>
  ) : null;

  if (!user.id) return null;

  return (
    <Fragment>
      <div className="page-auto">
        <div>
          <Title tag="span" title={locale("account")} className="title-general" />
        </div>
        <div className="flex-container">
          <div className="left-side">
            {/* <div className="image-logo">
              {imageName && (
                <img
                  className="image-user"
                  src={helpers.setFullMediaPath(`profiles%2F${imageName}`)}
                  alt=""
                />
              )}
              {showLoad}
              <div className="pop-up">
                <Icon type="camera" />
                <FileUploader
                  accept="image/*"
                  name="avatar"
                  className="file-upload"
                  filename={file =>
                    `${user.id}_${FIRSTORE_TIME_MILLI}` +
                    file.name.split(".")[1]
                  }
                  storageRef={storageRef.ref("profiles")}
                  onUploadStart={handleUploadStart}
                  onUploadError={handleUploadError}
                  onUploadSuccess={handleUploadSuccess}
                />
              </div>
            </div> */}
            {/* <Title tag="span" title="Pre-Uni-6" className="title-class-name" />
            <Title
              tag="span"
              title="Westland New Building"
              className="title-school-name"
            /> */}
          </div>
          <div className="form-input">
            <Title
              tag="span"
              title={locale("personalDetails")}
              className="title-personal"
            />
            <FormWrapper
              initialValues={user}
              className="form-context"
              form="initializeForm"
              onSubmit={onSubmit}
            >
              <Label>{locale("name")}</Label>
              <InputText name="name" placeholder="Name" />
              <Label>{locale("gender")}</Label>
              <SelectAutoComplete
                placeholder={locale("gender")}
                name="gender"
                className="select-down"
                dataSource={data.genderList}
              />
              <Label>{locale("dateOfBirth")}</Label>
              <DatePicker
                name="date_of_birth"
                placeholder={locale("dateOfBirth")}
              />
              <Button
                htmlType="submit"
                loading={loadings}
                onClick={setLoadings}
                className="btn-submit"
              >
                {locale("save")}
              </Button>
            </FormWrapper>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default CommonIndex;
