import styled from "styled-components";
export const Video = styled.video`
  width: 100%;
  transition: all ease-in-out 0.5s;
  opacity: ${props => props.isPause && 0.75};
`;

export const ButtonControlVideo = styled.button`
  color: #35d098;
  margin: auto;
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: inherit;
  border: 3px solid #35d098;

  i {
    font-size: 25px;
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;

    i {
      font-size: 20px;
    }
  }
`;
