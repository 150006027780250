import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import activity from "./activity";
import app from "./app";
import auth from "./auth";
import checkList from "./checkList";
import classRoom from "./class";
import canvas from "./canvas";
import canvasStatus from "./canvasStatus";
import canvasHistory from "./canvasHistory";
import canvasTeam from "./canvasTeam";
import canvasTeamSubmit from "./canvasTeamSubmit";
import course from "./course";
import enrollment from "./enrollment";
import htmlPage from "./htmlPage";
import lang from "./lang";
import member from "./member";
import quiz from "./quiz";
import quizLog from "./quizLog";
import resource from "./resource";
import school from "./school";
import session from "./session";
import sessionLog from "./sessionLog";
import team from "./team";
import teamMember from "./teamMember";
import teamInvitation from "./teamInvitation";
import user from "./user";
import activityLog from "./activityLog";
import teacher from "./teacher";
import survey from "./survey";

export default combineReducers({
  form: formReducer,
  app,
  activity,
  activityLog,
  auth,
  checkList,
  classRoom,
  canvas,
  canvasStatus,
  canvasHistory,
  canvasTeam,
  canvasTeamSubmit,
  course,
  enrollment,
  htmlPage,
  lang,
  member,
  quiz,
  quizLog,
  resource,
  school,
  session,
  sessionLog,
  survey,
  team,
  teamInvitation,
  teamMember,
  teacher,
  user
});
