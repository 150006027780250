export const APP_SET_DEFAULT = "APP_SET_DEFAULT";
export const APP_SET_IS_LOADING = "APP_SET_IS_LOADING";
export const APP_SET_IS_ONLINE = "APP_SET_IS_ONLINE";

export const ACTIVITY_SET_ACTIVITY = "ACTIVITY_SET_ACTIVITY";
export const ACTIVITY_SET_ACTIVITIES = "ACTIVITY_SET_ACTIVITIES";
export const ACTIVITY_SET_ACTIVITY_REQUEST = "ACTIVITY_SET_ACTIVITY_REQUEST";
export const ACTIVITY_SET_ACTIVITY_ERROR = "ACTIVITY_SET_ACTIVITY_ERROR";
export const ACTIVITY_SET_ACTIVITY_SUCCESS = "ACTIVITY_SET_ACTIVITY_SUCCESS";
export const ACTIVITY_FIND_ACTIVITY_BY_ID = "ACTIVITY_FIND_ACTIVITY_BY_ID";

export const ACTIVITY_LOG_SET_ACTIVITY_LOG = "ACTIVITY_LOG_SET_ACTIVITY_LOG";
export const ACTIVITY_LOG_SET_ACTIVITY_LOGS = "ACTIVITY_LOG_SET_ACTIVITY_LOGS";
export const ACTIVITY_LOG_SET_ACTIVITY_LOG_REQUEST = "ACTIVITY_LOG_SET_ACTIVITY_LOG_REQUEST";
export const ACTIVITY_LOG_SET_ACTIVITY_LOG_ERROR = "ACTIVITY_LOG_SET_ACTIVITY_LOG_ERROR";
export const ACTIVITY_LOG_SET_ACTIVITY_LOG_SUCCESS = "ACTIVITY_LOG_SET_ACTIVITY_LOG_SUCCESS";
export const ACTIVITY_LOG_FIND_ACTIVITY_LOG_BY_ID = "ACTIVITY_FIND_ACTIVITY_BY_ID";

export const AUTH_WITH_EMAIL_PASSWORD = "AUTH_WITH_EMAIL_PASSWORD";
export const AUTH_IS_MAIL_RESET_PASSWORD = "AUTH_IS_MAIL_RESET_PASSWORD";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_USER_ID = "AUTH_USER_ID";
export const AUTH_USER = "AUTH_USER";
export const AUTH_IS_VERIFY = "AUTH_IS_VERIFY";


export const CHECK_LIST_SET_CHECK_LIST = "CHECK_LIST_SET_CHECK_LIST";
export const CHECK_LIST_SET_CHECK_LISTS = "CHECK_LIST_SET_CHECK_LISTS";

export const CLASS_SET_CLASS = "CLASS_SET_CLASS";

export const CANVAS_SET_CANVASES = "CANVAS_SET_CANVASES";
export const CANVAS_SET_CANVASES_SUCCESS = "CANVAS_SET_CANVASES_SUCCESS";
export const CANVAS_SET_CANVASES_ERROR = "CANVAS_SET_CANVASES_ERROR";
export const CANVAS_SET_CANVAS = "CANVAS_SET_CANVAS";
export const CANVAS_SET_FIND_CANVAS = "CANVAS_SET_FIND_CANVAS";

export const CANVAS_STATUS_SET_CANVAS_STATUSES = "CANVAS_STATUS_SET_CANVAS_STATUSES";
export const CANVAS_STATUS_SET_CANVAS_STATUS = "CANVAS_STATUS_SET_CANVAS_STATUS";

export const CANVAS_HISTORY_SET_CANVAS_HISTORY = "CANVAS_HISTORY_SET_CANVAS_HISTORY";
export const CANVAS_HISTORY_SET_CANVAS_HISTORIES = "CANVAS_HISTORY_SET_CANVAS_HISTORIES";
export const CANVAS_HISTORY_SET_TOTAL = "CANVAS_HISTORY_SET_TOTAL";

export const CANVAS_TEAM_SET_CANVAS_TEAM = "CANVAS_TEAM_SET_CANVAS_TEAM";
export const CANVAS_TEAM_SET_CANVAS_TEAMS = "CANVAS_TEAM_SET_CANVAS_TEAMS";
export const CANVAS_TEAM_SET_CANVAS_TEAMS_REQUEST = "CANVAS_TEAM_SET_CANVAS_TEAMS_REQUEST";
export const CANVAS_TEAM_SET_CANVAS_TEAMS_SUCCESS = "CANVAS_TEAM_SET_CANVAS_TEAMS_SUCCESS";
export const CANVAS_TEAM_SET_CANVAS_TEAMS_ERROR = "CANVAS_TEAM_SET_CANVAS_TEAMS_ERROR";

export const CANVAS_TEAM_SET_CANVAS_TEAM_SUBMIT = "CANVAS_TEAM_SET_CANVAS_TEAM";
export const CANVAS_TEAM_SET_CANVAS_TEAM_SUBMITS = "CANVAS_TEAM_SET_CANVAS_TEAM_SUBMITS";
export const CANVAS_TEAM_SET_CANVAS_TEAMS_SUBMITS_REQUEST = "CANVAS_TEAM_SET_CANVAS_TEAMS_SUBMITS_REQUEST";
export const CANVAS_TEAM_SET_CANVAS_TEAMS_SUBMITS_SUCCESS = "CANVAS_TEAM_SET_CANVAS_TEAMS_SUBMITS_SUCCESS";
export const CANVAS_TEAM_SET_CANVAS_TEAMS_SUBMITS_ERROR = "CANVAS_TEAM_SET_CANVAS_TEAMS_SUBMITS_ERROR";

export const COURSE_SET_COURSE = "COURSE_SET_COURSE";

export const ENROLLMENT_SET_ENROLLMENT = "ENROLLMENT_SET_ENROLLMENT";

export const HTML_PAGE_SET_HTML_PAGES = "HTML_PAGE_SET_HTML_PAGES";
export const HTML_PAGE_SET_HTML_PAGE = "HTML_PAGE_SET_HTML_PAGE";

export const MEMBER_INIT = "MEMBER_INIT";

export const QUIZ_SET_QUIZ = "QUIZ_SET_QUIZ";
export const QUIZ_SET_QUIZZES = "QUIZ_SET_QUIZZES";
export const QUIZ_SET_QUIZZES_SUCCESS = "QUIZ_SET_QUIZZES_SUCCESS";
export const QUIZ_SET_QUIZZES_REQUEST = "QUIZ_SET_QUIZZES_REQUEST";
export const QUIZ_SET_QUIZZES_ERROR = "QUIZ_SET_QUIZZES_ERROR";
export const QUIZ_SET_TOTAL_QUIZ = "QUIZ_SET_TOTAL_QUIZ";
export const QUIZ_SET_TOTAL_CORRECT_ANSWER = "QUIZ_SET_TOTAL_CORRECT_ANSWER";

export const QUIZ_lOG_SET_QUIZ_LOGS = "QUIZ_SET_QUIZ_LOGS";
export const QUIZ_lOG_SET_QUIZ_LOG = "QUIZ_SET_QUIZ_LOG";
export const QUIZ_lOG_SET_IS_PASS_QUIZ = "QUIZ_lOG_SET_IS_PASS_QUIZ";

export const RESOURCE_SET_RESOURCE = "RESOURCE_SET_RESOURCE";
export const RESOURCE_SET_RESOURCES = "RESOURCE_SET_RESOURCES";

export const SET_LOCALE = "SET_LOCALE";

export const SCHOOL_SET_SCHOOLS = "SCHOOL_SET_SCHOOLS";

export const SESSION_SET_SESSIONS = "SESSION_SET_SESSIONS";
export const SESSION_SET_SESSION = "SESSION_SET_SESSION";
export const SESSION_SET_SESSION_BY_ID = "SESSION_SET_SESSION_BY_ID";


export const SESSION_LOG_SET_SESSION_LOGS = "SESSION_LOG_SET_SESSION_LOGS";
export const SESSION_LOG_SET_SESSION_LOG = "SESSION_LOG_SET_SESSION_LOG";

export const SURVEY_SET_SURVEYS = "SURVEY_SET_SURVEYS";
export const SURVEY_SET_SURVEY = "SURVEY_SET_SURVEY";

export const TEAM_SET_TEAMS = "TEAM_SET_TEAMS";
export const TEAM_SET_TEAM = "TEAM_SET_TEAM";

export const TEAM_INVITATION_SET_TEAM_INVITATIONS = "TEAM_INVITATION_SET_TEAM_INVITATIONS";
export const TEAM_INVITATION_SET_TEAM_INVITATION = "TEAM_INVITATION_SET_TEAM_INVITATION";

export const TEAM_MEMBER_SET_TEAM_MEMBER = "TEAM_MEMBER_SET_TEAM_MEMBER";
export const TEAM_MEMBER_SET_TEAM_MEMBERS = "TEAM_MEMBER_SET_TEAM_MEMBERS";
export const TEAM_MEMBER_SET_TEAM_MEMBERS_NOTI = "TEAM_MEMBER_SET_TEAM_MEMBERS_NOTI";

export const USER_SET_USER = "USER_SET_USER";
export const USER_SET_USERS = "USER_SET_USERS";
export const USER_REGISTER = "USER_REGISTER";

export const TEACHER_SET_TEACHER = "TEACHER_SET_TEACHER";


