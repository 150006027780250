/* eslint-disable indent */
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  teamMembers: [],
  teamMembersNoti: [],
  teamMember: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TEAM_MEMBER_SET_TEAM_MEMBERS:
      return { ...state, teamMembers: action.teamMembers };
    case actionTypes.TEAM_MEMBER_SET_TEAM_MEMBERS_NOTI:
      return { ...state, teamMembersNoti: action.teamMembers };
    case actionTypes.TEAM_MEMBER_SET_TEAM_MEMBER:
      return { ...state, teamMember: action.teamMember };
    default:
      return state;
  }
};

export default reducer;
