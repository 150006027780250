// Libs
import React, { Fragment, useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

// Components
import Title from "@/components/common/title";
import LearningDashboardLayout from "../index";
import Instruction from "./instruction";
import TeamInformation from "./team-information";
import * as actions from "@/stores/actions";
import { history } from "@/shared/utils/history";
import * as routes from "@/constants/routes";
import Invitation from "./invitation";
import NextButton from "@/components/common/next-button";

function FormingTeam() {
  const [isInvited, setIsInvited] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { user } = useSelector(state => state.user);
  const { session } = useSelector(state => state.session);
  const { activity } = useSelector(state => state.activity);
  const { teamMembers } = useSelector(state => state.teamMember);
  const dispatch = useDispatch();

  useEffect(() => {
    const { pathname } = history;
    if (pathname === routes.LESSON_DETAIL_FORM_TEAM_INVITATION) {
      return;
    }

    if (_.size(user))
      dispatch(actions.getTeamMembers({ user_id: user.id, is_verify: false }));
  }, [dispatch, user]);

  useEffect(() => {
    if (teamMembers.length) {
      setIsInvited(true);
    }
  }, [teamMembers]);

  const viewInvitation = useCallback(() => {
    setIsVisible(true);
  }, []);

  return (
    <LearningDashboardLayout hasSidebar={true} hasBreadcrumb={true}>
      <Fragment>
        <Title
          tag="h4"
          title={activity.title}
          className="mb-5 text-center text-shadow text-primary"
        />
        {!user.is_join_team && !isVisible && (
          <Instruction
            user={user}
            isInvited={isInvited}
            activity={activity}
            session={session}
            viewInvitation={viewInvitation}
          />
        )}
        {!user.is_join_team && isVisible && (
          <Invitation />
        )}
        {user.is_join_team && <TeamInformation />}
        <NextButton />
      </Fragment>
    </LearningDashboardLayout>
  );
}

export default FormingTeam;
