/* eslint-disable no-unused-vars */
// Libs
import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Modal, Button, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { Col, Label } from 'reactstrap';
import R from 'ramda';

// Components
import Title from '@/components/common/title';
import * as actions from '@/stores/actions';
import { history } from '@/shared/utils/history';
import { getLocationParamSearch, isEmpty } from '@/helpers';
import locale from '@/locale';

function Invitation() {
  const [visibleModal, setVisibleModal] = useState(false);
  const [team, setTeam] = useState({});
  const [member, setMember] = useState({});
  const { teamMembers } = useSelector((state) => state.teamMember);
  const { teams } = useSelector((state) => state.team);
  const { activity } = useSelector((state) => state.activity);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const getDeleteInviteIds = useCallback(
    (paramMember) => {
      let docIds = [];
      teamMembers.forEach((itemTeamMember) => {
        if (itemTeamMember.id !== paramMember.id)
          docIds.push(itemTeamMember.id);
      });
      return docIds;
    },
    [teamMembers]
  );

  const getDeleteInviteId = useCallback(
    (paramTeamMember) => {
      let docIds = [];
      teamMembers.forEach((itemTeamMember) => {
        if (itemTeamMember.id === paramTeamMember.id)
          docIds.push(itemTeamMember.id);
      });
      return docIds;
    },
    [teamMembers]
  );

  const showModal = useCallback(
    (paramTeamMember) => {
      setMember(paramTeamMember);
      let foundTeam = R.find(R.propEq('id', paramTeamMember.team_id))(teams);
      setTeam(foundTeam);
      setVisibleModal(true);
    },
    [teams]
  );

  const handleCancel = useCallback(() => {
    dispatch(actions.deleteTeamMembers(getDeleteInviteId(member)));
    setVisibleModal(false);
  }, [dispatch, getDeleteInviteId, member]);

  const handleJoin = useCallback(
    (paramMember) => {
      const { activityId } = getLocationParamSearch(history);
      if (document.getElementById(activityId)) {
        document.getElementById(activityId).click();
      }

      let tmpData = {
        id: paramMember.id,
        is_verify: true,
      };

      dispatch(actions.deleteTeamMembers(getDeleteInviteIds(paramMember)));
      dispatch(actions.updateTeamMember(tmpData)).then(() => {
        let tmpData = {
          is_join_team: true,
          team_id: paramMember.team_id,
        };

        dispatch(actions.updateUser(tmpData));
        dispatch(actions.setUser({ ...user, ...tmpData }));
      });
    },
    [dispatch, getDeleteInviteIds, user]
  );

  useEffect(() => {
    if (_.size(user)) {
      dispatch(actions.getTeamMembers({ user_id: true, is_verify: false }));
      dispatch(actions.getTeams({ class_id: user.class_id }));
    }
  }, [dispatch, user]);

  return (
    <Fragment>
      {/* {!activity.id && <Skeleton active paragraph={{ rows: 5 }} />}
      {!teamMembers.length && <Skeleton active paragraph={{ rows: 15 }} />} */}
      {teamMembers.length > 0 &&
        teamMembers.map((itemTeamMember) => {
          let teamName = '';
          let teamDescription = '';
          let foundTeam = R.find(R.propEq('id', itemTeamMember.team_id))(teams);

          if (foundTeam) {
            teamName = foundTeam.name;
            teamDescription = foundTeam.description;
          }
          return (
            <div
              className='p-4 mb-5 rounded text-center bg-light box-shadow'
              key={itemTeamMember.id}
            >
              <Title
                tag='h6'
                className='mb-4 text-dark'
                title={locale('messageTeamJoinRejectInfo').replace(
                  '#####',
                  teamName
                )}
              />
              <div className='p-4 mb-4 rounded bg-white box-shadow border-1 border-light'>
                <Label className='d-block text-left mb-0'>
                  <span className='pr-2 text-nowrap font-weight-bold'>
                    {locale('teamName')}:
                  </span>
                  {teamName}
                </Label>
                <Label className='d-block text-left mb-0'>
                  <span className='pr-2 text-nowrap font-weight-bold'>
                    {locale('teamDescription')}:
                  </span>
                  {teamDescription}
                </Label>
              </div>
              <Col
                md='6'
                xs='12'
                className='mx-auto d-flex align-items-center justify-content-between'
              >
                <Button
                  type='primary'
                  className='px-5'
                  onClick={() => handleJoin(itemTeamMember)}
                  //onClick={() => showModal(itemTeamMember)}
                >
                  {locale('join')}
                </Button>
                <Button
                  ghost
                  type='primary'
                  className='px-5'
                  onClick={() => showModal(itemTeamMember)}
                >
                  {locale('cancel')}
                </Button>
              </Col>
            </div>
          );
        })}

      <Modal
        centered
        footer={null}
        closable={false}
        keyboard={false}
        maskClosable={false}
        visible={visibleModal}
        style={{ borderRadius: 20 }}
        className='pb-0 text-center border-1 border-primary'
      >
        <Label className='mb-4 text-dark'>
          {locale('messageCancelJoinTeam').replace(
            '#####',
            !isEmpty(team) ? team.name : ''
          )}
        </Label>
        <Button type='primary' className='px-5' onClick={handleCancel}>
          {locale('okay')}
        </Button>
      </Modal>
    </Fragment>
  );
}

Invitation.propTypes = {};

Invitation.defaultProps = {
  teamMembers: [],
};

export default Invitation;
