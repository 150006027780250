/*eslint-disable indent */
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  activityLogs: [],
  activityLog: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTIVITY_LOG_SET_ACTIVITY_LOGS:
      return { ...state, activityLogs: action.activityLogs };
    case actionTypes.ACTIVITY_LOG_SET_ACTIVITY_LOG:
      return { ...state, activityLog: action.activityLog };
    default:
      return state;
  }
};

export default reducer;

