// Libs
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Components
import Title from "@/components/common/title";
import LearningDashboardLayout from "../index";

import NextButton from "@/components/common/next-button";
import { Skeleton, notification } from "antd";
import { CANVASES } from "@/constants/routes";
import locale from "@/locale";

function Canvas() {
  const activity = useSelector(state => state.activity.activity);
  const { user } = useSelector(state => state.user);

  const openNotificationWithIcon = type => {
    notification[type]({
      message: "Notification Title",
      description:
        "Please join team before go to canvas."
    });
  };

  return (
    <LearningDashboardLayout hasSidebar={true} hasBreadcrumb={true}>
      {!activity.id && <Skeleton active paragraph={{ rows: 5 }} />}
      {activity.id && (
        <>
          <Title
            tag="h4"
            title={activity.title}
            className="mb-5 text-center text-shadow text-primary"
          />
          <Title
            tag="p"
            title={activity["en_description"]}
            className="mb-5 text-center text-muted"
          />
        </>
      )}

      <div className="mt-3 text-center" style={{ marginBottom: "150px" }}>
        {user.team_id && (
          <Link
            to={`${CANVASES}/${activity.canvas_id}`}
            target="_blank"
            className="py-2 px-3 rounded text-white bg-gradient-primary-top-bottom"
          >
            {locale("gotoCanvas")}
          </Link>
        )}
        {!user.team_id && (
          <Link
            onClick={() => openNotificationWithIcon("error")}
            to="#"
            className="py-2 px-3 rounded text-white bg-gradient-primary-top-bottom"
          >
            {locale("gotoCanvas")}
          </Link>
        )}
      </div>
      <NextButton />
    </LearningDashboardLayout>
  );
}

export default Canvas;
