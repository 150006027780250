import * as actionTypes from "./actionTypes";
import { firestoreRef, FIRSTORE_TIMESTAMP } from "@/configs/firebase";

export const setSessionLogs = sessionLogs => ({
  type: actionTypes.SESSION_LOG_SET_SESSION_LOGS,
  sessionLogs
});

export const setSessionLog = sessionLog => ({
  type: actionTypes.SESSION_LOG_SET_SESSION_LOG,
  sessionLog
});

export const getSessionLog = (sessionId) => (dispatch, getState) => {
  try {
    const userId = getState().auth.userId;

    return firestoreRef
      .collection("session_logs")
      .where("user_id", "==", userId)
      .where("session_id", "==", sessionId)
      .onSnapshot(snapshots => {
        let data = {};
        snapshots.forEach(doc => {
          data = { ...doc.data(), id: doc.id };
        });

        dispatch(setSessionLog(data));
      });
  } catch (err) {
    console.log(err);
  }
};

export const getSessionLogs = () => (dispatch, getState) => {
  try {
    const { userId } = getState().auth;

    return firestoreRef
      .collection("session_logs")
      .where("user_id", "==", userId)
      .orderBy("created", "desc")
      .onSnapshot(snapshots => {
        let data = [];
        snapshots.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id });
        });

        dispatch(setSessionLogs(data));
      });
  } catch (err) {
    console.log(err);
  }
};

export const insertSessionLog = (sessionId) => (dispatch, getState) => {
  try {
    const { userId } = getState().auth;

    return firestoreRef
      .collection("session_logs")
      .where("user_id", "==", userId)
      .where("session_id", "==", sessionId)
      .onSnapshot(snapshots => {

        if (snapshots.size) {
          dispatch(setSessionLog({ ...snapshots.docs[0].data(), id: snapshots.docs[0].id }));
        } else {

          let docId = firestoreRef.collection("session_logs").doc().id;
          let newData = {
            id: docId,
            user_id: userId,
            session_id: sessionId,
            created: FIRSTORE_TIMESTAMP,
            is_completed: false
          };

          dispatch(setSessionLog(newData));
          firestoreRef
            .collection("session_logs")
            .doc(docId)
            .set(newData);
        }
      });
  } catch (err) {
    console.log(err);
  }
};

export const updateSessionLog = (sessionLogId, data = {}) => () => {
  try {
    return firestoreRef
      .collection("session_logs")
      .doc(sessionLogId)
      .update(data);
  } catch (err) {
    console.log(err);
  }
};
