import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { Label } from "reactstrap";

import Title from "@/components/common/title";
import InputText from "@/components/common/input";
import FormWrapper from "@/components/common/form";
import DatePicker from "@/components/common/date-picker";
import SelectAutoComplete from "@/components/common/select";
import { useSelector, useDispatch } from "react-redux";

import { history } from "@/shared/utils/history";

import * as helpers from "@/helpers";
import * as routes from "@/constants/routes";
import * as actions from "@/stores/actions";
import * as data from "@/data";
import { FIRSTORE_TIMESTAMP } from "@/configs/firebase";
import locale from "@/locale";

function Form() {
  const [error, setError] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const schools = useSelector(state => state.school.schools);
  const dispatch = useDispatch();

  const onSignUpSuccess = useCallback(
    values => {
      setLoadings(true);
      dispatch(actions.createUserWithEmailAndPassword(values))
        .then(user => {
          let data = values;
          delete data["password"];
          delete data["user_id"];
          data["id"] = user.uid;
          return data;
        })
        .then(data => {
          data["created"] = FIRSTORE_TIMESTAMP;
          data["is_join_team"] = false;
          data["is_team_leader"] = false;
          data["is_join_class"] = false;

          dispatch(actions.registerUser(data));
          return data;
        })
        .then(() => {
          dispatch(actions.sendEmailVerification()).then(() => {
            history.push(routes.EMAIL_VERIFY);
            // authRef.signOut();
          });
        })
        .catch(err => {
          setError(true);
          setLoadings(false);
          console.log(err);
        });

      return false;
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    values => {
      setLoadings(false);
      const validationRule = {
        name: {
          required: true
        },
        gender: {
          required: true
        },
        date_of_birth: {
          required: true
        },
        school: {
          required: true
        },
        email: {
          required: true,
          type: "email"
        },
        password: {
          required: true,
          type: "password"
        }
      };

      return helpers.createSubmitAction(
        validationRule,
        onSignUpSuccess,
        null // if no need custom values before submit pass null
      )(values);
    },
    [onSignUpSuccess]
  );

  useEffect(() => {
    dispatch(actions.getSchools());
  }, [dispatch]);

  let schoolLists = schools.map(school => {
    return { id: school.id, name: school.name };
  });

  return (
    <Fragment>
      {error && (
        <div className="p-3 mb-3 border-2 border-danger bg-danger text-center text-muted rounded">
          <Title className="text-muted" title={locale("emailInUsed")} />
          <span>{locale("errorEmailExisted")}</span>
        </div>
      )}
      <Title
        tag="h4"
        title={locale("createAccount")}
        className="text-primary text center"
      />
      <FormWrapper form="createAccount" onSubmit={onSubmit}>
        <InputText name="name" placeholder={locale("name")} />
        <SelectAutoComplete
          placeholder={locale("gender")}
          name="gender"
          dataSource={data.genderList}
        />
        <DatePicker name="date_of_birth" placeholder={locale("dateOfBirth")} />
        <SelectAutoComplete
          placeholder={locale("school")}
          name="school"
          dataSource={schoolLists}
        />
        <InputText name="email" placeholder={locale("email")} />
        <InputText
          name="password"
          type="Password"
          placeholder={locale("password")}
        />
        <Label className="d-flex align-items-center justify-content-center mb-3">
          {locale("alreadyHaveAccount")}
          <Link to={routes.SIGN_IN}>
            <span className="text-primary px-1">{locale("signIn")}</span>
          </Link>
          {locale("now")}
        </Label>
        <Button
          ghost
          type="primary"
          loading={loadings}
          onClick={setLoadings}
          className="px-4"
          htmlType="submit"
        >
          {locale("submit")}
        </Button>
      </FormWrapper>
    </Fragment>
  );
}

export default Form;
