export const LOGO = require("../assets/images/logo/smart_start_logo.png");
export const BACKGROUND_IMAGE = require("../assets/images/background/owl-and-book.svg");
export const BACKGROUND_IMAGE1 = require("../assets/images/background/paper-plane.svg");
export const BACKGROUND_IMAGE2 = require("../assets/images/background/background.svg");
export const BACKGROUND_IMAGE3 = require("../assets/images/background/people-and-book.svg");
export const ENVELOPE = require("../assets/images/utility/envelope.svg");
export const FOLDER = require("../assets/images/utility/folder.svg");
export const FOLDER_RECTANGLE = require("../assets/images/utility/folder-rectangle.svg");
export const FOLDER_RECTANGLE_CHECK = require("../assets/images/utility/folder-rectangle-check.svg");
export const IMAGE_USER = require("../assets/images/user/myself.jpg");
export const IMAGE_GIF = require("../assets/images/utility/loadin.gif");
export const IMAGE_VERIFIED = require("../assets/images/logo/Group.png");
export const IMAGE_VERIFY = require("../assets/images/logo/Group.png");
export const IMAGE_STAR = require("../assets/images/logo/start.png");
export const IMAGE_LOGO_SMART = require("../assets/images/logo/smart_start_logo.png");

// canvas
export const IDEA_GENERATION_IMAGE = require("../assets/images/canvas/idea-generation.png");
export const BUSINESS_MODEL_CANVAS_IMAGE = require("../assets/images/canvas/business-model-canvas.png");
export const TEAM_REFLECTION_IMAGE = require("../assets/images/canvas/team-reflection.png");
export const CUSTOMER_DISCOVERY_IMAGE = require("../assets/images/canvas/customer-discovery.png");
export const COMPETITION_ANALYSIS_IMAGE = require("../assets/images/canvas/competition-analysis.png");
export const PEER_FEEDBACK_IMAGE = require("../assets/images/canvas/peer-feedback.png");
export const PROTOTYPING_IMAGE = require("../assets/images/canvas/prototyping.png");
export const TEAM_CANVAS_IMAGE = require("../assets/images/canvas/team-canvas.png");

export const IDEA_AND_GENERATION = require("../assets/images/canvas/idea-and-generation.svg");
export const PEOPLES_AND_ROLE_ICON = require("../assets/images/canvas/people-and-role.svg");
export const VALUE_ICON = require("../assets/images/canvas/value.svg");
export const PURPOSE_ICON = require("../assets/images/canvas/purpose.svg");
export const COMMON_GOAL_ICON = require("../assets/images/canvas/common-goal.svg");
export const PERSONAL_GOAL_ICON = require("../assets/images/canvas/personal-and-goal.svg");
export const RULE_AND_ACTIVITY_ICON = require("../assets/images/canvas/rule-and-activity.svg");
export const STRENGTH_AND_ASSET_ICON = require("../assets/images/canvas/strength-and-asset.svg");
export const NEED_AND_EXPECTATION_ICON = require("../assets/images/canvas/need-and-expectation.svg");

export const CHECK_LIST_SUCCESS = require("../assets/images/utility/check_list_success.png");
export const CHECK_LIST_FAIL = require("../assets/images/utility/check_list_fail.png");

export const BACK_ARROW = require("../assets/images/utility/left-arrow.svg");

export const NUMBER_1 = require("../assets/images/number/num_1.png");
export const NUMBER_2 = require("../assets/images/number/num_2.png");
export const NUMBER_3 = require("../assets/images/number/num_3.png");
export const NUMBER_4 = require("../assets/images/number/num_4.png");
export const NUMBER_5 = require("../assets/images/number/num_5.png");
export const NUMBER_6 = require("../assets/images/number/num_6.png");
export const NUMBER_7 = require("../assets/images/number/num_7.png");
export const NUMBER_8 = require("../assets/images/number/num_8.png");

// About Us
export const CBRD = require("../assets/images/about-us/CBRD-LOGO.png");
export const IMPACT_HUB = require("../assets/images/about-us/Impact-Hub.png");
export const EDEMY = require("../assets/images/about-us/Learning-Platform-Partner-logo.png");
export const RULE_CAMBODIA = require("../assets/images/about-us/Logo_of_Rule_Cambodia.png");
export const MINISTRY_OF_EDUCATION = require("../assets/images/about-us/Ministry-of-Education.png");
export const MPTC = require("../assets/images/about-us/MPTC.jpg");
export const NATIONAL_UNIVERSITY_OF_CAMBODIA = require("../assets/images/about-us/National_University_of_Cambodia_logo.png");
export const NIPTICT = require("../assets/images/about-us/NIPTICT.png");
export const SMART_AXIATA = require("../assets/images/about-us/Smart-Axiata.png");
export const UP = require("../assets/images/about-us/UP.jpg");

export const ICON_ABOUT_US = require("../assets/images/icons/about-us.png");
export const ICON_ACCOUNT = require("../assets/images/icons/account.png");
export const ICON_ACCOUNT_SETTING = require("../assets/images/icons/account-setting.png");
export const ICON_EMAIL = require("../assets/images/icons/email.png");
export const ICON_HOME = require("../assets/images/icons/home.png");
export const ICON_LOGOUT = require("../assets/images/icons/logout.png");
export const ICON_LOGIN = require("../assets/images/icons/sign-in.png");
export const ICON_REGISTER = require("../assets/images/icons/register.png");
export const ICON_SECURITY = require("../assets/images/icons/security.png");
export const ICON_TEAM = require("../assets/images/icons/team.png");
export const ICON_LEARNING_DASHBOARD = require("../assets/images/icons/learing-dashboard.png");
export const ICON_CANVAS = require("../assets/images/icons/canvas.png");
export const ICON_CHECK = require("../assets/images/icons/check.png");

