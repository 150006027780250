import * as actionTypes from "./actionTypes";
import { firestoreRef } from "@/configs/firebase";

export const setSessions = sessions => ({
  type: actionTypes.SESSION_SET_SESSIONS,
  sessions
});

export const setSession = session => ({
  type: actionTypes.SESSION_SET_SESSION,
  session
});

export const setSessionById = id => ({
  type: actionTypes.SESSION_SET_SESSION_BY_ID,
  id
});

export const getSessions = () => dispatch => {
  try {
    return firestoreRef
      .collection("sessions")
      .where("publish", "==", true)
      .orderBy("rank", "asc")
      .onSnapshot(snapshots => {
        let data = [];
        snapshots.forEach(doc => {
          data.push({
            ...doc.data(),
            id: doc.id
          });
        });
        dispatch(setSessions(data));
      });
  } catch (err) {
    console.log(err);
  }
};

export const getSession = sessionId => dispatch => {
  try {
    return firestoreRef
      .collection("sessions")
      .doc(sessionId)
      .onSnapshot(doc => {
        if (doc.exists) {
          let data = { ...doc.data(), id: doc.id };
          dispatch(setSession(data));
        }
      });
  } catch (err) {
    console.log(err);
  }
};
