// Libs
import React, { useState } from "react";
import { useSelector } from "react-redux";

// Components
import Title from "@/components/common/title";
import LearningDashboardLayout from "../index";

// Util
import * as constants from "@/constants/constant";
import * as helpers from "@/helpers";
import NextButton from "@/components/common/next-button";
// import { Skeleton } from "antd";

// CKEditor Style
import "./style.scss";

function HtmlPage() {
  const { lang } = useSelector(state => state.lang);
  const [contentLanguage, setContentLanguage] = useState(lang);

  const { activity } = useSelector(state => state.activity);
  const { htmlPage } = useSelector(state => state.htmlPage);

  const switchContentLanguage = language => {
    setContentLanguage(language);
  };

  return (
    <LearningDashboardLayout
      hasSidebar={true}
      hasBreadcrumb={true}
      className="html-page"
    >
      <>
        <Title
          tag="h4"
          title={activity.title}
          className="mb-5 text-center text-shadow text-primary"
        />
        <Title
          tag="h6"
          title={activity.subtitle}
          className="mb-5 text-center text-muted"
        />
      </>
      <>
        <div>
          {htmlPage["image"] && (
            <img
              src={helpers.setFullMediaPath(
                `html_pages/${htmlPage["image"]}`
              )}
              className="img-html-page"
            />
          )}
        </div>
        <div className="mt-3 text-right">
          <a
            onClick={() => switchContentLanguage(constants.EN)}
            className={`${contentLanguage == constants.EN &&
              "text-primary"} pr-2`}
          >EN</a> |
          <a
            onClick={() => switchContentLanguage(constants.KH)}
            className={`${contentLanguage == constants.KH &&
              "text-primary"} pl-2`}
          >ខ្មែរ</a>
        </div>
        <div className="mt-3">
          {htmlPage.kh_slide && (
            <div className="video-wrapper">
              <iframe
                title="slide"
                key={htmlPage[`${contentLanguage}_slide`]}
                src={htmlPage[`${contentLanguage}_slide`]}
                width="100%"
                height="100%"
                scrolling="no"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              ></iframe>
            </div>
          )}
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: htmlPage[`${contentLanguage}_content`]
            }}
          />
        </div>
        <NextButton />
      </>
      {/* )} */}
    </LearningDashboardLayout>
  );
}

export default HtmlPage;
