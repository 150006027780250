// Libs
import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { Row, Col, Label } from 'reactstrap';
import { Button, Select } from 'antd';
import { change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

// Components
import Title from '@/components/common/title';
import Input from '@/components/common/input';
import FormWrapper from '@/components/common/form';
import { history } from '@/shared/utils/history';
import * as routes from '@/constants/routes';

// Helper
import * as helpers from '@/helpers';
import {
  getUsers,
  insertTeam,
  insertTeamMember,
  updateTeam,
  deleteTeamMembers,
  updateUsers,
  getTeamMembers,
  updateUser,
} from '@/stores/actions';
import TeamInformation from '@/views/learning-dashboard/forming-team/team-information';
import { FIRSTORE_TIMESTAMP } from '@/configs/firebase';
import * as constants from '@/constants/constant';
import locale from '@/locale';
import useRouter from '@/hook/useRouter';

const { Option } = Select;

function Form() {
  const [visible, setVisible] = useState(false);
  const [userTeams, setUserTeams] = useState([]);
  const [showMemberMessage, setShowMemberMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const dispatch = useDispatch();
  const { users, user } = useSelector((state) => state.user);
  const { team } = useSelector((state) => state.team);
  const { teamMembers } = useSelector((state) => state.teamMember);
  const { status, sessionId, activityId } = useRouter();

  useEffect(() => {
    setVisible(status === 'create' || status === 'edit');
    setLoading(false);
  }, [status]);

  useEffect(() => {
    if (_.size(user)) {
      dispatch(getUsers({ class_id: user.class_id }));
    }
  }, [dispatch, user]);

  const updateField = useCallback(
    (field, value) => {
      dispatch(change('addMemberForm', field, value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (_.size(team)) {
      updateField('name', team.name);
      updateField('description', team.description);
      updateField('id', team.id);
      dispatch(getTeamMembers({ team_id: team.id }));
    }
  }, [dispatch, updateField, team]);

  useEffect(() => {
    if (_.size(team) && _.size(user) && teamMembers.length && users.length) {
      let teams = _.filter(teamMembers, (item) => {
        return item.team_id === team.id && user.id !== item.user_id;
      });

      let teams2 = _.map(teams, (item) => {
        let { id, name, email } = _.find(users, { id: item.user_id });
        return `${id}#####${name}#####${email}`;
      });
      setMembers(teams2);
      updateField('member', teams2);
    }
  }, [teamMembers, team, users, user, updateField]);

  useEffect(() => {
    if (_.size(user) && users.length) {
      let filterUsers = _.filter(users, (item) => {
        if (user.team_id) {
          if (item.team_id) {
            if (item.team_id !== user.team_id) return false;
            if (item.is_team_leader) return false;
          }
        } else {
          if (item.team_id) return false;
          if (item.is_team_leader) return false;
        }

        if (!item.name) return false;

        return true;
      });
      setUserTeams(filterUsers);
    }
  }, [users, user]);

  const splitData = useCallback((data) => {
    return data.map((item) => {
      let tmp = item.split('#####');
      return tmp[0];
    });
  }, []);

  const onChange = useCallback(
    (selectedMembers) => {
      setShowMemberMessage(false);
      updateField('member', splitData(selectedMembers));
    },
    [updateField, splitData]
  );

  const handleSubmit = useCallback(
    (values) => {
      let routePath = `${routes.LESSON_DETAIL}?sessionId=${
        sessionId || constants.SESSION_ID_FORM_TEAM
      }&activityId=${activityId || constants.ACTIVITY_ID_FORM_TEAM}`;
      let routePathUrl = routePath;
      if (!activityId) {
        routePath = `${routes.FORM_TEAM}?action=view`;
      }

      setLoading(true);

      let objTeam = {
        name: values.name,
        description: values.description,
        class_id: user.class_id ? user.class_id : '',
        class_code: user.class_code,
        school_id: user.school,
        created_by: user.id,
      };

      values.member = splitData(values.member);

      if (values.id) {
        objTeam.id = values.id;
        dispatch(updateTeam(objTeam))
          .then((resTeam) => {
            let teamUserIds = teamMembers
              .map((item) => item.user_id)
              .filter((item) => item !== user.id);
            let selectedDeleted = teamUserIds.filter(
              (value) => -1 === values.member.indexOf(value)
            );

            let selectedInsert = values.member.filter(
              (value) => -1 === teamUserIds.indexOf(value)
            );

            let tmpTeamMemberDelete = teamMembers.filter((itemTeam) => {
              return (
                selectedDeleted.indexOf(itemTeam.user_id) !== -1 &&
                itemTeam.team_id === objTeam.id
              );
            });

            dispatch(
              deleteTeamMembers(tmpTeamMemberDelete.map((item) => item.id))
            );
            dispatch(
              updateUsers(selectedDeleted, {
                is_join_team: false,
                team_id: '',
              })
            );

            let teamsData = [];
            selectedInsert.forEach((item) => {
              let tmpData = {
                user_id: item,
                team_id: resTeam,
                created: FIRSTORE_TIMESTAMP,
                school_id: user.school,
                class_id: user.class_id ? user.class_id : '',
                class_code: user.class_code,
                is_verify: false,
                is_team_leader: false,
                route_url: constants.HOST + routePathUrl,
              };

              teamsData.push(tmpData);
            });

            dispatch(insertTeamMember(teamsData));
            setLoading(false);
            if (activityId) {
              history.push(routePath);
            } else {
              setVisible(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        objTeam.created = FIRSTORE_TIMESTAMP;
        dispatch(insertTeam(objTeam))
          .then((resTeam) => {
            if (!resTeam) return;
            let teamsData = [];
            values.member.forEach((item) => {
              let tmpData = {
                user_id: item,
                team_id: resTeam,
                created: FIRSTORE_TIMESTAMP,
                school_id: user.school,
                class_id: user.class_id ? user.class_id : '',
                class_code: user.class_code,
                is_verify: false,
                is_team_leader: false,
                route_url: constants.HOST + routePathUrl,
              };
              teamsData.push(tmpData);
            });

            // insert team leader into team
            let tmpData = {
              user_id: user.id,
              team_id: resTeam,
              created: FIRSTORE_TIMESTAMP,
              school_id: user.school,
              class_id: user.class_id ? user.class_id : '',
              class_code: user.class_code,
              is_verify: true,
              is_team_leader: true,
            };
            teamsData.push(tmpData);
            dispatch(insertTeamMember(teamsData));
            dispatch(
              updateUsers([user.id], {
                is_join_team: true,
                team_id: resTeam,
              })
            );

            if (activityId) {
              history.push(routePath);
            } else {
              setVisible(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    [dispatch, user, splitData, teamMembers, sessionId, activityId]
  );

  const onSubmit = (values) => {
    const validationRule = {
      name: {
        required: true,
      },
      description: {
        required: true,
      },
      member: {
        required: true,
        message: 'Please choose option',
        type: 'array',
      },
    };

    if (!values.member.length) {
      setShowMemberMessage(true);
    }

    return helpers.createSubmitAction(
      validationRule,
      handleSubmit,
      null, // if no need custom values before submit pass null
      false // if function don't need dispatch pass false
    )(values);
  };

  const handleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const onBecomeLeader = () => {
    dispatch(updateUser({ is_team_leader: true }));
    setVisible('edit');
  };

  const onInviteList = () => {
    window.location = `${routes.LESSON_DETAIL}?sessionId=${
      sessionId || constants.SESSION_ID_FORM_TEAM
    }&activityId=${activityId || constants.ACTIVITY_ID_FORM_TEAM}`;
  };

  if (!user.id) {
    return null;
  }

  // Todo check is team leader
  if (user.id && !user.is_team_leader && !user.team_id) {
    return (
      <Fragment>
        <Title
          tag='h3'
          title={locale('formingTeam')}
          className='mb-5 text-center text-shadow text-primary'
        />
        <Label className='d-block text-left mb-5'>
          <span className='pr-2 d-block font-weight-bold'>
            {locale('instruction')}:
          </span>
          {locale('messageInstructionMember')}
        </Label>
        <div className='text-center'>
          <Button type='primary' className='' onClick={onBecomeLeader}>
            {locale('becomeTeamLeader')}
          </Button>
          {/* {teamMembers.length > 0 && ( */}
          <Button className='ml-3' onClick={onInviteList}>
            {locale('inviteList')}
          </Button>
          {/* )} */}
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Title
        tag='h3'
        title={locale('formingTeam')}
        className='mb-5 text-center text-shadow text-primary'
      />
      {user.is_team_leader && visible === true && (
        <Title
          tag='h6'
          className='mb-5 text-muted'
          title={locale('messageTeamLeaderInfo')}
        />
      )}
      <Row>
        <Col xs='12' className='mx-auto'>
          {visible ? (
            <FormWrapper form='addMemberForm' onSubmit={onSubmit}>
              <Label className='mb-4'>{locale('labelAskTeamName')}</Label>
              <Input name='id' hide={true} />
              <Input name='name' />
              <Label className='mb-4'>{locale('labelShortDescTeamName')}</Label>
              <Input
                type='TextArea'
                name='description'
                autosize={{
                  minRows: 5,
                  maxRows: 10,
                }}
              />
              <Label className='mb-4'>{locale('labelAskTeamMember')}</Label>

              <div className='mb-4 d-flex flex-wrap align-items-center team-select'>
                <Select
                  key={members.length}
                  mode='multiple'
                  defaultValue={members}
                  placeholder={locale('addMember')}
                  onChange={onChange}
                  className={`${showMemberMessage ? 'has-error' : ''}`}
                >
                  {userTeams
                    .filter((value) => value.name)
                    .map((value, key) => {
                      return (
                        <Option
                          key={key}
                          value={`${value.id}#####${value.name}#####${value.email}`}
                        >
                          {value.name}
                          <small className='d-block'>{value.email}</small>
                        </Option>
                      );
                    })}
                </Select>
              </div>
              <div
                className='ant-form-explain text-danger'
                style={{ display: showMemberMessage ? 'block' : 'none' }}
              >
                {locale('errorRequired')}
              </div>
              <Button
                type='primary'
                htmlType='submit'
                className='px-4 mx-auto d-block font-16'
                loading={loading}
              >
                {locale('submit')}
              </Button>
            </FormWrapper>
          ) : (
            <TeamInformation
              isShowMessage={true}
              handleVisible={() => handleVisible()}
            />
          )}
        </Col>
      </Row>
    </Fragment>
  );
}

export default Form;
