import * as actionTypes from "../actions/actionTypes";

const initialState = {
  canvasTeamSubmit: {},
  canvasTeamSubmits: [],
  loading: false,
  error: ""
};

const reducer = (state = initialState, action) => {
  /* eslint-disable indent */
  switch (action.type) {
    case actionTypes.CANVAS_TEAM_SET_CANVAS_TEAM_SUBMITS:
      return {
        ...state,
        canvasTeamSubmits: action.canvasTeamSubmits,
        loading: true,
        error: ""
      };
    case actionTypes.CANVAS_TEAM_SET_CANVAS_TEAMS_SUBMITS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case actionTypes.CANVAS_TEAM_SET_CANVAS_TEAMS_SUBMITS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      };
    case actionTypes.CANVAS_TEAM_SET_CANVAS_TEAMS_SUBMITS_ERROR:
      return {
        ...state,
        loading: false,
        error: action
      };
    case actionTypes.CANVAS_TEAM_SET_CANVAS_TEAM_SUBMIT:
      return { ...state, canvasTeamSubmit: action.canvasTeamSubmit };
    default:
      return state;
  }
};

export default reducer;
