import React from "react";
import { NavLink } from "react-router-dom";
import { CANVASES, LESSON } from "@/constants/routes";
import { Avatar } from "antd";
import { ICON_LEARNING_DASHBOARD, ICON_CANVAS } from "@/constants/images";
import { Row, Col } from "reactstrap";
import styled from "styled-components";
import { PRIMARY_COLOR } from "@/constants/constant";
import useRouter from "@/hook/useRouter";

const WrapperUl = styled.ul`
    padding:0px;
    list-style-type:none;
    display:flex;
    justify-content:center;
  
    li {
      padding-left:10px;
      padding-right:10px;
      padding-top:10px;
      margin-top:-17px;

      &.active {
        border-top: 3px solid ${PRIMARY_COLOR};
      }
    }

    @media only screen and (max-width: 600px) {
      list-style-type:none;
      display:flex;
      justify-content:space-between;
      li {
      padding:10px 0px 0px 0px;
      text-align:center;

      .ant-avatar {
        /* display:block; */
      }
    }
}

`;
const LinkTab = () => {

  const { history: { location: { pathname } } } = useRouter();

  return (<Row className="m-0 p-0">
    <Col>
      <WrapperUl>
        <li className={pathname === LESSON ? "active" : ""}>
          <NavLink to={LESSON}><Avatar src={ICON_LEARNING_DASHBOARD} className="mr-2" /> Learning Dashboard</NavLink>
        </li>
        <li className={pathname === CANVASES ? "active" : ""}>
          <NavLink to={CANVASES}><Avatar src={ICON_CANVAS} className="mr-2" />Canvases</NavLink>
        </li>
      </WrapperUl>
    </Col>
  </Row>);
};

export default LinkTab;