// Libs
import React, { Fragment } from "react";
import { Label } from "reactstrap";
import { Button, Skeleton } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Components
import Title from "@/components/common/title";
import * as routes from "@/constants/routes";
import locale from "@/locale";

function Instruction({ user, isInvited, activity, viewInvitation, session }) {

  return (
    <Fragment>
      {!activity.id && <Skeleton active paragraph={{ rows: 15 }} />}
      {activity.id && user.is_team_leader && (
        <>
          <Title
            tag="h6"
            className="mb-5 text-muted"
            title={locale("messageTeamLeaderInfo")}
          />
          <Link
            to={`${routes.FORM_TEAM}?sessionId=${session.id}&activityId=${activity.id}&status=create`}
            target="_blank"
          >
            <Button
              ghost
              type="primary"
              className="mb-5 d-flex mx-auto"
            >
              {locale("buttonCreateTeam")}
            </Button>
          </Link>
        </>
      )}
      {activity.id && !user.is_team_leader && (
        <Label className="d-block text-left mb-5">
          <span className="pr-2 d-block font-weight-bold">
            {locale("instruction")}:
          </span>
          {locale("messageInstructionMember")}
        </Label>
      )}

      {activity.id && !user.is_team_leader && isInvited && (
        <div className="p-4 mb-5 rounded text-center border-1 bg-danger border-danger">
          <Title
            className="mb-4 text-danger"
            title={locale("messageHappyInvited")}
          />
          <Button
            ghost
            type="danger"
            className="mx-auto"
            onClick={viewInvitation}
          >
            {locale("viewInvitation")}
          </Button>
        </div>
      )}
    </Fragment>
  );
}

Instruction.propTypes = {
  user: PropTypes.object,
  isInvited: PropTypes.bool,
  session: PropTypes.object,
  activity: PropTypes.object,
  viewInvitation: PropTypes.func
};

Instruction.defaultProps = {
  session: {},
  activity: {}
};

export default Instruction;
