//import logger from "redux-logger";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";

let composeEnhancers = compose;

const middlewares = [];
if (process.env.NODE_ENV !== "production") {
  //middlewares.push(logger);
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}
middlewares.push(thunk);

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
