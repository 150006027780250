import React, { Fragment, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Button, Skeleton } from "antd";
import { reset } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

// Components
import Form from "@/components/common/form";
import Title from "@/components/common/title";
import LearningDashboardLayout from "../index";
import RadioButton from "@/components/common/radio-button";

// Utils
import * as helpers from "@/helpers";
import * as actions from "@/stores/actions";
import NextButton from "@/components/common/next-button";
import locale from "@/locale";
import { setQuizLogsData } from "@/stores/actions";
import useRouter from "@/hook/useRouter";
import { updateActivityLog } from "@/stores/actions/activityLog";

function Quiz() {
  const [isQuestion, setIsQuestion] = useState(true);
  const [loading, setLoading] = useState(false);
  const { activityLog } = useSelector(state => state.activityLog);
  const { activity } = useSelector(state => state.activity);
  const { quizzes } = useSelector(state => state.quiz);
  const totalQuiz = useSelector(state => state.quiz.totalQuiz);
  const quizLogs = useSelector(state => state.quizLog.quizLogs);
  const totalCorrectAnswer = useSelector(state => state.quiz.totalCorrectAnswer);
  const { sessionId, activityId } = useRouter();
  const { lang } = useSelector(state => state.lang);

  const dispatch = useDispatch();

  useEffect(() => {
    if (quizLogs.length && _.size(activityLog) > 0) {
      if (!activityLog.is_completed) {
        dispatch(updateActivityLog(sessionId, activityId, { is_completed: true }));
      }
    }
  }, [dispatch, quizLogs, activityLog, sessionId, activityId]);

  useEffect(() => {
    let correctAnswer = 0;
    quizLogs.map(itemQuizLog => {
      if (itemQuizLog.is_correct) correctAnswer += 1;
    });
    dispatch(actions.setTotalCorrectAnswer(correctAnswer));
  }, [dispatch, quizLogs]);

  const handleAnswer = useCallback(
    values => {
      setIsQuestion(false);
      setLoading(true);
      let correctAnswer = 0;
      quizzes.map(quiz => {
        let answerId = values[quiz.id];
        quiz.is_correct = false;
        quiz.answer_id = answerId;
        quiz.correct_answer_id = -1;

        if (quiz.answer[answerId] && quiz.answer[answerId].is_correct) {
          correctAnswer += 1;
          quiz.is_correct = true;
        }

        quiz.answer.map((answer, ansIndex) => {
          if (answer.is_correct) {
            quiz.correct_answer_id = ansIndex;
          }
        });

        return quiz;
      });

      dispatch(actions.setQuizzes(quizzes));
      dispatch(actions.setTotalCorrectAnswer(correctAnswer));
      dispatch(actions.setQuizResultLogsData({ quizzes, activity, sessionId }));
      if (helpers.calculatePercentage(quizzes.length, correctAnswer) >= 50) {
        dispatch(setQuizLogsData({ quizzes, activity, sessionId }));
        dispatch(updateActivityLog(sessionId, activity.id, { is_completed: true }));
      }
      setLoading(false);
    },
    [dispatch, quizzes, activity, sessionId]
  );

  const onRedoQuiz = useCallback(() => {
    setIsQuestion(true);
    dispatch(reset("quizForm"));
  }, [dispatch]);

  const onSubmit = useCallback(
    values => {
      const validationRule = {};
      const validateOptions = {
        required: true,
        message: "Please choose option"
      };
      quizzes.map(values => {
        validationRule[values.id] = validateOptions;
      });

      return helpers.createSubmitAction(
        validationRule,
        handleAnswer,
        null // if no need custom values before submit pass null
      )(values);
    },
    [handleAnswer, quizzes]
  );

  return (
    <LearningDashboardLayout hasSidebar={true} hasBreadcrumb={true}>
      {!activity.id && <Skeleton active paragraph={{ rows: 5 }} />}
      {activity.id && (
        <Title
          tag="h3"
          title={activity.title}
          className="mb-5 text-center text-shadow text-primary"
        />
      )}

      {/* {loadingQuiz && <Skeleton active paragraph={{ rows: 15 }} />} */}
      {quizzes.length > 0 && (
        <Form form="quizForm" onSubmit={onSubmit} className="quiz-box">
          {quizzes.map(values => {
            const { id, answer } = values;

            let yourAnswer = -1;
            if (quizLogs.length) {
              quizLogs.map(itemQuizLog => {
                if (values.id === itemQuizLog.quiz_id)
                  yourAnswer = itemQuizLog.user_answer_id;
              });
            }

            return (
              <RadioButton
                key={id}
                label={values[`${lang}_question_text`]}
                image={values.image}
                name={id}
                initValue={yourAnswer}
                isAnswer={helpers.calculatePercentage(totalQuiz, totalCorrectAnswer) >= 50 || quizLogs.length}
                dataSource={answer}
                lang={lang}
                style={{
                  marginLeft: "10%"
                }}
              />
            );
          })}
          {isQuestion && quizLogs.length <= 0 && quizzes.length > 0 && (
            <Button
              type="primary"
              htmlType="submit"
              className="px-5 mt-5 d-flex mx-auto"
              loading={loading}
            >
              {locale("submit")}
            </Button>
          )}
          {isQuestion && quizLogs.length > 0 && quizzes.length > 0 && (
            <div className="p-2 mx-auto rounded text-center border-1 border-danger w-50">
              <Title title={locale("youGot")} />
              <Title title={`${totalCorrectAnswer}/${totalQuiz}`} />
            </div>
          )}
          {!isQuestion && quizzes.length > 0 && (
            <Fragment>
              <div className="p-2 mx-auto rounded text-center border-1 border-danger w-50">
                <Title title={locale("youGot")} />
                <Title title={`${totalCorrectAnswer}/${totalQuiz}`} />
              </div>

              {helpers.calculatePercentage(totalQuiz, totalCorrectAnswer) <
                50 && (<>
                  <hr />
                  <div className="p-2 m-auto rounded text-center border-1 border-danger">
                    <Title
                      tag="h6"
                      className="text-danger p-4"
                      title={_.replace(locale("messageNotPassQuiz"), "#####", helpers.calculatePercentage(totalQuiz, totalCorrectAnswer))}
                    />
                  </div>
                  <hr />
                  <Button
                    type="primary"
                    className="px-5 mt-5 d-flex mx-auto"
                    onClick={onRedoQuiz}
                  >
                    {locale("redoQuiz")}
                  </Button>
                </>)}
            </Fragment>
          )}
        </Form>
      )}

      <NextButton />
    </LearningDashboardLayout>
  );
}

Quiz.propTypes = {
  onResetForm: PropTypes.func
};

export default Quiz;
