import * as actionTypes from "../actions/actionTypes";

const initialState = {
  members: [
    {
      id: 1,
      name: "Sovan Srun",
      school: "SETEC Institute"
    },
    {
      id: 2,
      name: "Kosoma Kim",
      school: "Norton University"
    },
    {
      id: 3,
      name: "Sokkheng Kaing",
      school: "Royal University of Phnom Penh"
    },
    {
      id: 4,
      name: "Vattanak",
      school: "SETEC Institute"
    },
    {
      id: 5,
      name: "Vichit",
      school: "Norton University"
    },
    {
      id: 6,
      name: "Kimleng",
      school: "Royal University of Phnom Penh"
    }
  ]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.MEMBER_INIT:
    return { ...state, members: action.members };
  }
  return state;
};

export default reducer;
