import * as actionTypes from "../actions/actionTypes";

const initialState = {
  teams: [],
  team: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.TEAM_SET_TEAMS:
    return { ...state, teams: action.teams };
  case actionTypes.TEAM_SET_TEAM:
    return { ...state, team: action.team };
  default:
    return state;
  }
};

export default reducer;

