// Helper
import styled from "styled-components";
import * as images from "@/constants/images";

export const WrapperBodyLeft = styled.div`
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: url(${images.BACKGROUND_IMAGE3});
`;