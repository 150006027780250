export const HOME = "/";
export const LESSON = "/lesson";
export const LESSON_DETAIL = "/lesson-detail";
export const LESSON_DETAIL_VIDEO = `${LESSON_DETAIL}-video`;
export const LESSON_DETAIL_INTRODUCTION = `${LESSON_DETAIL}-introduction`;
export const LESSON_DETAIL_CHECK_LIST = `${LESSON_DETAIL}-check-list`;
export const LESSON_DETAIL_QUIZ = `${LESSON_DETAIL}-quiz`;
export const LESSON_DETAIL_PAGE = `${LESSON_DETAIL}-page`;
export const LESSON_DETAIL_FORM_TEAM = `${LESSON_DETAIL}-forming-team`;
export const LESSON_DETAIL_CANVAS = `${LESSON_DETAIL}-canvas`;
export const LESSON_DETAIL_FORM_TEAM_INVITATION = `${LESSON_DETAIL}-forming-team-invitation`;

export const ENTER_CLASS_CODE = "/enter-class-code";
export const FORM_TEAM = "/form-team";
export const SIGN_IN = "/sign-in";
export const SIGN_UP = "/sign-up";
export const FORGET_PASSWORD = "/forget-password";
export const ABOUT_US = "/about-us";
export const CONTACT_US = "/contact-us";
export const EMAIL_VERIFY = "/email-verify";
export const ACCOUNT_SETTING = "/account-setting";
export const EMAIL = "/email";
export const SECURITY = "/security";
export const NOT_FOUND = "/not-found";

export const CANVASES = "/canvases";
export const TEAM_CANVAS = "team-canvas";
export const CUSTOMER_DISCOVERY = "customer-discovery";
export const COMPETITION_MATRIX = "competition-matrix";
export const IDEA_GENERATION_CANVAS = "idea-generation-canvas";
export const BUSINESS_MODEL_CANVAS = "business-model-canvas";
export const PROTOTYPE_CANVAS = "prototype-canvas";
export const NEGOTIATION_CANVAS = "negotiation-canvas";
export const PEER_FEEDBACK_CANVAS = "peer-feedback-canvas";
export const REFLECTION_CANVAS = "reflection-canvas";








