// Libs
import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

// Component
import Form from "./form";
import * as images from "@/constants/images";

// Util
const WrapperContainer = styled(Container)`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-image: url(${images.BACKGROUND_IMAGE});
`;

function SignUp() {
  return (
    <WrapperContainer fluid>
      <Row className="h-100">
        <Col lg="4" md="6" xs="12" className="m-auto text-center">
          <Form />
        </Col>
      </Row>
    </WrapperContainer>
  );
}

export default SignUp;
