// Libs
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Components
import NotFound from '@/views/not-found';
import SignIn from '@/views/log-in';
import SignUp from '@/views/create-account';
import ForgetPassword from '@/views/forget-password';
import Layout from '@/components/layout';
import * as routes from '@/constants/routes';
import VerifyEmailForm from '@/views/create-account/verify-email-form';
import AboutUs from '@/views/about-us';
import Test from '@/views/test';

class Public extends Component {
  render() {
    return (
      <Layout {...this.props}>
        <Switch>
          <Redirect exact from='/' to={routes.SIGN_IN} />
          <Route path={routes.SIGN_IN} component={SignIn} />
          <Route path={routes.SIGN_UP} component={SignUp} />
          <Route path={routes.FORGET_PASSWORD} component={ForgetPassword} />
          <Route path={routes.EMAIL_VERIFY} component={VerifyEmailForm} />
          <Route exact path={routes.ABOUT_US} component={AboutUs} />
          <Route exact path={'/test'} component={Test} />

          <Route component={NotFound} />
        </Switch>
      </Layout>
    );
  }
}

export default Public;
