import * as actionTypes from './actionTypes';
import { firestoreRef } from '@/configs/firebase';
import _ from 'lodash';

const setClass = (cls) => ({
  type: actionTypes.CLASS_SET_CLASS,
  cls,
});

export const getClass = (code) => (dispatch) => {
  try {
    return new Promise((resolve, reject) => {
      firestoreRef
        .collection('classes')
        .where('code', '==', code || 'N/A')
        .onSnapshot((snapshots) => {
          let data = {};
          snapshots.forEach((doc) => {
            data = { ...doc.data(), id: doc.id };
          });
          dispatch(setClass(data));
          if (_.size(data)) {
            resolve(data);
          }
          reject(false);
        });
    });
  } catch (err) {
    console.log(err);
  }
};
