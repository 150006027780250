import React, { Fragment, useCallback } from "react";
import { Label, Row, Col } from "reactstrap";
import { Icon, Button } from "antd";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import locale from "@/locale";
import { setActivityById } from "@/stores/actions";
import * as routes from "@/constants/routes";
import { history } from "@/shared/utils/history";

function NextButton() {
  const { activities, activity } = useSelector(state => state.activity);
  const { session } = useSelector(state => state.session);

  const dispatch = useDispatch();

  const handleNext = useCallback(() => {
    let findIndex = _.findIndex(activities, { id: activity.id });
    if (activities[findIndex + 1]) {
      let tmpActivity = activities[findIndex + 1];
      let routePath = routes.LESSON_DETAIL;
      routePath = `${routePath}?sessionId=${session.id}&activityId=${tmpActivity.id}`;
      history.push(routePath);
      dispatch(setActivityById(tmpActivity.id));
    }
  }, [dispatch, activities, activity, session]);

  const handleBack = useCallback(() => {
    let findIndex = _.findIndex(activities, { id: activity.id });
    if (activities[findIndex - 1]) {
      let tmpActivity = activities[findIndex - 1];
      let routePath = routes.LESSON_DETAIL;
      routePath = `${routePath}?sessionId=${session.id}&activityId=${tmpActivity.id}`;
      history.push(routePath);
      dispatch(setActivityById(tmpActivity.id));
    }
  }, [activities, dispatch, activity, session]);

  return (
    <Fragment>
      <Row className="mt-5">
        <Col>
          <div className="mt-3 text-left">
            <Button
              type="primary"
              className="rounded d-inline-flex align-items-center back-btn"
            >
              <a className="d-flex" onClick={handleBack}>
                <Icon type="arrow-left" />
              </a>
            </Button>
            <Label className="text-primary ml-3">{locale("back")}</Label>
          </div>
        </Col>
        <Col>
          <div className="mt-3 text-right">
            <Label className="text-primary">{locale("next")}</Label>
            <Button
              type="primary"
              className="ml-3 rounded d-inline-flex align-items-center"
            >
              <a className="d-flex" onClick={handleNext}>
                <Icon type="arrow-right" />
              </a>
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}

export default NextButton;
