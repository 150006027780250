import locale from "@/locale";
import * as constants from "@/constants/constant";

export const genderList = [
  {
    id: constants.MALE,
    name: locale("male")
  },
  {
    id: constants.FEMALE,
    name: locale("female")
  }
];
