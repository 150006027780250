import * as actionTypes from "./actionTypes";
import { firestoreRef, FIRSTORE_TIMESTAMP } from "@/configs/firebase";
import _ from "lodash";

export const setCanvasTeams = canvasTeams => ({
  type: actionTypes.CANVAS_TEAM_SET_CANVAS_TEAMS,
  canvasTeams
});

export const setCanvasTeam = canvasTeam => ({
  type: actionTypes.CANVAS_TEAM_SET_CANVAS_TEAM,
  canvasTeam
});

export const setCanvasTeamError = message => ({
  type: actionTypes.CANVAS_TEAM_SET_CANVAS_TEAMS_ERROR,
  message
});

export const setCanvasTeamSuccess = () => ({
  type: actionTypes.CANVAS_TEAM_SET_CANVAS_TEAMS_SUCCESS
});

export const setCanvasTeamRequest = () => ({
  type: actionTypes.CANVAS_TEAM_SET_CANVAS_TEAMS_REQUEST
});

export const getCanvasTeams = (team_id, canvas_id) => (dispatch) => {
  try {

    dispatch(setCanvasTeamRequest());
    return firestoreRef
      .collection("canvas_teams")
      .where("team_id", "==", team_id)
      .where("canvas_id", "==", canvas_id)
      .onSnapshot(snapshots => {
        let data = [];
        snapshots.forEach(doc => {
          data.push({
            ...doc.data(),
            id: doc.id
          });
        });
        dispatch(setCanvasTeams(data));
        dispatch(setCanvasTeamSuccess());
        return data;
      });
  } catch (err) {
    dispatch(setCanvasTeamError(err));
    console.log(err);
  }
};

export const getCanvasTeam = canvasTeamId => dispatch => {
  try {
    return firestoreRef
      .collection("canvas_teams")
      .doc(canvasTeamId)
      .onSnapshot(doc => {
        let data = { ...doc.data(), id: doc.id };
        dispatch(setCanvasTeam(data));
      });
  } catch (err) {
    console.log(err);
  }
};

export const insertCanvasTeam = (obj = {}) => (dispatch, getState) => {
  try {
    const { user } = getState().user;
    obj = {
      ...obj,
      user_id: user.id,
      team_id: user.team_id,
      class_id: user.class_id,
      created: FIRSTORE_TIMESTAMP
    };

    return firestoreRef
      .collection("canvas_teams")
      .where("team_id", "==", user.team_id)
      .where("canvas_id", "==", obj.canvas_id)
      .where("canvas_block_id", "==", obj.canvas_block_id)
      .get()
      .then(snapshots => {
        let data = {};
        snapshots.forEach(doc => {
          data = {
            ...doc.data(),
            id: doc.id
          };
        });

        if (_.size(data)) {
          firestoreRef
            .collection("canvas_teams")
            .doc(data.id)
            .update(obj);
        } else {
          firestoreRef
            .collection("canvas_teams")
            .doc()
            .set(obj);
        }
      });
  } catch (err) {
    console.log(err);
  }
};
