import _ from "lodash";
import * as constants from "@/constants/constant";
import { parse } from "query-string";
import moment from "moment";
import { EditorState, ContentState, convertFromHTML } from "draft-js";

export const visibleTab = (function () {
  var stateKey,
    eventKey,
    keys = {
      hidden: "visibilitychange",
      webkitHidden: "webkitvisibilitychange",
      mozHidden: "mozvisibilitychange",
      msHidden: "msvisibilitychange"
    };
  for (stateKey in keys) {
    if (stateKey in document) {
      eventKey = keys[stateKey];
      break;
    }
  }
  return function (c) {
    if (c) document.addEventListener(eventKey, c);
    return !document[stateKey];
  };
})();

export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

export const setFullMediaPath = imagePath => {
  return (
    constants.CLOUD_STORAGE_PREFIX +
    imagePath.replace("/", "%2F") +
    constants.ALT_MEDIA
  );
};

export const calculatePercentage = (total, number) => {
  return number || total ? Math.ceil((number / total) * 100) : 0;
};

export const getLocationPathname = (history, replacePath = "/canvases/") => {
  return history.location.pathname.replace(replacePath, "");
};

export const getLocationParamSearch = history => {
  return parse(history.location.search);
};

export const formatDateFirestore = (
  objDate,
  format = "DD/MM/YYYY HH:mm:ss"
) => {
  return moment(objDate.seconds * 1000).format(format);
};

export const findObject = (objects, id, compareId) => {
  let tmObj = objects.find(obj => {
    return obj[id] === compareId;
  });

  return tmObj;
};

export const findObjects = (objects, keys = [], compareIds = []) => {
  let tmObj = objects.find(obj => {
    let bool = false;
    keys.forEach((key, i) => {
      bool = obj[key] === compareIds[i];
    });
    return bool;
  });

  return tmObj;
};

export const getProperty = (data, field = "name") => {
  if (!data) return "";
  if (data[field]) return data[field];
  return "";
};

export const isEmpty = data => {
  if (typeof data === "object") {
    for (let key in data) if (data.hasOwnProperty(key)) return false;
  }
  return true;
};

export const getUserName = data => {
  return getProperty(data, "name");
};

export const getProfile = data => {
  return getProperty(data, "profile");
};

export const toEditor = content => {
  try {
    if (!content) {
      return EditorState.createEmpty();
    }

    return EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(content))
    );
  } catch (err) {
    console.log(err);
  }
};

export const sleep = (millisecond) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(millisecond);
    }, millisecond);
  });
};

export const getFirstLatterOfWord = (name) => {
  let split = name.trim(" ").split(" ");
  let firsts = split.map(item => item[0]);

  return firsts.join("");
};

export const getStoragePath = (path) => {
  return `${constants.CLOUD_STORAGE_PREFIX}${_.trim(path, '/', '').replace('/', '%2F')}${constants.ALT_MEDIA}`;
};