/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import locale from "@/locale";

function Footer() {
  return (
    <Fragment>
      <Container fluid className="footer-container">
        <Container >
          <Row className="pt-2 pb-2">
            <Col md="6" xs="12">
              {locale("allRightReserved").replace('#####', (new Date()).getFullYear())}
            </Col>
            <Col className="text-right" md="6" xs="12">
              <a href="https://www.edemy.org/" target="_blank">
                {locale("allRightReservedDev")}
              </a>
            </Col>
          </Row>
        </Container>
      </Container>
    </Fragment >
  );
}

export default Footer;
