import * as actionTypes from "./actionTypes";
import { firestoreRef } from "@/configs/firebase";

export const setResources = resources => ({
  type: actionTypes.RESOURCE_SET_RESOURCES,
  resources
});

export const setResource = resource => ({
  type: actionTypes.RESOURCE_SET_RESOURCE,
  resource
});

export const getResources = () => dispatch => {
  try {
    return firestoreRef
      .collection("resources")
      .onSnapshot(snapshots => {
        let data = [];
        snapshots.forEach(doc => {
          data.push({
            ...doc.data()
          });
        });
        dispatch(setResources(data));
      });
  } catch (err) {
    console.log(err);
  }
};

export const getResource = (resource_id) => (dispatch) => {
  try {
    return firestoreRef
      .collection("resources")
      .doc(resource_id)
      .onSnapshot(doc => {
        let data = doc.data() || {};
        dispatch(setResource(data));
      });
  } catch (err) {
    console.log(err);
  }
};
