import React, { Fragment } from "react";
import Layout from "./layout";
import CommonIndex from "./common-index";

function AccountSetting() {
  return (
    <Fragment>
      <Layout>
        <CommonIndex />
      </Layout>
    </Fragment>
  );
}

export default AccountSetting;
