import * as actionTypes from "../actions/actionTypes";

const initialState = {
  htmlPages: [],
  htmlPage: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.HTML_PAGE_SET_HTML_PAGES:
    return { ...state, htmlPages: action.htmlPages };
  case actionTypes.HTML_PAGE_SET_HTML_PAGE:
    return { ...state, htmlPage: action.htmlPage };
  default:
    return state;
  }
};

export default reducer;
