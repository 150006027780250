// Libs

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

// Component
import Title from '@/components/common/title';
import * as images from '@/constants/images';
import { Wrapper, WrapperLessonAndImage } from '@/components/style-component';
import * as routes from '@/constants/routes';
import * as actions from '@/stores/actions';
import { history } from '@/shared/utils/history';
import { SECONDARY_COLOR } from '@/constants/constant';
import { getStoragePath } from '@/helpers/utils';
import { Avatar } from 'antd';

function ClassBox({ sessions, isEnable }) {
  const dispatch = useDispatch();

  const handleClick = (paramSession) => {
    // let isComplete = paramSession.is_completed;
    // if (_.isNil(isComplete) && !isEnable) {
    //   return false;
    // }

    console.log(isEnable);

    dispatch(actions.setSession(paramSession || {}));
    dispatch(actions.getActivities());
    let routePath = `${routes.LESSON_DETAIL}?sessionId=${paramSession.id}`;
    history.push(routePath);
  };

  return (
    <Row>
      {sessions.map((itemSession) => {
        const { id, is_completed: isComplete, iconPath } = itemSession;

        return (
          <Col
            lg='3'
            md='4'
            xs='12'
            key={itemSession.id}
            className='p-1'
            onClick={() => handleClick(itemSession)}
          >
            <Wrapper
              disable={(_.isNil(isComplete) && !isEnable) || false}
              style={{ background: isComplete ? SECONDARY_COLOR : '#fff' }}
            >
              <div id={id}>
                {isComplete && (
                  <div className='icon-check pr-1'>
                    <Avatar src={images.ICON_CHECK} />
                  </div>
                )}
                {!isComplete && <div className='mt-5' />}
                <WrapperLessonAndImage>
                  <span className={_.isNil(isComplete) ? 'text-muted' : ''}>
                    {itemSession.session}
                  </span>
                  <img src={getStoragePath(iconPath)} alt='' />
                </WrapperLessonAndImage>
                <Title
                  style={{ position: 'relative', bottom: '0px' }}
                  tag='h6'
                  title={`${itemSession.name}`}
                  isShowTitle={true}
                  className={`${
                    _.isNil(isComplete) ? 'text-muted' : 'text-primary'
                  } mb-0 font-weight-bold text-height`}
                />
              </div>
            </Wrapper>
          </Col>
        );
      })}
    </Row>
  );
}

ClassBox.propTypes = {
  sessions: PropTypes.array,
  sessionLogs: PropTypes.array,
  isEnable: PropTypes.bool,
};

export default ClassBox;
