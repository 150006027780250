export default {
  addMember: "Add Member",
  allRightReserved: "SmartStart Unipreneurship © ##### All Rights Reserved.",
  allRightReservedDev: "E-Learning Platform developed by Edemy Co.,Ltd",
  alreadyHaveAccount: "Already have an account?",
  accountSetting: "Account Setting",
  aboutUs: "About Us",
  agree: "Agree",
  askForgetPassword: "Forget password?",
  buttonCreateTeam: "Click here to create a team",
  backToCanvas: "Back to canvas",
  back: "Back",
  becomeTeamLeader: "Become Team Leader",
  contactUs: "Contact Us",
  createAccount: "Create an account",
  canvases: "Canvases",
  canvasList: "Canvas List",
  changeEmail: "Change Email",
  cancel: "Cancel",
  comment: "Comment",
  dateOfBirth: "Date of Birth",
  description: "Description",
  done: "Done",
  email: "Email",
  emailAddress: "Email Address",
  emailInUsed: "Email already in use",
  enterYourClassCode: "Enter your class code",
  emailNotification: "Email notifications",
  edit: "Edit",
  errorRequired: "The field is required",
  errorRequired2: "*Required field",
  errorInvalidEmail: "Invalid Email Address",
  errorEmailNotExist: "Email not existed",
  errorEmailExisted: "The email address is already in use by another account.",
  errorInvalidEmailOrPassword: "Invalid username or password.",
  errorSelectOption: "Please choose option",
  errorPasswordLength: "Password must be 6 characters or more",
  errorSelectNotEmpty: "Please select at least 1 of these",
  female: "Female",
  formingTeam: "Forming a Team",
  finish: "Finish",
  gender: "Gender",
  general: "General",
  gotoCanvas: "Go to canvas",
  home: "Home",
  inviteList: "View invite to team",
  instruction: "Instruction",
  incorrectClassCode: "INCORRECT CLASS CODE",
  join: "Join",
  leaveGroup: "Leave Group",
  leaveGroupQuestion: "Do you want to leave group?",
  logIn: "Log In",
  learningDashboard: "Learning Dashboard",
  logOut: "Log out",
  labelAskTeamName: "What’s the name of your team?",
  labelAskTeamMember: "Who are your team members?",
  labelShortDescTeamProblem:
    "Please give a short description of the problem your team is working on.",
  labelShortDescTeamName:
    "Please give a short description of the problem your team is working on. (Write no more than 250 Characters)",
  male: "Male",
  messageUploadSuccess: "Profile has been updated",
  messageNotExist: "The page you were looking for doesn't exist",
  messageReturnHome: "Return to ##### or explore",
  messageReturnHomeContactAdmin:
    "Return to ##### or contact you administrator for more info.",
  messageEmailCurrently: "Your email address is currently",
  messageClassCode:
    "To get started, please enter the Class Code to your class, provided by your teacher. If you do not one, please obtain one from your teacher or your classmates. If there is any problem with your class code, please contact Impact Hub Phnom Penh.",
  messageNotiEmail:
    "To manage what emails you get, visit the email preferences center.",
  messageEmailVerify:
    "Please check your email address and click on verify URL to confirm.",
  messageVerifyEmailInfo:
    "A verification email has been sent. Please check your email and click “Verify URL” to confirm.",
  messageTeamJoinRejectInfo:
    "You have been invited to join the #####. If this is the correct team, click “Join”. Otherwise, click “Cancel”.",
  messageTeamLeaderInfo:
    "Your teacher has upgraded your account to be the “Team Leader”. A team leader account allows you to add or remove team members, and submit team work for review.",
  messageCanvasHistoryInfo: "Canvas Editing History",
  messageSuccessJoinTeam:
    "Congratulations! You are now a member of the #####. Here is your team information. Now you can start collaborate on the Canvases.",
  messageSuccessChecklist: "Well done! You have finished session #####",
  messageFailCheckList:
    "Uh oh, you have not completed the following activities yet. Complete them first before moving on.",
  messageInstructionMember:
    "To create a team, you will need to upgrade your account to be the team leader. There can only be one team leader in a team. If you do not have a team yet, discuss with your teammates, and request your team to upgrade one of your team member’s accounts to be the team leader.",
  messageHappyInvited: "Yay! You have been invited to join teams",
  messageCancelJoinTeam:
    "You have declined the invitation to join the #####. To join a team, please ask the Team Leader to send you an invitation.",
  messageEmailResetPassword:
    "We have sent an email to the email address provided. Please click on the LINK to confirm.",
  messageEnterIncorrectClassCode:
    "The Class Code you have entered is incorrect. Please try again or contact your teacher for the correct Class Code.",
  messageNotPassQuiz:
    "You got #####% correct. You will need to get at least 50% to move to the next the session. Please review the materials and complete the quiz again.",
  messageNoPermission:
    "Sorry, you do not have permission to perform this action",
  next: "Next",
  nextSession: "Next session",
  now: "now.",
  name: "Name",
  okey: "Okey",
  password: "Password",
  previousSession: "Previous Session",
  personalDetails: "Personal Details",
  pendingApproval: "Pending Approval",
  pending: "Pending",
  redoQuiz: "Redo the Quiz",
  signUp: "Sign Up",
  signIn: "Sign In",
  submit: "Submit",
  school: "School",
  setting: "Setting",
  save: "Save",
  saveChange: "Save Changes",
  security: "Security",
  session: "Session",
  satisfy: "Satisfy",
  status: "Status",
  titleEnterClassCode: "Enter your class code to start learning.",
  titleListSubMenu: "Smart Entrepreneurship",
  titleResetPassword: "Please enter your email address to reset your password.",
  titleChangePassword: "Change your password",
  titleLesson: "Hi #####! Welcome to Class $$$$$!",
  titleEmailSend: "Verification has been sent to your Email!",
  team: "Team",
  teamMember: "Team Member",
  teamManagement: "Team Management",
  teamName: "Team Name",
  total: "Total",
  teamDescription: "Team Description",
  thankYou: "Thank You!",
  username: "Username",
  unsatisfied: "Unsatisfied",
  viewInvitation: "View the Invitations",
  wrongCredentials: "Wrong Credentials",
  youGot: "You got",
  writtenHistory: "has written in",
  introCanvas: "Fill the canvases below.",
  canvas_no_team_message: "You have not joined a team yet. You need to form or join a team to work on the Canvases",
  form_no_team_message: "You have not joined a team yet. You need to form or join a team to view team members."
};
