// Libs
import React, { Fragment, useState } from "react";
import { Button } from "antd";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

// Components
import Title from "@/components/common/title";
import InputText from "@/components/common/input";
import FormWrapper from "@/components/common/form";

// Helper
import * as helpers from "@/helpers";
import * as routes from "@/constants/routes";
import * as actions from "@/stores/actions";
import { history } from "@/shared/utils/history";
import locale from "@/locale";

function ForgetPassword() {
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [loadings, setLoadings] = useState(false);

  const handleReset = values => {
    setLoadings(true);
    dispatch(actions.sendPasswordResetEmail(values.email))
      .then(() => {
        setIsSubmitted(true);
        history.push(routes.EMAIL_VERIFY);
      })
      .catch(() => {
        setError(true);
        setLoadings(false);
      });
  };

  const onSubmit = values => {
    setLoadings(false);
    const validationRule = {
      email: {
        required: true,
        type: "email"
      }
    };
    return helpers.createSubmitAction(
      validationRule,
      handleReset,
      null, // if no need custom values before submit pass null
      false // if function don't need dispatch pass false
    )(values);
  };

  return (
    <Container fluid>
      <Row className="h-100">
        <Col lg="6" md="8" xs="12" className="m-auto text-center">
          {error && (
            <div className="p-3 mb-3 border-2 border-danger bg-danger text-center text-muted rounded">
              <Title className="text-muted" title={locale("wrongCredentials")} />
              <span>{locale("errorInvalidEmail")}</span>
            </div>
          )}
          {isSubmitted ? (
            <Fragment>
              <Title
                tag="h6"
                className="text-muted mb-4"
                title={locale("titleResetPassword")}
              />

              <Link to={routes.SIGN_IN}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="mt-2 px-5 font-weight-bold"
                >
                  {locale("okey")}
                </Button>
              </Link>
            </Fragment>
          ) : (<Fragment>
            <Title
              tag="h6"
              className="text-muted mb-4"
              title={locale("titleResetPassword")}
            />
            <FormWrapper form="resetPassword" onSubmit={onSubmit}>
              <InputText name="email" placeholder={locale("email")} />
              <Button
                type="primary"
                htmlType="submit"
                loading={loadings}
                onClick={setLoadings}
                className="mt-2 px-5 font-weight-bold"
              >
                {locale("submit")}
              </Button>
            </FormWrapper>
          </Fragment>)}
        </Col>
      </Row>
    </Container>
  );
}

export default ForgetPassword;
