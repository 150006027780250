import * as actionTypes from "./actionTypes";
import { firestoreRef } from "@/configs/firebase";

export const setHtmlPages = htmlPages => ({
  type: actionTypes.HTML_PAGE_SET_HTML_PAGES,
  htmlPages
});

const setHtmlPage = htmlPage => ({
  type: actionTypes.HTML_PAGE_SET_HTML_PAGE,
  htmlPage
});

export const getHtmlPages = () => dispatch => {
  try {
    return firestoreRef
      .collection("html_pages")
      .onSnapshot(snapshots => {
        let data = [];
        snapshots.forEach(doc => {
          data.push({
            ...doc.data()
          });
        });
        dispatch(setHtmlPages(data));
      });
  } catch (err) {
    console.log(err);
  }
};

export const getHtmlPage = (html_page_id) => (dispatch) => {
  try {
    return firestoreRef
      .collection("html_pages")
      .doc(html_page_id)
      .onSnapshot(doc => {
        let data = { ...doc.data(), id: doc.id };
        dispatch(setHtmlPage(data));
      });
  } catch (err) {
    console.log(err);
  }
};
