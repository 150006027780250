import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import classnames from "classnames";
import { Select, Form } from "antd";
import { FormText } from "reactstrap";

const FormItem = Form.Item;
const Option = Select.Option;

class AutoCompleteField extends Component {
  onChange = value => {
    const {
      input: { onChange: reduxFormChange },
      onChange
    } = this.props;
    reduxFormChange(value);
    if (onChange) {
      onChange(value);
    }
  };

  render() {
    const {
      tip,
      dataSource,
      placeholder,
      label,
      required,
      mode,
      meta: { touched, error },
      input: { value }
    } = this.props;
    const validateStatus = classnames(touched && error ? "error" : "success");
    return (
      <Fragment>
        <FormItem
          label={label}
          validateStatus={validateStatus}
          help={error}
          required={required}
        >
          <Select
            showSearch
            mode={mode}
            placeholder={placeholder}
            optionFilterProp="children"
            onChange={this.onChange}
            value={value == "" ? undefined : value}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {dataSource.map((value, index) => {
              return (
                <Option key={index} value={value.id}>
                  {value.name}
                </Option>
              );
            })}
          </Select>
        </FormItem>
        {tip && (
          <FormText color="muted" className="mt-0 mb-2">
            {tip}
          </FormText>
        )}
      </Fragment>
    );
  }
}

AutoCompleteField.propTypes = {
  tip: PropTypes.string,
  input: PropTypes.any,
  meta: PropTypes.any,
  onChange: PropTypes.func,
  dataSource: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  mode: PropTypes.string
};

AutoCompleteField.defaultProps = {
  mode: "default"
};

class SelectAutoComplete extends Component {
  render() {
    const { ...props } = this.props;
    return <Field component={AutoCompleteField} {...props} />;
  }
}

export default SelectAutoComplete;
