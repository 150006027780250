import React, { Fragment } from "react";
import CommonEmail from "./common-email";
import Layout from "./layout";

function Email() {
  return (
    <Fragment>
      <Layout>
        <CommonEmail />
      </Layout>
    </Fragment>
  );
}

export default Email;
